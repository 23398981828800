/** Aroma Cartridges management getters */
const cartridgesManagementGetters = {
  searchCartridge: state => state.searchCartridge,
  cartridges: state => state.cartridges,
  currentCartridge: state => state.currentCartridge,
  getCartridgeById: state => id => {
    return state.cartridges.find(cartridge => cartridge.id === id)
  },
  cartridgeLocations: state => state.cartridgeLocations,
  paginationMeta: state => state.paginationMeta,
  remoteMessage: state => state.remoteMessage
}

export default cartridgesManagementGetters
