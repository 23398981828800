<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <label class="muted-text">Name</label>
      <h6
        v-if="!this.isEditable"
        class="text-muted"
      >
        {{ currentContact.name }}
      </h6>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- EMAIL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feContactEmail">Email</label>
            <d-input
              id="feContactEmail"
              :value="currentContact.email"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- SUBJECT -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feContactSubject">Subject</label>
            <d-input
              id="feContactSubject"
              :value="currentContact.subject"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- MESSAGE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feContactMessage">Message</label>
            <VueTrix
              v-model="currentContact.message"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- SOURCE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feContactSource">Source</label>
            <d-input
              id="feContactSource"
              :value="currentContact.contact_source"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- ACTIONS -->
        <d-form-row>
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleResendContact"
            >
              Resend
            </d-button>
            <d-button
              theme="danger"
              class="mr-3"
              @click.prevent="handleDeleteContact"
            >
              Delete
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'

import {
  DELETE_CONTACT,
  RESEND_CONTACT
} from '@/states/actionTypes'

export default {
  name: 'ContactView',
  components: {
    VueTrix
  },
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentContact: 'ContactsManagement/currentContact'
    })
  },
  methods: {
    ...mapActions({
      deleteContact: `ContactsManagement/${DELETE_CONTACT}`,
      resendContact: `ContactsManagement/${RESEND_CONTACT}`
    }),
    /** Handle deleting Contact event */
    handleDeleteContact () {
      let confirmDelete = confirm(`Are you sure to delete contact:\n ${this.currentContact.email}?`)
      if (confirmDelete) {
        // detelet contact
        this.deleteContact(this.currentContact)
      }
    },
    /** Handle resend Contact event */
    handleResendContact () {
      let confirmDelete = confirm(`Do you want to re-send notification for ${this.currentContact.email}?`)
      if (confirmDelete) {
        this.resendContact(this.currentContact)
      }
    }
  }
}
</script>
