import usersManagementStates from './UsersManagementStates'
import usersManagementGetters from './UsersManagementGetters'
import usersManagementMutations from './UsersManagementMutations'
import usersManagementActions from './UsersManagementActions'

const usersManagement = {
  namespaced: true,
  state: usersManagementStates,
  getters: usersManagementGetters,
  mutations: usersManagementMutations,
  actions: usersManagementActions
}

export default usersManagement
