<template>
  <tr>
    <td>
      {{ aromaShooter.serial_prefix }}
    </td>
    <td>
      {{ aromaShooter.title }}
    </td>
    <td>
      <div v-html="aromaShooter.description" />
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-md">
        <d-button
          class="btn-white"
          @click="handleOperation('view')"
        >
          <i class="material-icons">
            description
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperation('edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_SHOOTER_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'ShooterItem',
  props: {
    aromaShooter: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentShooter: `AromaShootersManagement/${CURRENT_SHOOTER_UPDATE}`
    }),
    handleOperation (eventType) {
      // Set current aroma shooter
      this.updateCurrentShooter(this.aromaShooter)

      switch (eventType) {
        case 'view':
          this.$router.push({
            name: 'shooter-details',
            params: {
              id: this.aromaShooter.id.toString(),
              action: 'view'
            }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'shooter-details',
            params: {
              id: this.aromaShooter.id.toString(),
              action: 'edit'
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
