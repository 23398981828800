/** Aroma Shooters management getters */
const aromaShootersManagementGetters = {
  currentAromaShooter: (state) => state.currentAromaShooter,
  aromaShooters: (state) => state.aromaShooters,
  getAromaShooterById: (state) => (id) => {
    return state.aromaShooters.find(aromaShooter => aromaShooter.id === id)
  },
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default aromaShootersManagementGetters
