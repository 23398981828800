<template>
  <div class="mx-lg-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Create
        </span>
        <h3 class="page-title">
          Aroma Shooter
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <d-row>
              <d-col>
                <label for="feShooterTitle">Title</label>
                <d-input
                  id="feShooterTitle"
                  v-model="shooterTitle"
                />
              </d-col>
            </d-row>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form>
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feShooterSlug">Slug</label>
                  <d-input
                    id="feShooterSlug"
                    v-model="shooterSlug"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterType">Code</label>
                  <d-input
                    id="feShooterType"
                    v-model="shooterType"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterPrefixes">Shooter variant</label>
                  <d-input
                    id="feShooterPrefixes"
                    v-model="shooterPrefix"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterColors">Colors</label>
                  <d-input
                    id="feShooterColors"
                    v-model="shooterColor"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterCartridgePorts">
                    Cartridges
                  </label>
                  <d-input
                    id="feShooterCartridgePorts"
                    v-model="shooterPorts"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterBlending">
                    Blending
                  </label>
                  <d-input
                    id="feShooterBlending"
                    v-model="shooterBlendingType"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterVoltage">
                    Voltage
                  </label>
                  <d-input
                    id="feShooterVoltage"
                    v-model="shooterVoltage"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterDesignType">
                    Design
                  </label>
                  <d-input
                    id="feShooterDesignType"
                    v-model="shooterDesign"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterDiffuseDistance">
                    Distance
                  </label>
                  <d-input
                    id="feShooterDiffuseDistance"
                    v-model="shooterDistance"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterLED">
                    LEDs
                  </label>
                  <d-input
                    id="feShooterLED"
                    v-model="shooterLed"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterDiffuseMethod">
                    Diffuse method
                  </label>
                  <d-input
                    id="feShooterDiffuseMethod"
                    v-model="shooterDiffuseMethod"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterPower">
                    Power
                  </label>
                  <d-input
                    id="feShooterPower"
                    v-model="shooterPower"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterSize">
                    Size
                  </label>
                  <d-input
                    id="feShooterSize"
                    v-model="shooterSize"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterSoftwareIncluded">
                    Software included
                  </label>
                  <d-input
                    id="feShooterSoftwareIncluded"
                    v-model="shooterSoftwares"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterWeight">
                    Weight
                  </label>
                  <d-input
                    id="feShooterWeight"
                    v-model="shooterWeight"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col md="6">
                  <label for="feShooterVideoUrl">
                    Video URL
                  </label>
                  <d-input
                    id="feShooterVideoUrl"
                    v-model="shooterVideoUrl"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feShooterTempRange">
                    Temp range
                  </label>
                  <d-input
                    id="feShooterTempRange"
                    v-model="shooterTempRange"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feShooterAccessories">
                    Accessories
                  </label>
                  <d-input
                    id="feShooterAccessories"
                    v-model="shooterAccessories"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feShooterENDocumentLinks">
                    Documents (EN)
                  </label>
                  <VueTrix
                    id="feShooterENDocumentLinks"
                    v-model="shooterDocumentsEN"
                  />
                </d-col>
              </d-form-row>
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feShooterJPDocumentLinks">
                    Documents (JP)
                  </label>
                  <VueTrix
                    id="feShooterJPDocumentLinks"
                    v-model="shooterDocumentsJP"
                  />
                </d-col>
              </d-form-row>
              <!-- Submit -->
              <d-form-row class="pt-3">
                <d-col>
                  <d-button
                    type="submit"
                    class="btn-accent mr-3"
                    @click.prevent="handleCreateShooter"
                  >
                    Create
                  </d-button>
                </d-col>
              </d-form-row>
            </d-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTrix from 'vue-trix'
import { mapActions, mapGetters } from 'vuex'
import {
  CURRENT_SHOOTER_UPDATE,
  CREATE_SHOOTER,
  SHOOTER_TITLE_UPDATE,
  SHOOTER_SLUG_UPDATE,
  SHOOTER_TYPE_UPDATE,
  SHOOTER_PREFIX_UPDATE,
  SHOOTER_COLOR_UPDATE,
  SHOOTER_PORTS_UPDATE,
  SHOOTER_BLENDING_UPDATE,
  SHOOTER_VOLTAGE_UPDATE,
  SHOOTER_DESIGN_UPDATE,
  SHOOTER_DISTANCE_UPDATE,
  SHOOTER_LED_UPDATE,
  SHOOTER_METHOD_UPDATE,
  SHOOTER_POWER_UPDATE,
  SHOOTER_SIZE_UPDATE,
  SHOOTER_SOFTWARES_UPDATE,
  SHOOTER_WEIGHT_UPDATE,
  SHOOTER_VIDEO_UPDATE,
  SHOOTER_TEMP_UPDATE,
  SHOOTER_ACCESSORIES_UPDATE,
  SHOOTER_DOCUMENTS_EN_UPDATE,
  SHOOTER_DOCUMENTS_JP_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'ShooterCreate',
  components: {
    VueTrix
  },
  created () {
    // Set current aroma shooter
    this.updateCurrentShooter({
      title: '',
      slug: '',
      shooter_type: '',
      serial_prefix: '',
      color: '',
      max_ports: '',
      blending_aromas: '',
      current: '',
      design_type: '',
      ejection_distance: '',
      led_lights: '',
      method: '',
      power: '',
      size: '',
      softwares_included: '',
      weight: '',
      video_url: '',
      use_temp_range: '',
      accessories: '',
      document_links: '',
      jp_document_links: ''
    })
  },
  computed: {
    ...mapGetters({
      currentAromaShooter: 'AromaShootersManagement/currentAromaShooter'
    }),
    shooterTitle: {
      get () {
        return this.currentAromaShooter.title
      },
      set (newTitle) {
        this.updateShooterTitle(newTitle)
        this.updateShooterSlug(newTitle)
      }
    },
    shooterSlug: {
      get () {
        return this.currentAromaShooter.slug
      },
      set (newSlug) {
        this.updateShooterSlug(newSlug)
      }
    },
    shooterType: {
      get () {
        return this.currentAromaShooter.shooter_type
      },
      set (newType) {
        this.updateShooterType(newType)
      }
    },
    shooterPrefix: {
      get () {
        return this.currentAromaShooter.serial_prefix
      },
      set (newPrefix) {
        this.updateShooterPrefix(newPrefix)
      }
    },
    shooterColor: {
      get () {
        return this.currentAromaShooter.color
      },
      set (newColor) {
        this.updateShooterColor(newColor)
      }
    },
    shooterPorts: {
      get () {
        return this.currentAromaShooter.max_ports
      },
      set (newPorts) {
        this.updateShooterPorts(newPorts)
      }
    },
    shooterBlendingType: {
      get () {
        return this.currentAromaShooter.blending_aromas
      },
      set (newBlending) {
        this.updateShooterBlending(newBlending)
      }
    },
    shooterVoltage: {
      get () {
        return this.currentAromaShooter.current
      },
      set (newCurrent) {
        this.updateShooterVoltage(newCurrent)
      }
    },
    shooterDesign: {
      get () {
        return this.currentAromaShooter.design_type
      },
      set (newDesign) {
        this.updateShooterDesign(newDesign)
      }
    },
    shooterDistance: {
      get () {
        return this.currentAromaShooter.ejection_distance
      },
      set (newDistance) {
        this.updateShooterDistance(newDistance)
      }
    },
    shooterLed: {
      get () {
        return this.currentAromaShooter.led_lights
      },
      set (newLedLights) {
        this.updateShooterLed(newLedLights)
      }
    },
    shooterDiffuseMethod: {
      get () {
        return this.currentAromaShooter.method
      },
      set (newDiffuseMethod) {
        this.updateShooterDiffuseMethod(newDiffuseMethod)
      }
    },
    shooterPower: {
      get () {
        return this.currentAromaShooter.power
      },
      set (newPower) {
        this.updateShooterPower(newPower)
      }
    },
    shooterSize: {
      get () {
        return this.currentAromaShooter.size
      },
      set (newSize) {
        this.updateShooterSize(newSize)
      }
    },
    shooterSoftwares: {
      get () {
        return this.currentAromaShooter.softwares_included
      },
      set (newSoftwares) {
        this.updateShooterSoftwares(newSoftwares)
      }
    },
    shooterWeight: {
      get () {
        return this.currentAromaShooter.weight
      },
      set (newWeight) {
        this.updateShooterWeight(newWeight)
      }
    },
    shooterVideoUrl: {
      get () {
        return this.currentAromaShooter.video_url
      },
      set (newVideoUrl) {
        this.updateShooterVideoUrl(newVideoUrl)
      }
    },
    shooterTempRange: {
      get () {
        return this.currentAromaShooter.use_temp_range
      },
      set (newTempRange) {
        this.updateShooterTempRange(newTempRange)
      }
    },
    shooterAccessories: {
      get () {
        return this.currentAromaShooter.accessories
      },
      set (newAccessories) {
        this.updateShooterAccessories(newAccessories)
      }
    },
    shooterDocumentsEN: {
      get () {
        return this.currentAromaShooter.document_links
      },
      set (newDocumentsEN) {
        this.updateShooterDocumentEN(newDocumentsEN)
      }
    },
    shooterDocumentsJP: {
      get () {
        return this.currentAromaShooter.jp_document_links
      },
      set (newDocumentsJP) {
        this.updateShooterDocumentJP(newDocumentsJP)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentShooter: `AromaShootersManagement/${CURRENT_SHOOTER_UPDATE}`,
      createAromaShooter: `AromaShootersManagement/${CREATE_SHOOTER}`,
      updateShooterTitle: `AromaShootersManagement/${SHOOTER_TITLE_UPDATE}`,
      updateShooterSlug: `AromaShootersManagement/${SHOOTER_SLUG_UPDATE}`,
      updateShooterType: `AromaShootersManagement/${SHOOTER_TYPE_UPDATE}`,
      updateShooterPrefix: `AromaShootersManagement/${SHOOTER_PREFIX_UPDATE}`,
      updateShooterColor: `AromaShootersManagement/${SHOOTER_COLOR_UPDATE}`,
      updateShooterPorts: `AromaShootersManagement/${SHOOTER_PORTS_UPDATE}`,
      updateShooterBlending: `AromaShootersManagement/${SHOOTER_BLENDING_UPDATE}`,
      updateShooterVoltage: `AromaShootersManagement/${SHOOTER_VOLTAGE_UPDATE}`,
      updateShooterDesign: `AromaShootersManagement/${SHOOTER_DESIGN_UPDATE}`,
      updateShooterDistance: `AromaShootersManagement/${SHOOTER_DISTANCE_UPDATE}`,
      updateShooterLed: `AromaShootersManagement/${SHOOTER_LED_UPDATE}`,
      updateShooterDiffuseMethod: `AromaShootersManagement/${SHOOTER_METHOD_UPDATE}`,
      updateShooterPower: `AromaShootersManagement/${SHOOTER_POWER_UPDATE}`,
      updateShooterSize: `AromaShootersManagement/${SHOOTER_SIZE_UPDATE}`,
      updateShooterSoftwares: `AromaShootersManagement/${SHOOTER_SOFTWARES_UPDATE}`,
      updateShooterWeight: `AromaShootersManagement/${SHOOTER_WEIGHT_UPDATE}`,
      updateShooterVideoUrl: `AromaShootersManagement/${SHOOTER_VIDEO_UPDATE}`,
      updateShooterTempRange: `AromaShootersManagement/${SHOOTER_TEMP_UPDATE}`,
      updateShooterAccessories: `AromaShootersManagement/${SHOOTER_ACCESSORIES_UPDATE}`,
      updateShooterDocumentEN: `AromaShootersManagement/${SHOOTER_DOCUMENTS_EN_UPDATE}`,
      updateShooterDocumentJP: `AromaShootersManagement/${SHOOTER_DOCUMENTS_JP_UPDATE}`
    }),
    // Handle updating new Aroma Shooter event
    handleCreateShooter () {
      this.createAromaShooter({ shooter: this.currentAromaShooter, locale: this.$i18n.locale })
    }
  }
}
</script>
