import Vue from 'vue'
import {
  SET_REMOTE_MESSAGE,
  SET_CURRENT_SUBSCRIBER,
  SET_SUBSCRIBERS_LIST,
  SET_PAGINATION_META
} from '../../mutationTypes'
import {
  FETCH_SUBSCRIBERS,
  CURRENT_SUBSCRIBER_UPDATE
} from '../../actionTypes'

function sortSubscribers (firstSubscriber, secondSubscriber) {
  if (firstSubscriber.createdAt > secondSubscriber.createdAt) {
    return -1
  }
  if (firstSubscriber.createdAt < secondSubscriber.createdAt) {
    return 1
  }

  return 0
}

const subscriberActions = {
  async [FETCH_SUBSCRIBERS] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        // Invoke subscribers API
        const subscriberResponse = await Vue.axios.serverlessServer.get(`subscriptions?debug=${params.debug}&locale=${params.locale}`)
        if (subscriberResponse.status === 200 && subscriberResponse.data.status) {
          if (subscriberResponse.data.statusMessage === 'Success' && subscriberResponse.data.data.success) {
            let subscribers = subscriberResponse.data.data.data
            subscribers.sort((firstSubscriber, secondSubscriber) => sortSubscribers(firstSubscriber, secondSubscriber))

            commit(SET_SUBSCRIBERS_LIST, subscribers)
            commit(SET_PAGINATION_META, null)
          } else {
            commit(SET_REMOTE_MESSAGE, {
              message: 'Error: Getting subscribers list failed',
              type: 'danger'
            })
          }
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: unauthorize',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_SUBSCRIBER_UPDATE] ({ commit }, subscriber) {
    commit(SET_CURRENT_SUBSCRIBER, subscriber)
  }
}

export default subscriberActions
