import Vue from 'vue'
import router from '@/router'
import { USER_KEY } from '@/constants/localStorages'
import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CURRENT_USER_UPDATE,
  USER_PROFILE_UPDATE,
  VERIFY_USER_TOKEN
} from '../../actionTypes'
import {
  SET_REMOTE_MESSAGE,
  SET_USER,
  SET_USER_PROFILE
} from '../../mutationTypes'

const userActions = {
  async [VERIFY_USER_TOKEN] ({ commit }, token) {
    try {
      // Reset remote messages
      commit(SET_REMOTE_MESSAGE, null)
      const verifyResponse = await Vue.axios.authServer.post('siteverify', {
        session: {
          response: token
        }
      })

      if (verifyResponse.status === 200 && verifyResponse.data.success && verifyResponse.data.score > 0.5) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'verified successfully',
          type: 'success'
        })
        return verifyResponse.data
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: reCaptcha verify failed',
          type: 'danger'
        })
        return false
      }
    } catch (error) {
      console.log(error)
      if (error.response && error.response.status === 401) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error [' + (error.response.status) + ']: reCaptcha is invalid.',
          type: 'danger'
        })
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + error.message,
          type: 'danger'
        })
      }
      return null
    }
  },
  async [SIGN_IN] ({ commit, dispatch }, credentials) {
    try {
      // Reset remote messages
      commit(SET_REMOTE_MESSAGE, null)
      // Sign in with user's credentials
      const loginResponses = await Vue.axios.authServer.post('sessions', {
        session: credentials
      })

      if (loginResponses.statusText === 'Created' && loginResponses.data.data) {
        if (loginResponses.data.data.user_type === 'super_admin' ||
          loginResponses.data.data.user_type === 'administrator') {
          commit(SET_USER, loginResponses.data.data)
          localStorage.setItem(USER_KEY, JSON.stringify(loginResponses.data.data))
          await dispatch(FETCH_USER_PROFILE)
          router.replace({ name: 'home' })
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error [' + loginResponses.status + ']: No valid permissions.',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error [' + loginResponses.status + ']: Invalid credentials.',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      if (error.response && error.response.status === 401) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error [' + (error.response.status) + ']: Email or password is invalid.',
          type: 'danger'
        })
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + error.message,
          type: 'danger'
        })
      }
    }
  },
  async [SIGN_OUT] ({ commit }) {
    try {
      // Reset remote messages
      commit(SET_REMOTE_MESSAGE, null)
      // Clear user local states
      await commit(SET_USER, null)
      await localStorage.setItem(USER_KEY, null)
      // Reset routing
      router.replace({ name: 'sign-in' })
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + (error.message || error.response.data.error),
        type: 'danger'
      })
    }
  },
  async [FETCH_USER_PROFILE] ({ commit, getters }) {
    try {
      // Reset remote messages
      commit(SET_REMOTE_MESSAGE, null)
      // Get user's profile
      const currentAccount = getters['user']
      const profileResponses = await Vue.axios.authServer.get(`users/${currentAccount.id}/profiles`, {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (profileResponses.data) {
        commit(SET_USER_PROFILE, profileResponses.data)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + profileResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + (error.message || error.response.data.error),
        type: 'danger'
      })
    }
  },
  async [UPDATE_USER_PROFILE] ({ commit, getters }, profile) {
    try {
      // Reset remote messages
      commit(SET_REMOTE_MESSAGE, null)
      // Get current credentials
      const currentAccount = getters['user']
      const profile = getters['profile']
      if (currentAccount && currentAccount.authentication_token) {
        // Update profile request
        const profileResponses = await Vue.axios.authServer.put(`users/${currentAccount.id}/profiles/${profile.id}`, {
          profile: profile
        }, {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        })
        if (profileResponses.status !== 200) {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: ' + profileResponses.data.error,
            type: 'danger'
          })
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Update profile successfully!',
            type: 'success'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: Invalid request',
          type: 'danger'
        })
      }
    } catch (error) {
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + (error.message || error.response.data.error),
        type: 'danger'
      })
    }
  },
  [CURRENT_USER_UPDATE] ({ commit }, user) {
    commit(SET_USER, user)
  },
  [USER_PROFILE_UPDATE] ({ commit }, profile) {
    commit(SET_USER_PROFILE, profile)
  }
}

export default userActions
