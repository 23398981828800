<template>
  <div>
    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <d-button-toolbar class="mb-4">
              <d-button-group>
                <d-button
                  @click="goToNewCareerPage"
                >
                  Create
                </d-button>
                <d-button
                  @click="toggleEditMode"
                >
                  {{ this.editing ? 'View' : 'Edit' }}
                </d-button>
              </d-button-group>
            </d-button-toolbar>
            <h6 class="text-muted">
              "{{ currentCareer.title }}" details
            </h6>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form
              @submit.prevent="handleUpdateCareer"
            >
              <!-- TITLE -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feTitle">Title*</label>
                  <d-input
                    v-model="title"
                    id="feTitle"
                    :disabled="!this.editing"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <!-- DEADLINE -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feDeadline">Deadline</label>
                  <d-datepicker
                    v-model="deadline"
                    id="feDeadline"
                    typeable
                    :disabled="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <!-- SLUG -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feSlug">Slug*</label>
                  <d-input
                    id="feSlug"
                    v-model="slug"
                    :disabled="!this.editing"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <!-- REQUIREMENTS -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feRequirements">Requirements</label>
                  <VueTrix
                    id="feRequirements"
                    v-model="requirements"
                    :disabled-editor="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <!-- DESCRIPTION -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feDescription">Description</label>
                  <VueTrix
                    id="feDescription"
                    v-model="description"
                    :disabled-editor="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <!-- BENEFITS -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feBenefits">Benefits</label>
                  <VueTrix
                    id="feBenefits"
                    v-model="benefits"
                    :disabled-editor="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <!-- WORK CONDITIONS -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feConditions">Work Conditions</label>
                  <VueTrix
                    id="feConditions"
                    v-model="conditions"
                    :disabled-editor="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <!-- LOCATION -->
              <d-form-row class="mb-3">
                <d-col>
                  <label for="feLocation">Location</label>
                  <d-input
                    v-model="location"
                    id="feLocation"
                    :disabled="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <d-button-group v-if="this.editing">
                <d-button
                  type="submit"
                  class="btn-accent mr-3"
                >
                  Update
                </d-button>
                <d-button
                  theme="danger"
                  class="mr-3"
                  @click.prevent="handleDeleteCareer"
                >
                  Delete
                </d-button>
              </d-button-group>
            </d-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'
import {
  CURRENT_CAREER_UPDATE,
  CAREER_TITLE_UPDATE,
  CAREER_DEADLINE_UPDATE,
  CAREER_SLUG_UPDATE,
  CAREER_REQUIREMENTS_UPDATE,
  CAREER_DESCRIPTION_UPDATE,
  CAREER_BENEFITS_UPDATE,
  CAREER_CONDITIONS_UPDATE,
  CAREER_LOCATION_UPDATE,
  UPDATE_CAREER,
  DELETE_CAREER
} from '@/states/actionTypes'

export default {
  name: 'CareerView',
  components: {
    VueTrix
  },
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      editing: false
    }
  },
  mounted () {
    this.editing = this.$route.params.action === 'edit'
    const careerId = parseInt(this.id)
    if (careerId) {
      const careerToEdit = this.getCareerById(careerId)
      this.updateCurrentCareer(careerToEdit)
    }
  },
  computed: {
    ...mapGetters({
      getCareerById: 'CareersManagement/getCareerById',
      currentCareer: 'CareersManagement/currentCareer'
    }),
    title: {
      get () {
        return this.currentCareer.title
      },
      set (newTitle) {
        this.updateCareerTitle(newTitle)
        this.updateCareerSlug(newTitle)
      }
    },
    deadline: {
      get () {
        return this.currentCareer.deadline
      },
      set (newDeadline) {
        this.updateCareerDeadline(newDeadline)
      }
    },
    slug: {
      get () {
        return this.currentCareer.slug
      },
      set (newSlug) {
        this.updateCareerSlug(newSlug)
      }
    },
    requirements: {
      get () {
        return this.currentCareer.requirements
      },
      set (newReq) {
        this.updateCareerRequirements(newReq)
      }
    },
    description: {
      get () {
        return this.currentCareer.description
      },
      set (newDescription) {
        this.updateCareerDescription(newDescription)
      }
    },
    benefits: {
      get () {
        return this.currentCareer.benefits
      },
      set (newBenefits) {
        this.updateCareerBenefits(newBenefits)
      }
    },
    conditions: {
      get () {
        return this.currentCareer.conditions
      },
      set (newConditions) {
        this.updateCareerConditions(newConditions)
      }
    },
    location: {
      get () {
        return this.currentCareer.location
      },
      set (newLocation) {
        this.updateCareerLocation(newLocation)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentCareer: `CareersManagement/${CURRENT_CAREER_UPDATE}`,
      updateCareer: `CareersManagement/${UPDATE_CAREER}`,
      deleteCareer: `CareersManagement/${DELETE_CAREER}`,
      updateCareerTitle: `CareersManagement/${CAREER_TITLE_UPDATE}`,
      updateCareerDeadline: `CareersManagement/${CAREER_DEADLINE_UPDATE}`,
      updateCareerSlug: `CareersManagement/${CAREER_SLUG_UPDATE}`,
      updateCareerRequirements: `CareersManagement/${CAREER_REQUIREMENTS_UPDATE}`,
      updateCareerDescription: `CareersManagement/${CAREER_DESCRIPTION_UPDATE}`,
      updateCareerBenefits: `CareersManagement/${CAREER_BENEFITS_UPDATE}`,
      updateCareerConditions: `CareersManagement/${CAREER_CONDITIONS_UPDATE}`,
      updateCareerLocation: `CareersManagement/${CAREER_LOCATION_UPDATE}`
    }),
    toggleEditMode () {
      this.editing = !this.editing
    },
    handleUpdateCareer () {
      this.updateCareer({
        career: this.currentCareer,
        locale: this.$i18n.locale
      })
      this.toggleEditMode()
    },
    handleDeleteCareer () {
      if (confirm(`Are you sure you want to delete [${this.currentCareer.title}]?`)) {
        this.deleteCareer(this.currentCareer.id)
      }
    },
    goToNewCareerPage () {
      this.$router.push({
        name: 'new-career'
      })
    }
  }
}
</script>
