import { mapGetters } from 'vuex'

export default function (messageRoot) {
  return {
    computed: {
      ...mapGetters({
        remoteMessage: messageRoot
      }),
      showMessage () {
        if (this.remoteMessage && this.remoteMessage.message) {
          return true
        }
        return false
      },
      notifiedMessage () {
        return this.remoteMessage && this.remoteMessage.message ? this.remoteMessage.message : ''
      },
      messageType () {
        return this.remoteMessage && this.remoteMessage.type ? this.remoteMessage.type : 'info'
      },
      dismissSecs () {
        return this.remoteMessage && this.remoteMessage.type === 'danger' ? 7 : 3
      }
    }
  }
}
