<template>
  <div class="mx-lg-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          User details
        </h3>
      </div>
    </div>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="text-muted">
              General account information
            </h6>
            <d-button-toolbar v-if="!this.creating">
              <d-button-group>
                <d-button
                  @click="handleCreateUser"
                >
                  Create
                </d-button>
                <d-button
                  @click="() => this.editing = !this.editing"
                >
                  {{ this.editing ? 'View' : 'Edit' }}
                </d-button>
                <d-button v-if="this.editing">
                  Promote to admin
                </d-button>
              </d-button-group>
            </d-button-toolbar>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form>
              <d-form-row>
                <d-col md="6">
                  <label for="feEmailAddress">Email</label>
                  <d-input-group
                    prepend="@"
                    class="mb-3"
                  >
                    <d-input
                      v-model="user.email"
                      id="feEmailAddress"
                      placeholder="Email"
                      :disabled="!this.editing"
                    />
                  </d-input-group>
                </d-col>
                <d-col md="6">
                  <label for="feRole">Role</label>
                  <d-select
                    id="feRole"
                    v-model="user.user_type"
                    :disabled="!this.editing"
                  >
                    <option value="ordinary_user">
                      Ordinary
                    </option>
                    <option value="administrator">
                      Administrator
                    </option>
                    <option value="super_admin">
                      Super Administrator
                    </option>
                  </d-select>
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feAuthToken">Auth token</label>
                  <d-input
                    v-model="user.authentication_token"
                    id="feAuthToken"
                    class="mb-3"
                    disabled
                  />
                </d-col>
                <d-col md="6">
                  <label for="feOAuthProvider">OAuth provider</label>
                  <d-input
                    v-model="user.provider"
                    id="feOAuthProvider"
                    class="mb-3"
                    disabled
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feUUID">UID</label>
                  <d-input
                    v-model="user.uid"
                    id="feUUID"
                    class="mb-3"
                    disabled
                  />
                </d-col>
                <d-col md="6">
                  <label for="feFbToken">Facebook token</label>
                  <d-input
                    v-model="user.facebook_token"
                    id="feFbToken"
                    class="mb-3"
                    disabled
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="feTokenExpiry">Token expiry time</label>
                  <d-input
                    :value="user.token_expiry | formatDate"
                    id="feAuthToken"
                    class="mb-3"
                    disabled
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="feUpdatedAt">Updated at</label>
                  <d-input
                    :value="user.updated_at | formatDate"
                    id="feUpdatedAt"
                    disabled
                    class="mb-3"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="feCreatedAt">Created at</label>
                  <d-input
                    :value="user.created_at | formatDate"
                    id="feCreatedAt"
                    disabled
                    class="mb-3"
                  />
                </d-col>
              </d-form-row>
              <d-button
                v-if="this.editing"
                type="submit"
                class="btn-accent"
              >
                Update
              </d-button>
              <d-button
                v-else-if="this.creating"
                type="submit"
                class="btn-accent"
              >
                Create new user
              </d-button>
            </d-form>
          </div>
          <div class="card-footer">
            <d-button
              v-if="!this.creating"
              theme="warning"
              @click="handleResetAuthToken"
            >
              Reset auth token
            </d-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserView',
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      user: {},
      editing: false,
      creating: false
    }
  },
  mounted () {
    const userId = parseInt(this.id)
    if (userId) {
      this.user = this.users.find((user) => {
        return user.id === userId
      })
      this.editing = this.$route.params.action === 'edit'
    } else {
      this.creating = this.$route.params.action === 'new'
    }
  },
  computed: {
    ...mapGetters({
      users: 'UsersManagement/users'
    })
  },
  methods: {
    handleCreateUser () {
      this.editing = false
      this.creating = true
      this.$router.push({
        name: 'user-new',
        params: { action: 'new' }
      })
    },
    handleResetAuthToken () {
      alert(`Are you sure to reset JWT token of ${this.user.email}?`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
