/**
 * Aroma Shooters management states
 **/
const aromaShootersManagementStates = {
  currentAromaShooter: null,
  aromaShooters: [],
  paginationMeta: {
    current: 0,
    limit: 10,
    pages: 0,
    next: 0,
    prev: 0
  },
  remoteMessage: null
}

export default aromaShootersManagementStates
