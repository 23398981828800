/** Audits management states */

const auditStates = {
  audits: [],
  currentAudit: null,
  paginationMeta: null,
  remoteMessage: null
}

export default auditStates
