/**
 * Applications management getters
 **/
const applicationsManagementGetters = {
  applications: (state) => state.applications,
  currentApplication: (state) => state.currentApplication,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default applicationsManagementGetters
