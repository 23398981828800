/**
 * FAQs management getters
 **/
const faqsManagementGetters = {
  faqs: (state) => state.faqs,
  currentFAQ: (state) => state.currentFAQ,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default faqsManagementGetters
