/**
 * Career management mutations
 **/
import {
  SET_CAREER_LIST,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_CURRENT_CAREER,
  SET_CAREER_TITLE,
  SET_CAREER_DEADLINE,
  SET_CAREER_SLUG,
  SET_CAREER_REQUIREMENTS,
  SET_CAREER_DESCRIPTION,
  SET_CAREER_BENEFITS,
  SET_CAREER_CONDITIONS,
  SET_CAREER_LOCATION
} from '@/states/mutationTypes'

const careersManagementMutations = {
  [SET_CAREER_LIST]: (state, careers) => {
    state.careers = careers
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  },
  [SET_CURRENT_CAREER]: (state, career) => {
    state.currentCareer = career
  },
  [SET_CAREER_TITLE]: (state, title) => {
    state.currentCareer.title = title
  },
  [SET_CAREER_DEADLINE]: (state, deadline) => {
    state.currentCareer.deadline = deadline
  },
  [SET_CAREER_SLUG]: (state, slug) => {
    state.currentCareer.slug = slug
  },
  [SET_CAREER_REQUIREMENTS]: (state, requirements) => {
    state.currentCareer.requirements = requirements
  },
  [SET_CAREER_DESCRIPTION]: (state, description) => {
    state.currentCareer.description = description
  },
  [SET_CAREER_BENEFITS]: (state, benefits) => {
    state.currentCareer.benefits = benefits
  },
  [SET_CAREER_CONDITIONS]: (state, conditions) => {
    state.currentCareer.nice_to_have = conditions
  },
  [SET_CAREER_LOCATION]: (state, location) => {
    state.currentCareer.work_location = location
  }
}

export default careersManagementMutations
