<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0 text-muted">
        Orders List
      </h6>
    </div>
    <div class="card-body p-0 pb-2 text-left">
      <div
        v-if="orders && orders.length > 0"
        class="table-responsive"
      >
        <table class="table table-hover m-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="border-0"
              >
                ID
              </th>
              <th
                scope="col"
                class="border-0"
              >
                State
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Customer
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Order total
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Payment
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Last modified
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <OrderItem
              v-for="orderItem in orders"
              :key="orderItem.id"
              :order-item="orderItem"
            />
          </tbody>
        </table>
      </div>
      <base-loading
        v-else
      />
    </div>
    <div class="card-footer p-0">
      <!-- Pagination -->
      <d-row class="mb-3">
        <d-col
          md="12"
          class="ml-3"
        >
          <d-button
            @click="handleLoadMoreOrder"
            :disabled="!lastEvaluatedKey"
            theme="primary"
          >
            Load more
          </d-button>
        </d-col>
      </d-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FETCH_ORDERS } from '@/states/actionTypes'
import OrderItem from './OrderItem'

export default {
  name: 'OrdersList',
  components: {
    OrderItem
  },
  mounted () {
    if (this.lastEvaluatedKey !== null) {
      this.fetchOrders({
        debug: true,
        locale: this.$i18n.locale,
        page: 1,
        size: 10
      })
    }
  },
  computed: {
    ...mapGetters({
      orders: 'OrdersManagement/sortedOrders',
      paginationMeta: 'OrdersManagement/paginationMeta',
      lastEvaluatedKey: 'OrdersManagement/lastEvaluatedKey'
    })
  },
  methods: {
    ...mapActions({
      fetchOrders: `OrdersManagement/${FETCH_ORDERS}`
    }),
    handleLoadMoreOrder () {
      this.fetchOrders({
        debug: true,
        locale: this.$i18n.locale,
        page: 1,
        size: 10
      })
    }
  }
}
</script>
