import accounting from 'accounting'
import {
  isToday,
  formatDistance,
  format,
  parseISO
} from 'date-fns'

/**
 * Convert number into Japanese currency
 * @param {*} value
 */
export function toJPYCurrency (value) {
  if (typeof value !== 'number') {
    return value
  }
  return accounting.formatMoney(value, '¥', 0, ',', '.')
}

export function transformSnakeCase (string) {
  if (typeof string !== 'string') {
    return string
  }
  let replaceString = string.replace(/_/, ' ')
  return replaceString.trim()
}

/**
 *
 * @param {*} value
 */
export function toFixed (value) {
  if (typeof value !== 'number') {
    return value
  }
  return accounting.toFixed(value, 2)
}

/**
 *
 * @param {*} value
 */
export function toNumber (value) {
  if (typeof value !== 'number') {
    return value
  }
  return accounting.formatNumber(value, '.', ',')
}

export function formatDate (date) {
  date = parseISO(date)
  return format(date, 'MMM do, yyyy hh:mm:ss')
}

export function formatDateRelative (fromDate, toDate = new Date()) {
  fromDate = parseISO(fromDate)
  toDate = parseISO(toDate)
  return formatDistance(fromDate, toDate) + (isToday(toDate) ? ' ago' : '')
}

export function formatDateFromUnixTime (fromDate) {
  const targetDateTimeStr = new Date(fromDate).toISOString().substr(0, 10)
  return format(parseISO(targetDateTimeStr), 'MMM do, yyyy')
}
