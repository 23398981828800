<template>
  <Transition appear>
    <BaseIcon
      :class="$style.loadingIcon"
      name="sync"
      spin
    />
  </Transition>
</template>

<script>
export default {
  name: 'Loading',
  metaInfo: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }]
  }
}
</script>

<style lang="scss" module>
// Design variables and global utilities from src/design.
@import '@/design/custom_variables.scss';

.loadingIcon {
  @extend %typography-xlarge;
  display: block;
  margin: 0 auto;
  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter-active) {
    transition: opacity 1s;
  }
  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter) {
    opacity: 0;
  }
}
</style>
