<template>
  <div class="main-content-container container-fluid px-4">
    <!-- Notification -->
    <base-notify
      :display="showMessage"
      :message="notifiedMessage"
      :dismiss-secs="7"
      :type="messageType"
      position="top-left"
    />

    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          Members Information
        </h3>
      </div>
    </div>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0 text-muted">
              Members List
            </h6>
          </div>
          <div class="card-body p-0 pb-2 text-left">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                    <th
                      scope="col"
                      class="border-0 text-center"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Updated at
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="member in members"
                    :key="member.id"
                  >
                    <td class="text-center">
                      {{ member.id }}
                    </td>
                    <td>{{ member.name }}</td>
                    <td>{{ member.title }}</td>
                    <td>{{ member.updated_at | formatDate }}</td>
                    <td>
                      <d-button-group class="d-flex justify-content-center btn-group-sm">
                        <d-button
                          class="btn-white"
                          @click="operation(member, 'view')"
                        >
                          <i class="material-icons">
                            credit_card
                          </i>
                        </d-button>
                        <d-button
                          class="btn-white"
                          @click="operation(member, 'edit')"
                        >
                          <i class="material-icons">
                            create
                          </i>
                        </d-button>
                        <d-button
                          class="btn-white"
                          @click="operation(member, 'delete')"
                        >
                          <i class="material-icons">
                            delete_sweep
                          </i>
                        </d-button>
                      </d-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer p-0">
            <!-- Pagination -->
            <base-pagination
              :pagination-meta="paginationMeta"
              @go-to-page="handleLoadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'
import {
  FETCH_MEMBERS_LIST
} from '@/states/actionTypes'

export default {
  name: 'Members',
  mixins: [
    ProcessErrorMessages('MembersManagement/remoteMessage')
  ],
  mounted () {
    this.fetchMembers({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      members: 'MembersManagement/members',
      paginationMeta: 'MembersManagement/paginationMeta',
      remoteMessage: 'MembersManagement/remoteMessage'
    })
  },
  methods: {
    ...mapActions({
      fetchMembers: `MembersManagement/${FETCH_MEMBERS_LIST}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchMembers({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchMembers({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchMembers({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    },
    operation (member, type) {
      // TODO: Do Member record opertions
      alert(type + ': ' + member.name)
    }
  },
  page: {
    title: 'Members management',
    links: [
      {
        rel: 'canonical',
        href: 'https://admin.aromajoin.com/members'
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
</style>
