import {
  GOOGLE_ACCESS_SESSION_UPDATE,
  SYNC_GA_PAGEVIEWS,
  GA_PAGEVIEWS_UPDATE,
  GA_PAGELOADTIME_UPDATE,
  SYNC_GA_PAGELOADTIME,
  SYNC_GA_CURRENT_USERS,
  SYNC_GA_NEW_VISITORS,
  SYNC_GA_SESSIONS,
  SYNC_GA_USER_DEVICES
} from '../../actionTypes'
import {
  SET_GOOGLE_ACCESS_SESSION,
  SET_STATS_PAGEVIEW,
  SET_STATS_PAGELOADTIME,
  SET_STATS_CURRENT_USERS,
  SET_STATS_NEW_VISITORS,
  SET_STATS_SESSIONS,
  SET_STATS_DEVICE_CATEGORIES
} from '../../mutationTypes'

/**
 * Web default actions
 */
const WebDefaultActions = {
  async [SYNC_GA_USER_DEVICES] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:users'
                }
              ],
              dimensions: [
                {
                  name: 'ga:deviceCategory'
                }
              ]
            }
          ]
        }
      })
      const devices = [
        response.result.reports[0].data.rows[0].metrics[0].values[0],
        response.result.reports[0].data.rows[1].metrics[0].values[0],
        response.result.reports[0].data.rows[2].metrics[0].values[0]
      ]
      commit(SET_STATS_DEVICE_CATEGORIES, devices)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  async [SYNC_GA_SESSIONS] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:sessions'
                }
              ]
            }
          ]
        }
      })
      const sessions = response.result.reports[0].data.totals[0].values[0]
      commit(SET_STATS_SESSIONS, sessions)
      // console.log('Sessions:', sessions)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  async [SYNC_GA_NEW_VISITORS] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:newUsers'
                }
              ]
            }
          ]
        }
      })
      const newUsers = response.result.reports[0].data.totals[0].values[0]
      commit(SET_STATS_NEW_VISITORS, newUsers)
      // console.log('New users:', newUsers)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  async [SYNC_GA_CURRENT_USERS] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:users'
                }
              ]
            }
          ]
        }
      })
      const currentUsers = response.result.reports[0].data.totals[0].values[0]
      commit(SET_STATS_CURRENT_USERS, currentUsers)
      // console.log('Current users:', currentUsers)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  async [SYNC_GA_PAGELOADTIME] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:avgPageLoadTime'
                }
              ]
            }
          ]
        }
      })
      const loadTime = Math.round(response.result.reports[0].data.totals[0].values[0] * 100) / 100
      commit(SET_STATS_PAGELOADTIME, loadTime)
      // console.log('Load time:', loadTime)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  async [SYNC_GA_PAGEVIEWS] ({ commit }, accessToken) {
    try {
      const response = await window.gapi.client.request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: '131938523',
              dateRanges: [
                {
                  startDate: '30daysAgo',
                  endDate: 'yesterday'
                }
              ],
              metrics: [
                {
                  expression: 'ga:pageviews'
                }
              ]
            }
          ]
        }
      })
      const pageviews = response.result.reports[0].data.totals[0].values[0]
      commit(SET_STATS_PAGEVIEW, pageviews)
      // console.log('Pageviews:', pageviews)
    } catch (error) {
      console.error('Error:', error)
    }
  },
  [GA_PAGEVIEWS_UPDATE] ({ commit }, pageviews) {
    commit(SET_STATS_PAGEVIEW, pageviews)
  },
  [GOOGLE_ACCESS_SESSION_UPDATE] ({ commit }, session) {
    commit(SET_GOOGLE_ACCESS_SESSION, session)
  },
  [GA_PAGELOADTIME_UPDATE] ({ commit }, loadTime) {
    commit(SET_STATS_PAGELOADTIME, loadTime)
  }
}

export default WebDefaultActions
