import { USER_KEY } from '@/constants/localStorages'
import Vue from 'vue'
import store from '../states'
import { CURRENT_USER_UPDATE } from '../states/actionTypes'

export async function refreshToken (currentRequest) {
  const account = JSON.parse(localStorage.getItem(USER_KEY))
  // console.log('Current credentials', account)
  const refreshResponses = await Vue.axios.authServer.get(`users/${account.id}/refresh_token`, {
    headers: {
      Authorization: account.authentication_token
    }
  })
  // console.log('New credentials', refreshResponses.data)
  if (refreshResponses.status === 200 && refreshResponses.data) {
    account.authentication_token = refreshResponses.data.data.token
    account.token_expiry = refreshResponses.data.data.token_expiry
    await localStorage.setItem(USER_KEY, JSON.stringify(account))
    await store.dispatch(`User/${CURRENT_USER_UPDATE}`, account)
    return refreshResponses
  }
}
