<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <h6 class="text-muted">
        {{ currentAudit.id }}
      </h6>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- USER ID -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditUserId">Original User</label>
            <d-input
              id="feAuditUserId"
              :value="currentAudit.user_id"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- REMOTE ADDRESS -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditAddress">
              Remote Address
            </label>
            <d-input
              id="feAuditAddress"
              :value="currentAudit.remote_address"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- REQUEST ID -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditRequestUUID">
              Request UUID
            </label>
            <d-input
              id="feAuditRequestUUID"
              :value="currentAudit.request_uuid"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- Editable Id -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditableId">
              Auditable Id
            </label>
            <d-input
              id="feAuditableId"
              :value="currentAudit.auditable_id"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- Editable Type -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditableType">
              Auditable Type
            </label>
            <d-input
              id="feAuditableType"
              :value="currentAudit.auditable_type"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- Action -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditAction">
              Action
            </label>
            <d-input
              id="feAuditAction"
              :value="currentAudit.action"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- audited_changes -->
        <d-form-row
          v-if="currentAudit.audited_changes"
          class="mb-3"
        >
          <d-col>
            <label for="feAuditChanges">
              Changes
            </label>
            <VueTrix
              input-id="feAuditChanges"
              v-model="displayedChanges"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- Version -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAuditVersion">
              Version
            </label>
            <d-input
              id="feAuditVersion"
              :value="currentAudit.version"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>

        <!-- Created At -->
        <d-form-row class="mb-5">
          <d-col>
            <label for="feAuditCreatedAt">
              Created at
            </label>
            <d-input
              id="feAuditCreatedAt"
              :value="currentAudit.created_at | formatDate"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <d-form-row class="mb-3">
          <d-col>
            <d-button
              theme="info"
              class="mr-3"
              @click="() => { this.$router.push({ name: 'audit-logs-management' }) }"
            >
              Back
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueTrix from 'vue-trix'

export default {
  name: 'AuditView',
  components: {
    VueTrix
  },
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentAudit: 'AuditsManagement/currentAudit'
    }),
    displayedChanges () {
      return this.currentAudit.audited_changes ? JSON.stringify(this.currentAudit.audited_changes) : ''
    }
  }
}
</script>
