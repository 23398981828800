import aromaFirmwareStates from './AromaFirmwareStates'
import aromaFirmwareGetters from './AromaFirmwareGetters'
import aromaFirmwareMutations from './AromaFirmwareMutations'
import aromaFirmwareActions from './AromaFirmwareActions'

const firmwareManagement = {
  namespaced: true,
  state: aromaFirmwareStates,
  getters: aromaFirmwareGetters,
  mutations: aromaFirmwareMutations,
  actions: aromaFirmwareActions
}

export default firmwareManagement
