<template>
  <tr>
    <td class="pl-4">
      {{ subscriber.email }}
    </td>
    <td>
      {{ subscriber.origin }}
    </td>
    <td>
      {{ subscriber.activation }}
    </td>
    <td>
      {{ subscriber.createdAt | formatDateFromUnixTime }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'SubscriberItem',
  props: {
    subscriber: {
      type: Object,
      required: true
    }
  }
}
</script>
