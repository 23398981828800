/**
 * User state mutations
 */
import {
  SET_USER,
  SET_USER_PROFILE,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const userMutations = {
  [SET_USER]: (state, user) => {
    state.user = user
  },
  [SET_USER_PROFILE]: (state, profile) => {
    state.profile = profile
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default userMutations
