/**
 * FAQ management mutations
 **/
import {
  SET_FAQ_LIST,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_CURRENT_FAQ,
  SET_FAQ_QUESTION,
  SET_FAQ_SLUG,
  SET_FAQ_ANSWER,
  SET_FAQ_CATEGORY
} from '@/states/mutationTypes'

const faqsManagementMutations = {
  [SET_FAQ_LIST]: (state, faqs) => {
    state.faqs = faqs
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  },
  [SET_CURRENT_FAQ]: (state, faq) => {
    state.currentFAQ = faq
  },
  [SET_FAQ_QUESTION]: (state, question) => {
    state.currentFAQ.question = question
  },
  [SET_FAQ_ANSWER]: (state, answer) => {
    state.currentFAQ.answer = answer
  },
  [SET_FAQ_CATEGORY]: (state, category) => {
    state.currentFAQ.question_type = category
  },
  [SET_FAQ_SLUG]: (state, slug) => {
    state.currentFAQ.slug = slug
  }
}

export default faqsManagementMutations
