import ordersManagementStates from './OrdersManagementStates'
import ordersManagementGetters from './OrdersManagementGetters'
import ordersManagementMutations from './OrdersManagementMutations'
import ordersManagementActions from './OrdersManagementActions'

const ordersManagement = {
  namespaced: true,
  state: ordersManagementStates,
  getters: ordersManagementGetters,
  mutations: ordersManagementMutations,
  actions: ordersManagementActions
}

export default ordersManagement
