/**
 * News management mutations
 **/
import {
  SET_CURRENT_NEWS,
  SET_NEWS_DESCRIPTION,
  SET_NEWS_END_DATE,
  SET_NEWS_HIGHLIGHTED,
  SET_NEWS_IMAGE,
  SET_NEWS_LIST,
  SET_NEWS_SLUG,
  SET_NEWS_START_DATE,
  SET_NEWS_TITLE,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  REMOVE_NEWS_IMAGE
} from '../../mutationTypes'

const newsManagementMutations = {
  [SET_NEWS_LIST]: (state, news) => {
    state.news = news
  },
  [SET_CURRENT_NEWS]: (state, event) => {
    state.currentEvent = event
  },
  [SET_NEWS_TITLE]: (state, title) => {
    state.currentEvent.title = title
  },
  [SET_NEWS_SLUG]: (state, slug) => {
    state.currentEvent.slug = slug
  },
  [SET_NEWS_DESCRIPTION]: (state, description) => {
    state.currentEvent.description = description
  },
  [SET_NEWS_HIGHLIGHTED]: (state, highlighted) => {
    state.currentEvent.highlighted = highlighted
  },
  [SET_NEWS_START_DATE]: (state, startDate) => {
    state.currentEvent.start_date = startDate
  },
  [SET_NEWS_END_DATE]: (state, endDate) => {
    state.currentEvent.end_date = endDate
  },
  [SET_NEWS_IMAGE]: (state, image) => {
    state.currentEvent.images.push(image)
  },
  [REMOVE_NEWS_IMAGE]: (state, imageId) => {
    state.currentEvent.images = state.currentEvent.images.map((image) => {
      if (image.id === imageId) {
        image.deleted = true
      }
      return image
    })
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default newsManagementMutations
