/** Subscriber management getters */

const subscriberGetters = {
  subscribers: state => state.subscribers,
  currentSubscriber: state => state.currentSubscriber,
  paginationMeta: state => state.paginationMeta,
  remoteMessage: state => state.remoteMessage
}

export default subscriberGetters
