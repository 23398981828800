<template>
  <b-alert
    v-show="message"
    :show="dismissCountDown"
    :variant="type"
    :class="[position.replace('-', ' '), $style.alert]"
    :key="generateKey"
    dismissible
    fade
    @dismissed="dismissCountDown=0"
    @dismiss-count-down="countDownChanged"
  >
    <p>{{ message }}</p>
    <b-progress
      :variant="type"
      :max="dismissSecs"
      :value="dismissCountDown"
      height="4px"
    />
  </b-alert>
</template>

<script>
export default {
  name: 'BaseNotify',
  props: {
    message: {
      type: String,
      require: true,
      default () {
        return ''
      }
    },
    dismissSecs: {
      type: Number,
      require: false,
      default () {
        return 5
      }
    },
    type: {
      type: String,
      require: false,
      default () {
        return 'danger' // light, success, primary
      }
    },
    display: {
      type: Boolean,
      require: false,
      default () {
        return false
      }
    },
    position: {
      type: String,
      require: false,
      default () {
        return 'top-left'
      }
    }
  },
  data () {
    return {
      dismissCountDown: 0
    }
  },
  computed: {
    generateKey () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0
        var v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  },
  methods: {
    countDownChanged (dismissCountDown) {
      if (dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      }
    },
    initCountDown () {
      this.dismissCountDown = this.dismissSecs
    }
  },
  watch: {
    display: {
      handler: 'initCountDown'
    }
  }
}
</script>

<style lang="scss" module>
.alert {
  position: fixed;
  z-index: 9999;
  min-width: 270px;
  margin: 1rem 0;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  &.top {
    top: 15px;
  }
  &.bottom {
    bottom: 15px;
  }
  &.left {
    left: 15px;
  }
  &.center {
    left: 50%;
  }
  &.right {
    right: 15px;
  }
}
</style>
