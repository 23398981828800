import Vue from 'vue'
import {
  FETCH_MEMBERS_LIST
} from '../../actionTypes'
import {
  SET_MEMBERS,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'
/**
 * Members management actions
 **/
const membersManagementActions = {
  async [FETCH_MEMBERS_LIST] ({ commit, rootGetters }, params) {
    // Fetching Careers data from server api
    // Reset error messages
    commit('remoteMessage', null)
    try {
      const currentAccount = rootGetters['User/user']
      const memberResponses = await Vue.axios.resourceServer.get('members', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (memberResponses.data.data) {
        commit(SET_MEMBERS, memberResponses.data.data)
        commit(SET_PAGINATION_META, memberResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + memberResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  }
}

export default membersManagementActions
