<template>
  <d-container
    fluid
    class="main-content-container"
  >
    <!-- Notification -->
    <base-notify
      :display="showMessage"
      :message="notifiedMessage"
      :dismiss-secs="7"
      :type="messageType"
      position="top-left"
    />

    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          {{ $t("common.subTitle") }}
        </span>
        <h3 class="page-title">
          {{ $t("careers.pageTitle") }}
        </h3>
      </div>
    </div>

    <!-- Page Content -->
    <router-view />
  </d-container>
</template>

<script>
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'

export default {
  name: 'Careers',
  mixins: [
    ProcessErrorMessages('CareersManagement/remoteMessage')
  ],
  page: {
    title: 'Careers management',
    links: [
      {
        rel: 'canonical',
        href: 'https://admin.aromajoin.com/careers'
      }
    ]
  }
}
</script>
