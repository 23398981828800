<template>
  <tr>
    <td class="text-center">
      {{ sdk.id }}
    </td>
    <td>{{ sdk.title }}</td>
    <td>
      <div v-html="sdk.overview" />
    </td>
    <td>{{ sdk.updated_at | formatDate }}</td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          class="btn-white"
          @click="handleOperation('view')"
        >
          <i class="material-icons">
            credit_card
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperation('edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperation('delete')"
        >
          <i class="material-icons">
            delete_sweep
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_SDK_UPDATE,
  DELETE_AROMA_SDK
} from '@/states/actionTypes'

export default {
  name: 'AromaSdkItem',
  props: {
    sdk: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentSDK: `ControllerSdksManagement/${CURRENT_SDK_UPDATE}`,
      deleteSDK: `ControllerSdksManagement/${DELETE_AROMA_SDK}`
    }),
    handleOperation (operation) {
      /** Select current app */
      this.updateCurrentSDK(this.sdk)

      /** Routing operations */
      switch (operation) {
        case 'view':
          this.$router.push({
            name: 'view-aroma-sdk',
            params: { id: this.sdk.id.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'view-aroma-sdk',
            params: { id: this.sdk.id.toString(), action: 'edit' }
          })
          break
        case 'delete':
          let confirmDelete = confirm(`Are you sure to delete: ${this.sdk.title}?`)
          if (confirmDelete) {
            this.deleteSDK(this.sdk)
          }
          break
        default:
          break
      }
    }
  }
}
</script>
