export default function () {
  return {
    methods: {
      readFileAsync (file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()

          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(file)
        })
      }
    }
  }
}
