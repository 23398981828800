<template>
  <div class="card mb-4">
    <div class="card-header border-bottom pl-4">
      <h6 class="m-0 text-muted">
        {{ $t('subscribers.totalLabel') }}: {{ (subscribersList.length) }}{{ $t('subscribers.unit') }}
      </h6>
    </div>
    <div class="card-body p-0 pb-2 text-left">
      <div
        v-if="subscribersList && subscribersList.length > 0"
        class="table-responsive"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="pl-4"
              >
                {{ $t('subscribers.emailCol') }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t('subscribers.sourceCol') }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t('subscribers.activationCol') }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t('subscribers.createdAtCol') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <subscriber-item
              v-for="subscriber in subscribersList"
              :key="subscriber.subscriberId"
              :subscriber="subscriber"
            />
          </tbody>
        </table>
      </div>
      <base-loading
        v-else
      />
    </div>
    <div class="card-footer pl-2">
      <d-button
        pill
        outline
        theme="light"
        @click.prevent="handleExportSubscribersCSV"
      >
        <span class="text-muted export__csv">
          {{ $t('subscribers.exportCSVLabel') }}
        </span>
      </d-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SubscriberItem from './SubscriberItem'
import {
  FETCH_SUBSCRIBERS
} from '@/states/actionTypes'

export default {
  name: 'SubscribersList',
  components: {
    SubscriberItem
  },
  mounted () {
    this.fetchSubscribersList({
      debug: true,
      locale: this.$i18n.locale
    })
  },
  computed: {
    ...mapGetters({
      subscribersList: 'SubscribersManagement/subscribers'
    })
  },
  methods: {
    ...mapActions({
      fetchSubscribersList: `SubscribersManagement/${FETCH_SUBSCRIBERS}`
    }),
    async handleExportSubscribersCSV () {
      try {
        const exportSubscribersCSVResponse = await Vue.axios.serverlessServer.get('subscriptions/export-csv?debug=true')
        if (exportSubscribersCSVResponse.status === 200) {
          let downloadLink = document.createElement('a')
          const csvFile = new Blob([exportSubscribersCSVResponse.data], { type: 'text/csv' })
          downloadLink.download = 'subscriptions.csv'
          downloadLink.href = window.URL.createObjectURL(csvFile)
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
          downloadLink.click()
        } else {
          console.error(exportSubscribersCSVResponse.data)
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.export__csv {
  text-decoration: underline;
  font-size: 15px;
}
</style>
