/** Aroma Firmware management states */

const aromaFirmwareStates = {
  firmwares: [],
  currentFirmware: null,
  paginationMeta: null,
  remoteMessage: null
}

export default aromaFirmwareStates
