<template>
  <d-container
    fluid
    class="main-content-container px-4 h-100"
  >
    <div class="no-gutters">
      <div class="bg-404 mx-auto text-center mt-5 mb-5">
        <img
          src="@/assets/images/404-bg.png"
          class="img-fluid"
          alt="back-img"
        >
      </div>
      <div class="btn-404 mx-auto text-center">
        <h1
          v-if="resource"
          :class="$style.title"
        >
          {{ resource }}
        </h1>
        <BaseLink
          :to="{ name: 'home' }"
          class="d-inline-block"
        >
          <img
            src="@/assets/images/404-btn.png"
            alt="back-btn"
          >
        </BaseLink>
      </div>
    </div>
  </d-container>
</template>

<script>
export default {
  name: 'NotFound',
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }]
  },
  props: {
    resource: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
.title {
  text-align: center;
  padding: 10px 10px;
}
</style>
