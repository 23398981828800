/**
 * Controller SDK management mutations
 **/
import {
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META,
  SET_AROMA_SDKS,
  SET_CURRENT_SDK,
  SET_SDK_TITLE,
  SET_SDK_SLUG,
  SET_SDK_DOWNLOAD_URL,
  SET_SDK_OVERVIEW,
  SET_SDK_INSTALL_GUIDES
} from '../../mutationTypes'

const controllerSdksManagementMutations = {
  [SET_SDK_INSTALL_GUIDES]: (state, guides) => {
    state.currentSDK.install_guides = guides
  },
  [SET_SDK_OVERVIEW]: (state, overview) => {
    state.currentSDK.overview = overview
  },
  [SET_SDK_DOWNLOAD_URL]: (state, downloadURL) => {
    state.currentSDK.link_url = downloadURL
  },
  [SET_SDK_SLUG]: (state, slug) => {
    state.currentSDK.slug = slug
  },
  [SET_SDK_TITLE]: (state, title) => {
    state.currentSDK.title = title
  },
  [SET_AROMA_SDKS]: (state, sdks) => {
    state.controllerSdks = sdks
  },
  [SET_CURRENT_SDK]: (state, currentSDK) => {
    state.currentSDK = currentSDK
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default controllerSdksManagementMutations
