<template>
  <div>
    <!-- New FAQ button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleFAQEvent"
          class="btn-royal-blue"
        >
          Create FAQ
        </d-button>
      </d-col>
    </d-row>

    <div class="row">
      <div class="col">
        <FAQList />
      </div>
    </div>
  </div>
</template>

<script>
import FAQList from '@/components/faq/FAQList'

export default {
  name: 'FAQIndex',
  components: {
    FAQList
  },
  methods: {
    /** Handle going to adding FAQ view */
    handleFAQEvent () {
      this.$router.push({
        name: 'create-faq'
      })
    }
  }
}
</script>
