<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-row>
        <d-col>
          <label for="feAppTitle">Title</label>
          <d-input
            id="feAppTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppSlug">Slug</label>
            <d-input
              id="feAppSlug"
              v-model="slug"
            />
          </d-col>
        </d-form-row>
        <!-- DESCRIPTION -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppDescription">Description</label>
            <VueTrix
              input-id="feAppDescription"
              v-model="description"
            />
          </d-col>
        </d-form-row>
        <!-- FEATURES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppFeatures">Features</label>
            <VueTrix
              input-id="feAppFeatures"
              v-model="features"
            />
          </d-col>
        </d-form-row>
        <!-- OS SUPPORTS -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppSupports">OS Supports</label>
            <VueTrix
              input-id="feAppSupports"
              v-model="supports"
            />
          </d-col>
        </d-form-row>
        <!-- PRICE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppPrice">Price</label>
            <d-input
              id="feAppPrice"
              v-model="price"
            />
          </d-col>
        </d-form-row>
        <!-- ANDROID URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppAndroidUrl">Android URL</label>
            <d-input
              id="feAppAndroidUrl"
              v-model="androidUrl"
            />
          </d-col>
        </d-form-row>
        <!-- iOS URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppiOSUrl">iOS URL</label>
            <d-input
              id="feAppiOSUrl"
              v-model="iOSUrl"
            />
          </d-col>
        </d-form-row>
        <!-- Linux URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppLinuxUrl">Linux URL</label>
            <d-input
              id="feAppLinuxUrl"
              v-model="linuxUrl"
            />
          </d-col>
        </d-form-row>
        <!-- macOS URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppMacOSUrl">macOS URL</label>
            <d-input
              id="feAppMacOSUrl"
              v-model="macOSUrl"
            />
          </d-col>
        </d-form-row>
        <!-- Window URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppWindowUrl">Windows URL</label>
            <d-input
              id="feAppWindowUrl"
              v-model="windowUrl"
            />
          </d-col>
        </d-form-row>
        <!-- Video URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppVideoUrl">Video URL</label>
            <d-input
              id="feAppVideoUrl"
              v-model="videoUrl"
            />
          </d-col>
        </d-form-row>
        <!-- Document URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppDocumentUrl">Document URL</label>
            <d-input
              id="feAppDocumentUrl"
              v-model="documentUrl"
            />
          </d-col>
        </d-form-row>
        <!-- IMAGE UPLOAD -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppImageFiles">
              Upload images
            </label>
            <div class="custom-file mb-3">
              <input
                type="file"
                class="custom-file-input"
                id="feAppImageFiles"
                ref="feAppImageFiles"
                @change="handlePreviewFile"
                multiple
              >
              <label
                class="custom-file-label"
                for="feAppImageFiles"
              >
                Choose file...
              </label>
            </div>
          </d-col>
        </d-form-row>
        <!-- IMAGE VIEW -->
        <d-form-row class="mb-3">
          <d-col
            v-for="imageUrl in previewImageUrls"
            :key="imageUrl.name"
            cols="4"
          >
            <d-img
              :src="imageUrl"
              fluid
              thumbnail
              rounded
              class="mb-2"
            />
          </d-col>
        </d-form-row>
        <!-- Submit -->
        <d-form-row>
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleCreateApp"
            >
              Create
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'

import ReadFileAsync from '@/mixins/ReadFileAsync'
import {
  CREATE_AROMA_APPLICATION,
  CURRENT_APPLICATION_UPDATE,
  APPLICATION_TITLE_UPDATE,
  APPLICATION_SLUG_UPDATE,
  APPLICATION_DESC_UPDATE,
  APPLICATION_PRICE_UPDATE,
  APPLICATION_SUPPORT_UPDATE,
  APPLICATION_FEATURES_UPDATE,
  APPLICATION_ANDROID_UPDATE,
  APPLICATION_IOS_UPDATE,
  APPLICATION_LINUX_UPDATE,
  APPLICATION_MACOS_UPDATE,
  APPLICATION_WINDOW_UPDATE,
  APPLICATION_VIDEO_UPDATE,
  APPLICATION_DOCUMENT_UPDATE,
  APPLICATION_IMAGE_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'AromaApplicationCreate',
  components: {
    VueTrix
  },
  mixins: [
    ReadFileAsync()
  ],
  data () {
    return {
      previewImageUrls: []
    }
  },
  created () {
    this.updateCurrentApp({
      title: 'New App',
      slug: '',
      description: '',
      features: '',
      os_supported: '',
      price: '',
      android_url: '',
      ios_url: '',
      linux_url: '',
      mac_url: '',
      window_url: '',
      video_url: '',
      document_links: '',
      images: []
    })
  },
  computed: {
    ...mapGetters({
      currentApplication: 'ApplicationsManagement/currentApplication'
    }),
    title: {
      get () {
        return this.currentApplication.title || ''
      },
      set (newTitle) {
        this.updateAppTitle(newTitle)
        this.updateAppSlug(newTitle)
      }
    },
    slug: {
      get () {
        return this.currentApplication.slug
      },
      set (newSlug) {
        this.updateAppSlug(newSlug)
      }
    },
    description: {
      get () {
        return this.currentApplication.description
      },
      set (newDesc) {
        this.updateAppDesc(newDesc)
      }
    },
    features: {
      get () {
        return this.currentApplication.features
      },
      set (newFeatures) {
        this.updateAppFeatures(newFeatures)
      }
    },
    supports: {
      get () {
        return this.currentApplication.os_supported
      },
      set (newOSSupport) {
        this.updateAppSupport(newOSSupport)
      }
    },
    price: {
      get () {
        return this.currentApplication.price
      },
      set (newPrice) {
        this.updateAppPrice(newPrice)
      }
    },
    androidUrl: {
      get () {
        return this.currentApplication.android_url
      },
      set (newAndroidUrl) {
        this.updateAppAndroidUrl(newAndroidUrl)
      }
    },
    iOSUrl: {
      get () {
        return this.currentApplication.ios_url
      },
      set (newIOSUrl) {
        this.updateAppIOSUrl(newIOSUrl)
      }
    },
    linuxUrl: {
      get () {
        return this.currentApplication.linux_url
      },
      set (newLinuxUrl) {
        this.updateAppLinuxUrl(newLinuxUrl)
      }
    },
    macOSUrl: {
      get () {
        return this.currentApplication.mac_url
      },
      set (newMacOSUrl) {
        this.updateAppMacOSUrl(newMacOSUrl)
      }
    },
    windowUrl: {
      get () {
        return this.currentApplication.window_url
      },
      set (newWindowUrl) {
        this.updateAppWindowUrl(newWindowUrl)
      }
    },
    videoUrl: {
      get () {
        return this.currentApplication.video_url
      },
      set (newVideoUrl) {
        this.updateAppVideoUrl(newVideoUrl)
      }
    },
    documentUrl: {
      get () {
        return this.currentApplication.document_links
      },
      set (newDocumentUrl) {
        this.updateAppDocumentUrl(newDocumentUrl)
      }
    }
  },
  methods: {
    ...mapActions({
      createAromaApp: `ApplicationsManagement/${CREATE_AROMA_APPLICATION}`,
      updateCurrentApp: `ApplicationsManagement/${CURRENT_APPLICATION_UPDATE}`,
      updateAppTitle: `ApplicationsManagement/${APPLICATION_TITLE_UPDATE}`,
      updateAppSlug: `ApplicationsManagement/${APPLICATION_SLUG_UPDATE}`,
      updateAppDesc: `ApplicationsManagement/${APPLICATION_DESC_UPDATE}`,
      updateAppPrice: `ApplicationsManagement/${APPLICATION_PRICE_UPDATE}`,
      updateAppSupport: `ApplicationsManagement/${APPLICATION_SUPPORT_UPDATE}`,
      updateAppFeatures: `ApplicationsManagement/${APPLICATION_FEATURES_UPDATE}`,
      updateAppAndroidUrl: `ApplicationsManagement/${APPLICATION_ANDROID_UPDATE}`,
      updateAppIOSUrl: `ApplicationsManagement/${APPLICATION_IOS_UPDATE}`,
      updateAppLinuxUrl: `ApplicationsManagement/${APPLICATION_LINUX_UPDATE}`,
      updateAppMacOSUrl: `ApplicationsManagement/${APPLICATION_MACOS_UPDATE}`,
      updateAppWindowUrl: `ApplicationsManagement/${APPLICATION_WINDOW_UPDATE}`,
      updateAppVideoUrl: `ApplicationsManagement/${APPLICATION_VIDEO_UPDATE}`,
      updateAppDocumentUrl: `ApplicationsManagement/${APPLICATION_DOCUMENT_UPDATE}`,
      updateAppImage: `ApplicationsManagement/${APPLICATION_IMAGE_UPDATE}`
    }),
    handleCreateApp () {
      this.createAromaApp({ app: this.currentApplication, locale: this.$i18n.locale })
    },
    async handlePreviewFile () {
      if (this.$refs.feAppImageFiles.files.length > 0) {
        for (var index = 0; index < this.$refs.feAppImageFiles.files.length; index++) {
          const previewUrl = URL.createObjectURL(this.$refs.feAppImageFiles.files[index])
          this.previewImageUrls.push(previewUrl)
          const imageData = await this.readFileAsync(this.$refs.feAppImageFiles.files[index])
          this.updateAppImage({ file: imageData })
        }
      }
    }
  }
}
</script>
