<template>
  <!-- FOOTER -->
  <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
    <div :class="[contained ? 'container' : 'container-fluid']">
      <div class="row">
        <ul class="nav">
          <li
            v-for="(item, idx) in menuItems"
            :key="idx"
            class="nav-item"
          >
            <base-link
              class="nav-link"
              :href="item.to"
              target="_blank"
            >
              {{ item.title }}
            </base-link>
          </li>
        </ul>
        <span class="copyright ml-auto my-auto mr-2">
          {{ footerCopyright }}
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
const defaultMenuItems = [{
  title: 'Home',
  to: 'https://aromajoin.com/'
}, {
  title: 'Products',
  to: 'https://aromajoin.com/ja/hardware/shooters/aroma-shooter-1'
}, {
  title: 'Apps',
  to: 'https://aromajoin.com/ja/software/apps/aroma-controller'
}, {
  title: 'Company',
  to: 'https://aromajoin.com/ja/about-aromajoin'
}, {
  title: 'Blog',
  to: 'https://aromajoin.com/ja/blog'
}]

export default {
  name: 'TheFooter',
  props: {
    /**
     * The footer menu items.
     */
    menuItems: {
      type: Array,
      default () {
        return defaultMenuItems
      }
    },
    /**
     * The copyright information.
     */
    copyright: {
      type: String,
      default: ''
    },
    /**
     * Whether the footer should be wrapped in a container, or not.
     */
    contained: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    footerCopyright () {
      return this.copyright ? this.copyright : `© ${new Date().getFullYear()} Aromajoin Corporation'`
    }
  }
}
</script>
