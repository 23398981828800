<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0 text-muted">
        FAQ List
      </h6>
    </div>
    <div class="card-body p-0 pb-2 text-left">
      <div class="table-responsive">
        <table class="table table-hover m-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="border-0 text-center"
              >
                ID
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Question
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Category
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <FAQItem
              v-for="faq in faqs"
              :key="faq.slug"
              :faq="faq"
            />
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer p-0">
      <!-- Pagination -->
      <base-pagination
        :pagination-meta="paginationMeta"
        @go-to-page="handleLoadPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  FETCH_FAQS,
  DELETE_FAQ,
  CURRENT_FAQ_UPDATE
} from '@/states/actionTypes'
import FAQItem from './FAQItem'

export default {
  name: 'FAQList',
  components: {
    FAQItem
  },
  mounted () {
    this.fetchFAQs({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      faqs: 'FAQsManagement/faqs',
      paginationMeta: 'FAQsManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchFAQs: `FAQsManagement/${FETCH_FAQS}`,
      deleteFAQ: `FAQsManagement/${DELETE_FAQ}`,
      updateCurrentFAQ: `FAQsManagement/${CURRENT_FAQ_UPDATE}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchFAQs({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchFAQs({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchFAQs({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    }
  }
}
</script>
