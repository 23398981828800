<template>
  <d-row>
    <d-col>
      <d-form @submit.prevent="handleGeneratingToken">
        <d-form-row>
          <!-- Current JWT token -->
          <d-col
            class="form-group"
          >
            <label
              class="text-muted d-block mb-2"
              for="feCurrentToken"
            >
              Current Authentication Token (JWT)
            </label>
            <d-form-input
              type="text"
              id="feCurrentToken"
              placeholder="Your current email"
              value="eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJleHAiOjE1NTAzNjk5MjB9.0xEZu_mG0_KrXZjRl_Iq17ON8UP9qFGhpTc5nLq7H3k"
              disabled
            />
          </d-col>
        </d-form-row>
        <d-button
          type="submit"
          class="btn-accent"
        >
          Generate new token
        </d-button>
      </d-form>
    </d-col>
  </d-row>
</template>

<script>
export default {
  name: 'AccountTokenForm',
  methods: {
    handleGeneratingToken () {
      alert('Generating new account token!')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
