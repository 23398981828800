/**
 * Member management getters
 */
const membersManagementGetters = {
  members: (state) => state.members,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default membersManagementGetters
