import Vue from 'vue'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page'
})

const metaInfo = {
  // if no sub-components specify a metaInfo.title, this title will be used
  title: 'Aromajoin',
  // all titles will be injected into this template
  titleTemplate: '%s | Aromajoin',
  meta: [
    {
      name: 'description',
      content: ''
    },
    {
      name: 'keywords',
      content: ''
    },
    // OpenGraph data
    { property: 'og:title', content: 'Home | Aromajoin' },
    { property: 'og:site_name', content: 'Aromajoin Homepage' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://aromajoin.com' },
    { property: 'og:image', content: '' },
    { property: 'og:description', content: '' },
    // Twitter card
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: 'https://aromajoin.com' },
    { name: 'twitter:title', content: 'Home | Aromajoin' },
    { name: 'twitter:description', content: '' },
    // Google / Schema.org markup:
    { itemprop: 'name', content: 'Home | Aromajoin' },
    { itemprop: 'description', content: '' },
    { itemprop: 'image', content: '' }
  ]
}

export default metaInfo
