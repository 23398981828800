<template>
  <div class="row">
    <div class="col">
      <div class="card mb-4">
        <div class="card-header border-bottom">
          <h6 class="m-0 text-muted">
            Careers List
          </h6>
        </div>
        <div class="card-body p-0 pb-2 text-left">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="border-0 text-center"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    {{ $t("careers.title") }}
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    {{ $t("careers.description") }}
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    {{ $t("careers.deadline") }}
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    {{ $t("common.managementActions") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <CareerItem
                  v-for="careerItem in careers"
                  :key="careerItem.id"
                  :career-item="careerItem"
                />
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer p-0">
          <!-- Pagination -->
          <base-pagination
            :pagination-meta="paginationMeta"
            @go-to-page="handleLoadPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CareerItem from '@/components/careers/CareerItem'
import { mapGetters, mapActions } from 'vuex'

import {
  FETCH_CAREERS
} from '@/states/actionTypes'

export default {
  name: 'CareerList',
  mounted () {
    this.fetchCareers({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  components: {
    CareerItem
  },
  computed: {
    ...mapGetters({
      careers: 'CareersManagement/careers',
      paginationMeta: 'CareersManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchCareers: `CareersManagement/${FETCH_CAREERS}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchCareers({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchCareers({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchCareers({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    }
  }
}
</script>
