<template>
  <div>
    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <d-button-toolbar class="mb-4">
              <d-button
                @click="toggleEditMode"
              >
                {{ this.editing ? 'View' : 'Edit' }}
              </d-button>
            </d-button-toolbar>
            <h6 class="text-muted">
              {{ currentCartridge.serial }} details
            </h6>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form
              @submit.prevent="handleUpdateCartridge"
            >
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feSerial">Serial*</label>
                  <d-input
                    v-model="serial"
                    id="feSerial"
                    :disabled="!this.editing"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feAccess">Access state</label>
                  <d-select
                    id="feAccess"
                    class="mb-3"
                    v-model="access"
                    :disabled="!this.editing"
                  >
                    <option value="published">
                      Public
                    </option>
                    <option value="customed">
                      Private
                    </option>
                  </d-select>
                </d-col>
                <d-col md="6">
                  <label for="feStockLocation">Stock Location</label>
                  <d-form-select
                    id="feStockLocation"
                    class="autocomplete"
                    v-model="stockLocation"
                    :disabled="!this.editing"
                    :options="locationOptions"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feStockStatus">Stock State</label>
                  <d-select
                    id="feStockStatus"
                    class="mb-3"
                    v-model="active"
                    :disabled="!this.editing"
                  >
                    <option value="liquid">
                      Liquid oil
                    </option>
                    <option value="solid">
                      Solid tablet
                    </option>
                    <option value="sold_out">
                      Sold out
                    </option>
                  </d-select>
                </d-col>
                <d-col md="6">
                  <label for="feQuantity">Stock quantity</label>
                  <d-input
                    v-model="quantity"
                    type="number"
                    id="feQuantity"
                    class="mb-3"
                    :disabled="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feClassification">Classification</label>
                  <d-input
                    v-model="classification"
                    id="feClassification"
                    class="mb-3"
                    :disabled="!this.editing"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feKind">Kind*</label>
                  <d-input
                    v-model="kind"
                    id="feKind"
                    class="mb-3"
                    :disabled="!this.editing"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feEName">EName*</label>
                  <d-input
                    v-model="ename"
                    id="feEName"
                    class="mb-3"
                    :disabled="!this.editing"
                    :required="true"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feJName">JName</label>
                  <d-input
                    v-model="jname"
                    id="feJName"
                    class="mb-3"
                    :disabled="!this.editing"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="fePrice">Price*</label>
                  <d-input-group
                    prepend="¥"
                    class="mb-3"
                  >
                    <d-input
                      v-model="price"
                      id="fePrice"
                      :disabled="!this.editing"
                      :required="true"
                    />
                  </d-input-group>
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="feDescription">
                    Description
                  </label>
                  <b-form-textarea
                    id="fe-description"
                    v-model="description"
                    :disabled="!this.editing"
                    :rows="3"
                    :max-rows="6"
                    name="feDescription"
                    class="mb-3"
                    placeholder="Enter cartridge description"
                  />
                </d-col>
              </d-form-row>
              <!-- IMAGE UPLOAD -->
              <d-form-row
                v-if="this.editing"
              >
                <d-col>
                  <label for="feCartridgeImageFile">
                    Cartridge image
                  </label>
                  <div class="custom-file mb-3">
                    <input
                      id="feCartridgeImageFile"
                      ref="feCartridgeImageFile"
                      type="file"
                      class="custom-file-input"
                      @change="handlePreviewFile"
                    >
                    <label
                      class="custom-file-label"
                      for="feCartridgeImageFile"
                    >
                      Upload file...
                    </label>
                  </div>
                </d-col>
              </d-form-row>
              <!-- IMAGE VIEW -->
              <d-form-row
                v-if="this.editing && (previewImageUrl || currentCartridge.images.length > 0)"
              >
                <d-col>
                  <d-img
                    :src="previewImageUrl ? previewImageUrl : currentCartridge.images[currentCartridge.images.length - 1].file.url"
                    fluid
                    rounded
                    center
                    class="mb-3"
                    alt="Cartridge Image Preview"
                  />
                </d-col>
              </d-form-row>
              <d-button
                v-if="this.editing"
                type="submit"
                class="btn-accent mr-3"
              >
                Update
              </d-button>
              <d-button
                v-if="this.editing"
                theme="danger"
                class="mr-3"
                @click.prevent="handleDeleteCartridge"
              >
                Delete
              </d-button>
            </d-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReadFileAsync from '@/mixins/ReadFileAsync'
import {
  CURRENT_CARTRIDGE_UPDATE,
  CARTRIDGE_SERIAL_UPDATE,
  CARTRIDGE_ACCESS_UPDATE,
  CARTRIDGE_LOCATION_UPDATE,
  CARTRIDGE_STOCK_UPDATE,
  CARTRIDGE_QUANTITY_UPDATE,
  CARTRIDGE_CLASSIFICATION_UPDATE,
  CARTRIDGE_KIND_UPDATE,
  CARTRIDGE_ENAME_UPDATE,
  CARTRIDGE_JNAME_UPDATE,
  CARTRIDGE_PRICE_UPDATE,
  CARTRIDGE_DESCRIPTION_UPDATE,
  CARTRIDGE_IMAGE_UPDATE,
  UPDATE_CARTRIDGE,
  DELETE_CARTRIDGE
} from '@/states/actionTypes'

export default {
  name: 'CartridgeView',
  mixins: [
    ReadFileAsync()
  ],
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      previewImageUrl: null,
      editing: false
    }
  },
  mounted () {
    this.editing = this.$route.params.action === 'edit'
    const cartridgeId = parseInt(this.id)
    if (cartridgeId) {
      const cartridgeToEdit = this.getCartridgeById(cartridgeId)
      this.updateCurrentCartridge(cartridgeToEdit)
      if (this.currentCartridge.stock_location) {
        this.updateCartridgeLocation(this.currentCartridge.stock_location.id)
      }
    }
  },
  computed: {
    ...mapGetters({
      getCartridgeById: 'CartridgesManagement/getCartridgeById',
      currentCartridge: 'CartridgesManagement/currentCartridge',
      cartridgeLocations: 'CartridgesManagement/cartridgeLocations'
    }),
    locationOptions () {
      let locations = []
      this.cartridgeLocations.forEach((location) => {
        if (location) {
          locations.push({ text: location.location, value: location.id })
        }
      })
      return locations
    },
    serial: {
      get () {
        return this.currentCartridge.serial
      },
      set (newSerial) {
        this.updateCartridgeSerial(newSerial)
      }
    },
    access: {
      get () {
        return this.currentCartridge.access
      },
      set (newAccess) {
        this.updateCartridgeAccess(newAccess)
      }
    },
    stockLocation: {
      get () {
        return this.currentCartridge.stock_location_id
      },
      set (newLocationId) {
        this.updateCartridgeLocation(newLocationId)
      }
    },
    active: {
      get () {
        return this.currentCartridge.active
      },
      set (newStock) {
        this.updateCartridgeStock(newStock)
      }
    },
    quantity: {
      get () {
        return this.currentCartridge.quantity
      },
      set (newQuantity) {
        this.updateCartridgeQuantity(newQuantity)
      }
    },
    classification: {
      get () {
        return this.currentCartridge.classification
      },
      set (newClassification) {
        this.updateCartridgeClassification(newClassification)
      }
    },
    kind: {
      get () {
        return this.currentCartridge.kind
      },
      set (newKind) {
        this.updateCartridgeKind(newKind)
      }
    },
    ename: {
      get () {
        return this.currentCartridge.ename
      },
      set (newEname) {
        this.updateCartridgeEname(newEname)
      }
    },
    jname: {
      get () {
        return this.currentCartridge.jname
      },
      set (newJname) {
        this.updateCartridgeJname(newJname)
      }
    },
    price: {
      get () {
        return this.currentCartridge.price
      },
      set (newPrice) {
        this.updateCartridgePrice(newPrice)
      }
    },
    description: {
      get () {
        return this.currentCartridge.description
      },
      set (newDescription) {
        this.updateCartridgeDescription(newDescription)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentCartridge: `CartridgesManagement/${CURRENT_CARTRIDGE_UPDATE}`,
      updateCartridgeImage: `CartridgesManagement/${CARTRIDGE_IMAGE_UPDATE}`,
      updateCartridge: `CartridgesManagement/${UPDATE_CARTRIDGE}`,
      deleteCartridge: `CartridgesManagement/${DELETE_CARTRIDGE}`,
      updateCartridgeSerial: `CartridgesManagement/${CARTRIDGE_SERIAL_UPDATE}`,
      updateCartridgeAccess: `CartridgesManagement/${CARTRIDGE_ACCESS_UPDATE}`,
      updateCartridgeLocation: `CartridgesManagement/${CARTRIDGE_LOCATION_UPDATE}`,
      updateCartridgeStock: `CartridgesManagement/${CARTRIDGE_STOCK_UPDATE}`,
      updateCartridgeQuantity: `CartridgesManagement/${CARTRIDGE_QUANTITY_UPDATE}`,
      updateCartridgeClassification: `CartridgesManagement/${CARTRIDGE_CLASSIFICATION_UPDATE}`,
      updateCartridgeKind: `CartridgesManagement/${CARTRIDGE_KIND_UPDATE}`,
      updateCartridgeEname: `CartridgesManagement/${CARTRIDGE_ENAME_UPDATE}`,
      updateCartridgeJname: `CartridgesManagement/${CARTRIDGE_JNAME_UPDATE}`,
      updateCartridgePrice: `CartridgesManagement/${CARTRIDGE_PRICE_UPDATE}`,
      updateCartridgeDescription: `CartridgesManagement/${CARTRIDGE_DESCRIPTION_UPDATE}`
    }),
    toggleEditMode () {
      this.editing = !this.editing
    },
    handleUpdateCartridge () {
      this.updateCartridge(this.currentCartridge)
      this.toggleEditMode()
    },
    handleDeleteCartridge () {
      if (confirm(`Are you sure you want to delete [${this.currentCartridge.serial}]?`)) {
        this.deleteCartridge(this.currentCartridge)
      }
    },
    /** Handle upload image preview */
    async handlePreviewFile () {
      this.previewImageUrl = URL.createObjectURL(this.$refs.feCartridgeImageFile.files[0])
      const imageData = await this.readFileAsync(this.$refs.feCartridgeImageFile.files[0])
      this.updateCartridgeImage({ file: imageData })
    }
  }
}
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    background: white;
    width: 150px;
    overflow: auto;
    position: absolute;
    z-index: 1;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 12px;
    cursor: pointer;
  }

  .autocomplete-result:hover {
    background-color: #17c671;
    color: white;
  }
</style>
