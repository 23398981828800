/**
 * Applications management actions
 **/
import Vue from 'vue'
import router from '@/router'
import { stringToSlug } from '@/utils/stringToSlug'
import {
  FETCH_AROMA_APPLICATIONS,
  CURRENT_APPLICATION_UPDATE,
  DELETE_AROMA_APPLICATION,
  CREATE_AROMA_APPLICATION,
  UPDATE_AROMA_APPLICATION,
  APPLICATION_ANDROID_UPDATE,
  APPLICATION_DESC_UPDATE,
  APPLICATION_DOCUMENT_UPDATE,
  APPLICATION_FEATURES_UPDATE,
  APPLICATION_IOS_UPDATE,
  APPLICATION_LINUX_UPDATE,
  APPLICATION_MACOS_UPDATE,
  APPLICATION_PRICE_UPDATE,
  APPLICATION_SLUG_UPDATE,
  APPLICATION_SUPPORT_UPDATE,
  APPLICATION_TITLE_UPDATE,
  APPLICATION_VIDEO_UPDATE,
  APPLICATION_WINDOW_UPDATE,
  APPLICATION_IMAGES_UPDATE,
  APPLICATION_IMAGE_UPDATE,
  APPLICATION_IMAGE_REMOVE
} from '../../actionTypes'
import {
  SET_AROMA_APPLICATIONS,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META,
  SET_CURRENT_APPLICATION,
  SET_APPLICATION_ANDROID,
  SET_APPLICATION_DESC,
  SET_APPLICATION_DOCUMENT,
  SET_APPLICATION_FEATURES,
  SET_APPLICATION_IOS,
  SET_APPLICATION_LINUX,
  SET_APPLICATION_MACOS,
  SET_APPLICATION_PRICE,
  SET_APPLICATION_SLUG,
  SET_APPLICATION_SUPPORT,
  SET_APPLICATION_TITLE,
  SET_APPLICATION_VIDEO,
  SET_APPLICATION_WINDOW,
  SET_APPLICATION_IMAGES,
  SET_APPLICATION_IMAGE,
  REMOVE_APPLICATION_IMAGE
} from '../../mutationTypes'

const applicationsManagementActions = {
  /** Fetching Aroma Cartridges data from server api */
  async [FETCH_AROMA_APPLICATIONS] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const appResponses = await Vue.axios.resourceServer.get('aroma_applications', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (appResponses.data.data) {
        commit(SET_AROMA_APPLICATIONS, appResponses.data.data)
        commit(SET_PAGINATION_META, appResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Create Aroma Application data by invoking API */
  async [CREATE_AROMA_APPLICATION] ({ commit, getters, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const bodyData = params.app || getters['currentApplication']

      const appResponses = await Vue.axios.resourceServer
        .post(`aroma_applications?locale=${params.locale}`, { bodyData }, authHeader)

      if (appResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created app successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'apps-management' })
        }, 3500)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Update Aroma Application data by invoking API */
  async [UPDATE_AROMA_APPLICATION] ({ commit, getters, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const bodyData = params.app || getters['currentApplication']

      const appResponses = await Vue.axios.resourceServer
        .put(`aroma_applications/${bodyData.id}?locale=${params.locale}`, { bodyData }, authHeader)

      if (appResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'apps-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DELETE_AROMA_APPLICATION] ({ commit, getters, dispatch, rootGetters }, app) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const deletedId = app.id || getters['currentApplication'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const appResponses = await Vue.axios.resourceServer
        .delete(`aroma_applications/${deletedId}`, authHeader)

      if (appResponses.data.data) {
        await dispatch(FETCH_AROMA_APPLICATIONS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'apps-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [CREATE_AROMA_APPLICATION] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const app = params.app
      const appResponses = await Vue.axios.resourceServer
        .post(`aroma_applications?locale=${params.locale}`, { app }, authHeader)

      if (appResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created news successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'apps-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_AROMA_APPLICATION] ({ commit, getters, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const updatedId = params.app.id || getters['currentApplication'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const app = params.app
      const appResponses = await Vue.axios.resourceServer
        .put(`aroma_applications/${updatedId}?locale=${params.locale}`, { app }, authHeader)

      if (appResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated app successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'apps-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_APPLICATION_UPDATE] ({ commit }, app) {
    commit(SET_CURRENT_APPLICATION, app)
  },
  [APPLICATION_ANDROID_UPDATE] ({ commit }, androidUrl) {
    commit(SET_APPLICATION_ANDROID, androidUrl)
  },
  [APPLICATION_DESC_UPDATE] ({ commit }, description) {
    commit(SET_APPLICATION_DESC, description)
  },
  [APPLICATION_DOCUMENT_UPDATE] ({ commit }, documentUrl) {
    commit(SET_APPLICATION_DOCUMENT, documentUrl)
  },
  [APPLICATION_FEATURES_UPDATE] ({ commit }, features) {
    commit(SET_APPLICATION_FEATURES, features)
  },
  [APPLICATION_IOS_UPDATE] ({ commit }, iosUrl) {
    commit(SET_APPLICATION_IOS, iosUrl)
  },
  [APPLICATION_LINUX_UPDATE] ({ commit }, linuxUrl) {
    commit(SET_APPLICATION_LINUX, linuxUrl)
  },
  [APPLICATION_MACOS_UPDATE] ({ commit }, macUrl) {
    commit(SET_APPLICATION_MACOS, macUrl)
  },
  [APPLICATION_PRICE_UPDATE] ({ commit }, price) {
    commit(SET_APPLICATION_PRICE, price)
  },
  [APPLICATION_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_APPLICATION_SLUG, stringToSlug(slug))
  },
  [APPLICATION_SUPPORT_UPDATE] ({ commit }, supports) {
    commit(SET_APPLICATION_SUPPORT, supports)
  },
  [APPLICATION_TITLE_UPDATE] ({ commit }, title) {
    commit(SET_APPLICATION_TITLE, title)
  },
  [APPLICATION_VIDEO_UPDATE] ({ commit }, video) {
    commit(SET_APPLICATION_VIDEO, video)
  },
  [APPLICATION_WINDOW_UPDATE] ({ commit }, windowUrl) {
    commit(SET_APPLICATION_WINDOW, windowUrl)
  },
  [APPLICATION_IMAGES_UPDATE] ({ commit }, images) {
    commit(SET_APPLICATION_IMAGES, images)
  },
  [APPLICATION_IMAGE_UPDATE] ({ commit }, image) {
    commit(SET_APPLICATION_IMAGE, image)
  },
  [APPLICATION_IMAGE_REMOVE] ({ commit }, imageId) {
    commit(REMOVE_APPLICATION_IMAGE, imageId)
  }
}

export default applicationsManagementActions
