/**
 * Career management getters
 **/
const careersManagementGetters = {
  careers: (state) => state.careers,
  currentCareer: state => state.currentCareer,
  getCareerById: state => id => {
    return state.careers.find(career => career.id === id)
  },
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default careersManagementGetters
