/**
 * Applications management mutations
 **/
import {
  SET_AROMA_APPLICATIONS,
  SET_CURRENT_APPLICATION,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META,
  SET_APPLICATION_ANDROID,
  SET_APPLICATION_DESC,
  SET_APPLICATION_DOCUMENT,
  SET_APPLICATION_FEATURES,
  SET_APPLICATION_IOS,
  SET_APPLICATION_LINUX,
  SET_APPLICATION_MACOS,
  SET_APPLICATION_PRICE,
  SET_APPLICATION_SLUG,
  SET_APPLICATION_SUPPORT,
  SET_APPLICATION_TITLE,
  SET_APPLICATION_VIDEO,
  SET_APPLICATION_WINDOW,
  SET_APPLICATION_IMAGES,
  SET_APPLICATION_IMAGE,
  REMOVE_APPLICATION_IMAGE
} from '../../mutationTypes'

const applicationsManagementMutations = {
  [SET_AROMA_APPLICATIONS]: (state, apps) => {
    state.applications = apps
  },
  [SET_CURRENT_APPLICATION]: (state, app) => {
    state.currentApplication = app
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  },
  [SET_APPLICATION_ANDROID]: (state, androidUrl) => {
    state.currentApplication.android_url = androidUrl
  },
  [SET_APPLICATION_DESC]: (state, desc) => {
    state.currentApplication.description = desc
  },
  [SET_APPLICATION_DOCUMENT]: (state, documentLinks) => {
    state.currentApplication.document_links = documentLinks
  },
  [SET_APPLICATION_FEATURES]: (state, features) => {
    state.currentApplication.features = features
  },
  [SET_APPLICATION_IOS]: (state, iOSUrl) => {
    state.currentApplication.ios_url = iOSUrl
  },
  [SET_APPLICATION_LINUX]: (state, linuxUrl) => {
    state.currentApplication.linux_url = linuxUrl
  },
  [SET_APPLICATION_MACOS]: (state, macUrl) => {
    state.currentApplication.mac_url = macUrl
  },
  [SET_APPLICATION_PRICE]: (state, price) => {
    state.currentApplication.price = price
  },
  [SET_APPLICATION_SLUG]: (state, slug) => {
    state.currentApplication.slug = slug
  },
  [SET_APPLICATION_SUPPORT]: (state, osSupport) => {
    state.currentApplication.os_supported = osSupport
  },
  [SET_APPLICATION_TITLE]: (state, title) => {
    state.currentApplication.title = title
  },
  [SET_APPLICATION_VIDEO]: (state, videoUrl) => {
    state.currentApplication.video_url = videoUrl
  },
  [SET_APPLICATION_WINDOW]: (state, windowUrl) => {
    state.currentApplication.window_url = windowUrl
  },
  [SET_APPLICATION_IMAGES]: (state, images) => {
    state.currentApplication.images = images
  },
  [SET_APPLICATION_IMAGE]: (state, image) => {
    state.currentApplication.images.push(image)
  },
  [REMOVE_APPLICATION_IMAGE]: (state, imageId) => {
    state.currentApplication.images = state.currentApplication.images.map((image) => {
      if (image.id === imageId) {
        image.deleted = true
      }
      return image
    })
  }
}

export default applicationsManagementMutations
