<template>
  <div class="mx-lg-4">
    <!-- New App button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewAromaShooter"
          class="btn-royal-blue"
        >
          Create Aroma Shooter
        </d-button>
      </d-col>
    </d-row>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0 text-muted">
              List of Aroma Shooters
            </h6>
          </div>
          <div class="card-body p-0 pb-2 text-left">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      {{ $t("shooters.aromaShooterLine") }}
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      {{ $t("shooters.aromaShooterName") }}
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      {{ $t("shooters.aromaShooterFeatures") }}
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      {{ $t("common.managementActions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <shooter-item
                    v-for="aromaShooter in aromaShooters"
                    :key="aromaShooter.id"
                    :aroma-shooter="aromaShooter"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer p-0">
            <!-- Pagination -->
            <base-pagination
              :pagination-meta="paginationMeta"
              @go-to-page="handleLoadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ShooterItem from './ShooterItem'
import {
  FETCH_AROMA_SHOOTER_LIST
} from '@/states/actionTypes'

export default {
  name: 'ShootersIndex',
  components: {
    ShooterItem
  },
  mounted () {
    this.fetchAromaShooters({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      aromaShooters: 'AromaShootersManagement/aromaShooters',
      paginationMeta: 'AromaShootersManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchAromaShooters: `AromaShootersManagement/${FETCH_AROMA_SHOOTER_LIST}`
    }),
    goToNewShooterPage () {
      this.$router.push({
        name: 'new-shooter',
        params: {
          action: 'new'
        }
      })
    },
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchAromaShooters({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchAromaShooters({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchAromaShooters({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    },
    handleNewAromaShooter () {
      this.$router.push({
        name: 'create-aroma-shooter'
      })
    }
  }
}
</script>
