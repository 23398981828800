<template>
  <component :is="layout">
    <template slot="body">
      <RouterView />
    </template>
  </component>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      registration: null,
      updateExists: false
    }
  },
  computed: {
    layout () {
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  },
  created () {
    /* Listen to service-worker updated fired */
    document.addEventListener(
      'swUpdated', this.showRefreshUI, { once: true }
    )

    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        window.location.reload()
      }
    )
  },
  methods: {
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true

      if (this.updateExists) {
        this.$toasted.show(this.$i18n.t('shared.service__worker__title'), {
          action: {
            text: this.$i18n.t('shared.service__worker__confirm'),
            onClick: (e, toastObject) => {
              this.updateExists = false
              toastObject.goAway(0)
              this.$toasted.clear()
              if (this.registration && this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
// Design variables and global utilities from src/design.
@import '@/design/aromajoin-dashboard.scss';

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Style Bootstrap.
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
