import aromaShootersManagementStates from './AromaShootersManagementStates'
import aromaShootersManagementGetters from './AromaShootersManagementGetters'
import aromaShootersManagementMutations from './AromaShootersManagementMutations'
import aromaShootersManagementActions from './AromaShootersManagementActions'

const aromaShootersManagement = {
  namespaced: true,
  state: aromaShootersManagementStates,
  getters: aromaShootersManagementGetters,
  mutations: aromaShootersManagementMutations,
  actions: aromaShootersManagementActions
}

export default aromaShootersManagement
