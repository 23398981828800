<template>
  <tr @click="handleOperation('view')">
    <td class="text-center">
      {{ audit.user_id }}
    </td>
    <td>
      {{ audit.remote_address }}
    </td>
    <td>
      {{ audit.auditable_type }}
    </td>
    <td>
      {{ audit.action }}
    </td>
    <td>
      {{ audit.created_at | formatDate }}
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_AUDIT_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'AuditItem',
  props: {
    audit: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentAudit: `AuditsManagement/${CURRENT_AUDIT_UPDATE}`
    }),
    handleOperation (operation) {
      /** Select current app */
      this.updateCurrentAudit(this.audit)

      switch (operation) {
        case 'view':
          this.$router.push({
            name: 'view-audit',
            params: { id: this.audit.id.toString(), action: 'view' }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
