/**
 * Admin user states
 */
const userStates = {
  user: null,
  profile: null,
  remoteMessage: null
}

export default userStates
