/**
 * Contact management actions
 **/
import Vue from 'vue'
import router from '@/router'

import {
  SET_CONTACT_LIST,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_CURRENT_CONTACT
} from '../../mutationTypes'
import {
  FETCH_CONTACTS,
  DELETE_CONTACT,
  CURRENT_CONTACT_UPDATE,
  RESEND_CONTACT
} from '../../actionTypes'

const contactsManagementActions = {
  /** Fetching Contact data from server API */
  async [FETCH_CONTACTS] ({ commit, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const contactResponses = await Vue.axios.resourceServer.get('contacts', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (contactResponses.data.data) {
        commit(SET_CONTACT_LIST, contactResponses.data.data)
        commit(SET_PAGINATION_META, contactResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + contactResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DELETE_CONTACT] ({ commit, dispatch, getters, rootGetters }, contact) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const deleteId = contact.id || getters['currentContact'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const contactResponse = await Vue.axios.resourceServer
        .delete(`contacts/${deleteId}`, authHeader)

      if (contactResponse.data.data) {
        await dispatch(FETCH_CONTACTS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })

        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })

        commit(SET_CURRENT_CONTACT, null)

        if (router.currentRoute.name !== 'contacts-management') {
          setTimeout(() => {
            router.push({ name: 'contacts-management' })
          }, 3000)
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Resend contact email notification */
  async [RESEND_CONTACT] ({ commit, getters, rootGetters }, contact) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const resendId = contact.id || getters['currentContact'].id
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const contactResponses = await Vue.axios.resourceServer
        .post(`contacts/${resendId}/resend`, {}, authHeader)

      if (contactResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Resend notification successfully!',
          type: 'success'
        })

        setTimeout(() => {
          router.push({ name: 'contacts-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + contactResponses.data.error,
          type: 'danger'
        })
      }

      commit(SET_REMOTE_MESSAGE, {
        message: 'Resend contact notification successfully.',
        type: 'success'
      })
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_CONTACT_UPDATE] ({ commit }, contact) {
    commit(SET_CURRENT_CONTACT, contact)
  }
}

export default contactsManagementActions
