<template>
  <!-- Pagination -->
  <div
    :class="$style.aj_pagination"
    class="row col-md-12 pl-2 pr-3"
  >
    <nav class="d-flex text-center">
      <ul class="pagination order-1">
        <li
          class="page-item"
          :class="paginationMeta.prev ? '' : 'disabled'"
        >
          <a
            class="page-link"
            aria-label="First"
            @click="handleRequestPage('first')"
          >
            <span aria-hidden="true">
              &laquo;
            </span>
          </a>
        </li>
        <li
          class="page-item"
          :class="paginationMeta.prev ? '' : 'disabled'"
        >
          <a
            class="page-link"
            aria-label="Prev"
            @click="handleRequestPage(paginationMeta.prev)"
          >
            <span aria-hidden="true">
              <i class="material-icons">
                keyboard_arrow_left
              </i>
            </span>
          </a>
        </li>
        <li
          class="page-item"
          v-if="paginationMeta.prev"
        >
          <a
            class="page-link"
            @click="handleRequestPage(paginationMeta.prev)"
          >
            {{ paginationMeta.prev }}
          </a>
        </li>
        <li class="page-item active">
          <a
            class="page-link"
            @click="handleRequestPage(paginationMeta.current)"
          >
            {{ paginationMeta.current }}
          </a>
        </li>
        <li
          class="page-item"
          v-if="paginationMeta.next"
        >
          <a
            class="page-link"
            @click="handleRequestPage(paginationMeta.next)"
          >
            {{ paginationMeta.next }}
          </a>
        </li>
        <li
          class="page-item"
          :class="paginationMeta.next ? '' : 'disabled'"
        >
          <a
            class="page-link"
            aria-label="Next"
            @click="handleRequestPage(paginationMeta.next)"
          >
            <span aria-hidden="true">
              <i class="material-icons">
                keyboard_arrow_right
              </i>
            </span>
          </a>
        </li>
        <li
          class="page-item"
          :class="paginationMeta.next ? '' : 'disabled'"
        >
          <a
            class="page-link"
            aria-label="Last"
            @click="handleRequestPage('last')"
          >
            <span aria-hidden="true">
              &raquo;
            </span>
          </a>
        </li>
      </ul>
      <p
        class="text-center order-0"
        :class="$style.pagination__count"
      >
        Showing {{ paginationMeta.current }}
        of {{ paginationMeta.pages }} pages
      </p>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    paginationMeta: {
      type: Object,
      required: false,
      default () {
        return {
          current: 0,
          limit: 10,
          pages: 0,
          next: 0,
          prev: 0
        }
      }
    }
  },
  methods: {
    handleRequestPage (page) {
      switch (page) {
        case 'first':
          this.$emit('go-to-page', 'first')
          break
        case 'last':
          this.$emit('go-to-page', 'last')
          break
        case 'next':
          this.$emit('go-to-page', 'next')
          break
        case 'prev':
          this.$emit('go-to-page', 'prev')
          break
        default:
          this.$emit('go-to-page', page)
          break
      }
    }
  }
}
</script>

<style lang="scss" module>
.aj_pagination {
  margin: 0;
  padding: 13px 0;
  background: #fbfbfb;
  border-radius: 0 0 .375rem .375rem;
  ul {
    margin: 0;
    li {
      cursor: pointer;
    }
  }
  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .pagination__count {
    width: auto!important;
    max-width: -webkit-max-content!important;
    max-width: -moz-max-content!important;
    max-width: max-content!important;
    margin: 0!important;
    padding: 0!important;
    margin-top: auto!important;
    margin-bottom: auto!important;
    color: #818ea3;
  }
}
</style>
