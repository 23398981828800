<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-button-toolbar class="mb-4">
        <d-button-group>
          <d-button
            @click="() => this.isEditable = !this.isEditable"
          >
            {{ this.isEditable ? 'View' : 'Edit' }}
          </d-button>
        </d-button-group>
      </d-button-toolbar>
      <h6
        v-if="!this.isEditable"
        class="text-muted"
      >
        {{ currentApplication.title }}
      </h6>
      <d-row v-else>
        <d-col>
          <d-input
            id="feNewsTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppSlug">Slug</label>
            <d-input
              id="feAppSlug"
              v-model="slug"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- DESCRIPTION -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppDescription">Description</label>
            <VueTrix
              input-id="feAppDescription"
              v-model="description"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- FEATURES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppFeatures">Features</label>
            <VueTrix
              input-id="feAppFeatures"
              v-model="features"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- OS SUPPORTS -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppSupports">OS Supports</label>
            <VueTrix
              input-id="feAppSupports"
              v-model="supports"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- PRICE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppPrice">Price</label>
            <d-input
              id="feAppPrice"
              v-model="price"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- ANDROID URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppAndroidUrl">Android URL</label>
            <d-input
              id="feAppAndroidUrl"
              v-model="androidUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- iOS URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppiOSUrl">iOS URL</label>
            <d-input
              id="feAppiOSUrl"
              v-model="iOSUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- Linux URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppLinuxUrl">Linux URL</label>
            <d-input
              id="feAppLinuxUrl"
              v-model="linuxUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- macOS URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppMacOSUrl">macOS URL</label>
            <d-input
              id="feAppMacOSUrl"
              v-model="macOSUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- Window URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppWindowUrl">Windows URL</label>
            <d-input
              id="feAppWindowUrl"
              v-model="windowUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- Video URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppVideoUrl">Video URL</label>
            <d-input
              id="feAppVideoUrl"
              v-model="videoUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- Document URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppDocumentUrl">Document URL</label>
            <d-input
              id="feAppDocumentUrl"
              v-model="documentUrl"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- IMAGE UPLOAD -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feAppImageFiles">
              Upload images
            </label>
            <div class="custom-file mb-3">
              <input
                type="file"
                class="custom-file-input"
                id="feAppImageFiles"
                ref="feAppImageFiles"
                @change="handlePreviewFile"
                multiple
              >
              <label
                class="custom-file-label"
                for="feAppImageFiles"
              >
                Choose file...
              </label>
            </div>
          </d-col>
        </d-form-row>
        <!-- IMAGE VIEW -->
        <d-form-row
          class="mb-3"
          v-if="this.isEditable"
        >
          <d-col
            v-for="image in previewImageUrls"
            :key="image.id"
            cols="4"
          >
            <d-button
              v-if="image.id"
              outline
              pill
              theme="warning"
              size="sm"
              class="mb-2"
              @click.prevent="handleRemoveAppImage(image.id)"
            >
              Remove
            </d-button>
            <d-img
              :src="image.url"
              fluid
              thumbnail
              rounded
              class="mb-2"
            />
          </d-col>
        </d-form-row>
        <!-- Submit -->
        <d-form-row v-if="this.isEditable">
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleUpdateApp"
            >
              Update
            </d-button>
            <d-button
              theme="danger"
              class="mr-3"
              @click.prevent="handleDeleteApp"
            >
              Delete
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'

import ReadFileAsync from '@/mixins/ReadFileAsync'
import {
  UPDATE_AROMA_APPLICATION,
  DELETE_AROMA_APPLICATION,
  APPLICATION_TITLE_UPDATE,
  APPLICATION_SLUG_UPDATE,
  APPLICATION_DESC_UPDATE,
  APPLICATION_PRICE_UPDATE,
  APPLICATION_SUPPORT_UPDATE,
  APPLICATION_FEATURES_UPDATE,
  APPLICATION_ANDROID_UPDATE,
  APPLICATION_IOS_UPDATE,
  APPLICATION_LINUX_UPDATE,
  APPLICATION_MACOS_UPDATE,
  APPLICATION_WINDOW_UPDATE,
  APPLICATION_VIDEO_UPDATE,
  APPLICATION_DOCUMENT_UPDATE,
  APPLICATION_IMAGE_UPDATE,
  APPLICATION_IMAGE_REMOVE
} from '@/states/actionTypes'

export default {
  name: 'AromaApplicationView',
  components: {
    VueTrix
  },
  mixins: [
    ReadFileAsync()
  ],
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentApplication: 'ApplicationsManagement/currentApplication'
    }),
    previewImageUrls () {
      let urls = []
      if (this.currentApplication.images && this.currentApplication.images.length > 0) {
        this.currentApplication.images.forEach((image) => {
          if (image.file && !image.deleted) {
            if (image.file.url) {
              urls.push({ id: image.id, url: image.file.url })
            } else {
              urls.push({ id: null, url: image.file })
            }
          }
        })
      }
      return urls
    },
    title: {
      get () {
        return this.currentApplication.title
      },
      set (newTitle) {
        this.updateAppTitle(newTitle)
        this.updateAppSlug(newTitle)
      }
    },
    slug: {
      get () {
        return this.currentApplication.slug
      },
      set (newSlug) {
        this.updateAppSlug(newSlug)
      }
    },
    description: {
      get () {
        return this.currentApplication.description
      },
      set (newDesc) {
        this.updateAppDesc(newDesc)
      }
    },
    features: {
      get () {
        return this.currentApplication.features
      },
      set (newFeatures) {
        this.updateAppFeatures(newFeatures)
      }
    },
    supports: {
      get () {
        return this.currentApplication.os_supported
      },
      set (newOSSupport) {
        this.updateAppSupport(newOSSupport)
      }
    },
    price: {
      get () {
        return this.currentApplication.price
      },
      set (newPrice) {
        this.updateAppPrice(newPrice)
      }
    },
    androidUrl: {
      get () {
        return this.currentApplication.android_url
      },
      set (newAndroidUrl) {
        this.updateAppAndroidUrl(newAndroidUrl)
      }
    },
    iOSUrl: {
      get () {
        return this.currentApplication.ios_url
      },
      set (newIOSUrl) {
        this.updateAppIOSUrl(newIOSUrl)
      }
    },
    linuxUrl: {
      get () {
        return this.currentApplication.linux_url
      },
      set (newLinuxUrl) {
        this.updateAppLinuxUrl(newLinuxUrl)
      }
    },
    macOSUrl: {
      get () {
        return this.currentApplication.mac_url
      },
      set (newMacOSUrl) {
        this.updateAppMacOSUrl(newMacOSUrl)
      }
    },
    windowUrl: {
      get () {
        return this.currentApplication.window_url
      },
      set (newWindowUrl) {
        this.updateAppWindowUrl(newWindowUrl)
      }
    },
    videoUrl: {
      get () {
        return this.currentApplication.video_url
      },
      set (newVideoUrl) {
        this.updateAppVideoUrl(newVideoUrl)
      }
    },
    documentUrl: {
      get () {
        return this.currentApplication.document_links
      },
      set (newDocumentUrl) {
        this.updateAppDocumentUrl(newDocumentUrl)
      }
    }
  },
  methods: {
    ...mapActions({
      updateAromaApp: `ApplicationsManagement/${UPDATE_AROMA_APPLICATION}`,
      deleteAromaApp: `ApplicationsManagement/${DELETE_AROMA_APPLICATION}`,
      updateAppTitle: `ApplicationsManagement/${APPLICATION_TITLE_UPDATE}`,
      updateAppSlug: `ApplicationsManagement/${APPLICATION_SLUG_UPDATE}`,
      updateAppDesc: `ApplicationsManagement/${APPLICATION_DESC_UPDATE}`,
      updateAppPrice: `ApplicationsManagement/${APPLICATION_PRICE_UPDATE}`,
      updateAppSupport: `ApplicationsManagement/${APPLICATION_SUPPORT_UPDATE}`,
      updateAppFeatures: `ApplicationsManagement/${APPLICATION_FEATURES_UPDATE}`,
      updateAppAndroidUrl: `ApplicationsManagement/${APPLICATION_ANDROID_UPDATE}`,
      updateAppIOSUrl: `ApplicationsManagement/${APPLICATION_IOS_UPDATE}`,
      updateAppLinuxUrl: `ApplicationsManagement/${APPLICATION_LINUX_UPDATE}`,
      updateAppMacOSUrl: `ApplicationsManagement/${APPLICATION_MACOS_UPDATE}`,
      updateAppWindowUrl: `ApplicationsManagement/${APPLICATION_WINDOW_UPDATE}`,
      updateAppVideoUrl: `ApplicationsManagement/${APPLICATION_VIDEO_UPDATE}`,
      updateAppDocumentUrl: `ApplicationsManagement/${APPLICATION_DOCUMENT_UPDATE}`,
      updateAppImage: `ApplicationsManagement/${APPLICATION_IMAGE_UPDATE}`,
      removeAppImage: `ApplicationsManagement/${APPLICATION_IMAGE_REMOVE}`
    }),
    /** Handle updating App event */
    handleUpdateApp () {
      this.updateAromaApp({ app: this.currentApplication, locale: this.$i18n.locale })
    },
    handleDeleteApp () {
      let confirmDelete = confirm(`Are you sure to delete:\n ${this.currentApplication.title}?`)
      if (confirmDelete) {
        // detelet contact
        this.deleteAromaApp(this.currentApplication)
      }
    },
    /** Handle preview uploaded images */
    async handlePreviewFile () {
      if (this.$refs.feAppImageFiles.files.length > 0) {
        for (var index = 0; index < this.$refs.feAppImageFiles.files.length; index++) {
          const previewUrl = URL.createObjectURL(this.$refs.feAppImageFiles.files[index])
          this.previewImageUrls.push(previewUrl)

          const imageData = await this.readFileAsync(this.$refs.feAppImageFiles.files[index])
          this.updateAppImage({ file: imageData })
        }
      }
    },
    handleRemoveAppImage (imageId) {
      this.removeAppImage(imageId)
    }
  }
}
</script>
