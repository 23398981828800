import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { refreshToken } from '@/utils/refreshToken'

let RESOURCE_API_URL = ''
let AUTH_API_URL = ''
let SERVERLESS_API_URL = ''

if (process.env.VUE_APP_RESOURCE_API_URL) {
  RESOURCE_API_URL = process.env.VUE_APP_RESOURCE_API_URL
}

if (process.env.VUE_APP_AUTH_API_URL) {
  AUTH_API_URL = process.env.VUE_APP_AUTH_API_URL
}

if (process.env.VUE_APP_SERVERLESS_API_URL) {
  SERVERLESS_API_URL = process.env.VUE_APP_SERVERLESS_API_URL
}

export const ResourceServer = axios.create({
  timeout: 20000,
  baseURL: RESOURCE_API_URL + 'api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const AuthServer = axios.create({
  timeout: 20000,
  baseURL: AUTH_API_URL + 'api/',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const ServerlessServer = axios.create({
  timeout: 20000,
  baseURL: SERVERLESS_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_SERVERLESS_API_KEY
  }
})

Vue.use(VueAxios, {
  resourceServer: ResourceServer,
  authServer: AuthServer,
  serverlessServer: ServerlessServer
})

/**
 * Add response interceptor which help to refreshing JWT token
 */
ResourceServer.interceptors.response.use(response => response, async (error) => {
  const status = error.response ? error.response.status : null

  if (status === 401 &&
      error.response.data.error.toLowerCase() === 'token expired') {
    // console.log('Axios interceptor:', error.response)
    const refreshedResponses = await refreshToken(error.response.request)
    error.config.headers['Authorization'] = 'Bearer ' + refreshedResponses.data.data.token
    return Vue.axios.authServer.request(error.config)
  }
  return Promise.reject(error)
})

AuthServer.interceptors.response.use(response => response, async (error) => {
  const status = error.response ? error.response.status : null

  if (status === 401 &&
      error.response.data.error.toLowerCase() === 'token expired') {
    // console.log('Axios interceptor:', error.response)
    const refreshedResponses = await refreshToken(error.response.request)
    error.config.headers['Authorization'] = 'Bearer ' + refreshedResponses.data.data.token
    return Vue.axios.authServer.request(error.config)
  }
  return Promise.reject(error)
})
