<template>
  <div class="mx-lg-4">
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          User details
        </h3>
      </div>
    </div>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="text-muted">
              New user's account
            </h6>
          </div>
          <div class="card-body text-left border-bottom">
            <h4>
              Please, register new user from AromaPlayer application!
            </h4>
            <p class="text-muted">
              This feature have been not supported yet.
            </p>
            <d-button
              theme="info"
              @click="handleBackBtn"
            >
              Back
            </d-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserNew',
  methods: {
    handleBackBtn () {
      this.$router.back()
    }
  }
}
</script>
