import userStates from './UserStates'
import userGetters from './UserGetters'
import userMutations from './UserMutations'
import userActions from './UserActions'

const user = {
  namespaced: true,
  state: userStates,
  getters: userGetters,
  mutations: userMutations,
  actions: userActions
}

export default user
