<template>
  <div class="card mb-4">
    <div class="card-header border-bottom search">
      <d-button-toolbar>
        <i
          class="material-icons"
          id="search-icon"
        >
          search
        </i>
        <input
          id="cartridge-search-input"
          :placeholder="$t('cartridges.searchCartridgeHint')"
          ref="search"
          v-model="searchCartridgeQuery"
        >
      </d-button-toolbar>
    </div>
    <div class="card-body p-0 pb-2">
      <div class="table-responsive">
        <table class="table table-hover m-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("cartridges.cartridgeCode") }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("cartridges.cartridgeName") }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("cartridges.cartridgeLocation") }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("cartridges.cartridgeState") }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("cartridges.cartridgeQuantity") }}
              </th>
              <th
                scope="col"
                class="border-0"
              >
                {{ $t("common.managementActions") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <CartridgeItem
              v-for="cartridgeItem in cartridges"
              :key="cartridgeItem.id"
              :cartridge-item="cartridgeItem"
            />
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer p-0">
      <!-- Pagination -->
      <base-pagination
        :pagination-meta="paginationMeta"
        @go-to-page="handleLoadPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  FETCH_CARTRIDGES,
  FETCH_LOCATIONS,
  CARTRIDGE_SEARCH_QUERY_UPDATE
} from '@/states/actionTypes'
import CartridgeItem from './CartridgeItem'

export default {
  name: 'CartridgeList',
  components: {
    CartridgeItem
  },
  mounted () {
    this.fetchLocations()
    this.fetchCartridges({
      locale: this.$i18n.locale,
      query: this.searchCartridge.query,
      page: 1,
      size: 10
    })
    window.addEventListener('keyup', (event) => {
      if (event.key === '/') {
        this.$refs.search.focus()
      }
    })
  },
  computed: {
    ...mapGetters({
      searchCartridge: 'CartridgesManagement/searchCartridge',
      cartridges: 'CartridgesManagement/cartridges',
      paginationMeta: 'CartridgesManagement/paginationMeta'
    }),
    searchCartridgeQuery: {
      get () {
        return this.searchCartridge.query
      },
      set (newQuery) {
        this.updateCartridgeSearchQuery(newQuery)
        this.onSearchSubmit()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCartridges: `CartridgesManagement/${FETCH_CARTRIDGES}`,
      fetchLocations: `CartridgesManagement/${FETCH_LOCATIONS}`,
      updateCartridgeSearchQuery: `CartridgesManagement/${CARTRIDGE_SEARCH_QUERY_UPDATE}`
    }),
    handleLoadPage (pageNumber) {
      switch (pageNumber) {
        case 'first':
          this.fetchCartridges({
            query: this.searchCartridge.query,
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchCartridges({
            query: this.searchCartridge.query,
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchCartridges({
            query: this.searchCartridge.query,
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    },
    onSearchSubmit () {
      this.fetchCartridges({
        locale: this.$i18n.locale,
        page: 1,
        size: 10,
        query: this.searchCartridge.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #search-icon {
    font-size: 28px;
    margin-left: 5px;
    margin-right: 10px;
  }
  #cartridge-search-input {
    border: 0;
    outline: none;
    height: 100%;
    flex-grow: 1;
    margin: 0;
    font-size: 16px;
  }
  .card-header.border-bottom.search {
    padding: 0 10px;
    height: 55px;
  }
  .btn-toolbar {
    height: 100%;
    flex-grow: 1;
    align-items: center;
  }
</style>
