/** Aroma Cartridges management states */

const cartridgesManagementStates = {
  searchCartridge: {
    query: ''
  },
  cartridges: [],
  currentCartridge: {
    serial: '',
    stock: '',
    quantity: '',
    classification: '',
    kind: '',
    ename: '',
    jname: '',
    price: '',
    description: ''
  },
  cartridgeLocations: [],
  paginationMeta: {
    current: 0,
    limit: 10,
    pages: 0,
    next: 0,
    prev: 0
  },
  remoteMessage: null
}

export default cartridgesManagementStates
