<template>
  <d-container
    fluid
    class="main-content-container px-4"
  >
    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          WARNING
        </span>
        <h3
          class="page-title"
        >
          Loading Timeout
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5>
          The page timed out while loading.
          Are you sure you're still connected to the Internet?
        </h5>
      </div>
    </div>
  </d-container>
</template>

<script>
export default {
  name: 'Timeout'
}
</script>

<style lang="scss" module>
</style>
