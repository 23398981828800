/**
 * Orders management getters
 **/

function sortOrderCondition (firstOrder, secondOrder) {
  if (firstOrder.updatedAt > secondOrder.updatedAt) {
    return -1
  }
  if (firstOrder.updatedAt < secondOrder.updatedAt) {
    return 1
  }

  return 0
}

const ordersManagementGetters = {
  orders: (state) => state.orders,
  sortedOrders: (state) => {
    let orders = Object.assign([], state.orders)

    return orders.sort((firstOrder, secondOrder) => sortOrderCondition(firstOrder, secondOrder))
  },
  lastEvaluatedKey: (state) => state.lastEvaluatedKey,
  currentOrder: (state) => state.currentOrder,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage,
  totalCartridgesQuantity: (state) => {
    let total = 0
    if (state.currentOrder.selectedCartridges && state.currentOrder.selectedCartridges.length > 0) {
      state.currentOrder.selectedCartridges.forEach((cartridge) => {
        total += parseInt(cartridge.quantity)
      })
    }
    return total
  },
  totalAromaShooterQuantity: (state) => {
    let total = 0
    if (state.currentOrder.metadata && state.currentOrder.metadata.length > 0) {
      for (let index = 0; index < state.currentOrder.metadata.length; index++) {
        total += parseInt(Object.values(state.currentOrder.metadata[index])[0])
      }
    }
    return total
  }
}

export default ordersManagementGetters
