/** Dashboard */
export const SET_GOOGLE_ACCESS_SESSION = 'setGAccessSession'
export const SET_STATS_PAGEVIEW = 'setPageviewStats'
export const SET_STATS_PAGELOADTIME = 'setPageLoadTimeStats'
export const SET_STATS_CURRENT_USERS = 'setCurrentUserStats'
export const SET_STATS_NEW_VISITORS = 'setNewVisitorStats'
export const SET_STATS_SESSIONS = 'setSessionStats'
export const SET_STATS_DEVICE_CATEGORIES = 'setDeviceCategoryStats'

/** Common mutations */
export const SET_PAGINATION_META = 'setPaginationMeta'
export const SET_REMOTE_MESSAGE = 'setRemoteMessage'

/** News management */
export const SET_NEWS_LIST = 'setNewsList'
export const SET_CURRENT_NEWS = 'setCurrentNews'
export const SET_NEWS_TITLE = 'setNewsTitle'
export const SET_NEWS_SLUG = 'setNewsSlug'
export const SET_NEWS_DESCRIPTION = 'setNewsDescription'
export const SET_NEWS_HIGHLIGHTED = 'setNewsHighlighted'
export const SET_NEWS_START_DATE = 'setNewsStartDate'
export const SET_NEWS_END_DATE = 'setNewsEndDate'
export const SET_NEWS_IMAGE = 'setNewsImage'
export const REMOVE_NEWS_IMAGE = 'removeNewsImage'

/** FAQ management */
export const SET_FAQ_LIST = 'setFAQList'
export const SET_CURRENT_FAQ = 'setCurrentFAQ'
export const SET_FAQ_QUESTION = 'setFAQQuestion'
export const SET_FAQ_SLUG = 'setFAQSlug'
export const SET_FAQ_ANSWER = 'setFAQAnswer'
export const SET_FAQ_CATEGORY = 'setFAQCategory'

/** Aroma Cartridges management */
export const SET_CARTRIDGES = 'cartridges'
export const SET_CURRENT_CARTRIDGE = 'updateCurrentCartridge'
export const RESET_CURRENT_CARTRIDGE = 'resetCurrentCartridge'
export const SET_CARTRIDGE_SERIAL = 'updateCartridgeSerial'
export const SET_CARTRIDGE_ACCESS = 'updateCartridgeAccess'
export const SET_CARTRIDGE_LOCATION = 'updateCartridgeLocation'
export const SET_LOCATIONS = 'updateAllLocations'
export const SET_CARTRIDGE_STOCK = 'updateCartridgeStock'
export const SET_CARTRIDGE_QUANTITY = 'updateCartridgeQuantity'
export const SET_CARTRIDGE_CLASSIFICATION = 'updateCartridgeClassification'
export const SET_CARTRIDGE_KIND = 'updateCartridgeKind'
export const SET_CARTRIDGE_ENAME = 'updateCartridgeEname'
export const SET_CARTRIDGE_JNAME = 'updateCartridgeJname'
export const SET_CARTRIDGE_PRICE = 'updateCartridgePrice'
export const SET_CARTRIDGE_DESCRIPTION = 'updateCartridgeDescription'
export const SET_CURRENT_CARTRIDGE_IMAGE = 'updateCurrentCartridgeImage'
export const SET_CARTRIDGE_SEARCH_QUERY = 'updateCartridgeSearchQuery'

/** Contact management */
export const SET_CONTACT_LIST = 'setContactList'
export const SET_CURRENT_CONTACT = 'setCurrentContact'

/** Aroma Applications management */
export const SET_AROMA_APPLICATIONS = 'setAromaApplicationList'
export const SET_CURRENT_APPLICATION = 'setCurrentApplication'
export const SET_APPLICATION_TITLE = 'setApplicationTitle'
export const SET_APPLICATION_SLUG = 'setApplicationSlug'
export const SET_APPLICATION_DESC = 'setApplicationDesc'
export const SET_APPLICATION_PRICE = 'setApplicationPrice'
export const SET_APPLICATION_SUPPORT = 'setApplicationSupport'
export const SET_APPLICATION_FEATURES = 'setApplicationFeatures'
export const SET_APPLICATION_ANDROID = 'setApplicationAndroidUrl'
export const SET_APPLICATION_IOS = 'setApplicationIOSUrl'
export const SET_APPLICATION_LINUX = 'setApplicationLinuxUrl'
export const SET_APPLICATION_MACOS = 'setApplicationMacOSUrl'
export const SET_APPLICATION_WINDOW = 'setApplicationWindowUrl'
export const SET_APPLICATION_VIDEO = 'setApplicationVideoUrl'
export const SET_APPLICATION_DOCUMENT = 'setApplicationDocumentUrl'
export const SET_APPLICATION_IMAGES = 'setApplicationImages'
export const SET_APPLICATION_IMAGE = 'setApplicationImage'
export const REMOVE_APPLICATION_IMAGE = 'removeApplicationImage'

/** Aroma SDK management */
export const SET_AROMA_SDKS = 'setAromaSDKList'
export const SET_CURRENT_SDK = 'setCurrentSDK'
export const SET_SDK_TITLE = 'setSDKTitle'
export const SET_SDK_SLUG = 'setSDKSlug'
export const SET_SDK_DOWNLOAD_URL = 'setSDKDownloadUrl'
export const SET_SDK_OVERVIEW = 'setSDKOverview'
export const SET_SDK_INSTALL_GUIDES = 'setSDKInstallGuides'

/** Authentication */
export const SET_USER = 'setUser'
export const SET_USER_PROFILE = 'setUserProfile'

/** Users management */
export const SET_USERS = 'setUsers'

/** Firmware management */
export const SET_FIRMWARE_LIST = 'setFirmwareList'
export const SET_CURRENT_FIRMWARE = 'setCurrentFirmware'
export const SET_FIRMWARE_LATEST = 'setFirmwareLatest'
export const SET_FIRMWARE_NAME = 'setFirmwareName'
export const SET_FIRMWARE_AS_VERSION = 'setFirmwareASVersion'
export const SET_FIRMWARE_VERSION = 'setFirmwareVersion'
export const SET_FIRMWARE_DESCRIPTION = 'setFirmwareDescription'
export const SET_FIRMWARE_BINARY = 'setFirmwareBinary'

/** Aroma Shooters management */
export const SET_AROMA_SHOOTERS = 'setAromaShooterList'
export const SET_CURRENT_SHOOTER = 'setCurrentAromaShooter'
export const SET_SHOOTER_TITLE = 'setShooterTitle'
export const SET_SHOOTER_SLUG = 'setShooterSlug'
export const SET_SHOOTER_TYPE = 'setShooterType'
export const SET_SHOOTER_PREFIX = 'setShooterPrefix'
export const SET_SHOOTER_COLOR = 'setShooterColor'
export const SET_SHOOTER_PORTS = 'setShooterPorts'
export const SET_SHOOTER_BLENDING = 'setShooterBlending'
export const SET_SHOOTER_VOLTAGE = 'setShooterVoltage'
export const SET_SHOOTER_DESIGN = 'setShooterDesign'
export const SET_SHOOTER_DISTANCE = 'setShooterDistance'
export const SET_SHOOTER_LED = 'setShooterLed'
export const SET_SHOOTER_METHOD = 'setShooterDiffuseMethod'
export const SET_SHOOTER_POWER = 'setShooterPower'
export const SET_SHOOTER_SIZE = 'setShooterSize'
export const SET_SHOOTER_SOFTWARES = 'setShooterSoftwares'
export const SET_SHOOTER_WEIGHT = 'setShooterWeight'
export const SET_SHOOTER_VIDEO = 'setShooterVideoUrl'
export const SET_SHOOTER_TEMP = 'setShooterTempRange'
export const SET_SHOOTER_ACCESSORIES = 'setShooterAccessories'
export const SET_SHOOTER_DOCUMENTS_EN = 'setShooterDocumentEN'
export const SET_SHOOTER_DOCUMENTS_JP = 'setShooterDocumentJP'

/** Members management */
export const SET_MEMBERS = 'setMembersList'

/** Careers management */
export const SET_CAREER_LIST = 'setCareerList'
export const SET_CURRENT_CAREER = 'setCurrentCareer'
export const SET_CAREER_TITLE = 'setCareerTitle'
export const SET_CAREER_DEADLINE = 'setCareerDeadline'
export const SET_CAREER_SLUG = 'setCareerSlug'
export const SET_CAREER_REQUIREMENTS = 'setCareerRequirements'
export const SET_CAREER_DESCRIPTION = 'setCareerDescription'
export const SET_CAREER_BENEFITS = 'setCareerBenefits'
export const SET_CAREER_CONDITIONS = 'setCareerConditions'
export const SET_CAREER_LOCATION = 'setCareerLocation'

/** Audits management */
export const SET_AUDITS_LIST = 'setAuditsList'
export const SET_CURRENT_AUDIT = 'setCurrentAudit'

/** Subscribers management */
export const SET_SUBSCRIBERS_LIST = 'setSubscribersList'
export const SET_CURRENT_SUBSCRIBER = 'setCurrentSubscriber'

/** Order management */
export const SET_ORDER_LAST_EVALUATED_KEY = 'setOrderLastEvaluatedKey'
export const SET_ORDERS_LIST = 'setOrdersList'
export const SET_CURRENT_ORDER = 'setCurrentOrder'
export const SET_ORDER_STATE = 'setOrderState'
export const SET_ORDER_NUMBER = 'setOrderNumber'
export const SET_ORDER_NET_AMOUNT = 'setOrderNetAmount'
export const SET_ORDER_SHIPPING_AMOUNT = 'setOrderShippingAmount'
export const SET_ORDER_GROSS_AMOUNT = 'setOrderGrossAmount'
export const SET_ORDER_TRANSACTION_FEE = 'setOrderTransactionFee'
export const SET_ORDER_CHARGED_AMOUNT = 'setOrderChargedAmount'
export const SET_ORDER_CUSTOMER_NAME = 'setOrderCustomerName'
export const SET_ORDER_CUSTOMER_EMAIL = 'setOrderCustomerEmail'
export const SET_ORDER_CUSTOMER_PHONE = 'setOrderCustomerPhone'
export const SET_ORDER_SHIPPING_CARRIER = 'setOrderShippingCarrier'
export const SET_ORDER_SHIPPING_TRACKING_CODE = 'setOrderShippingTrackingCode'
export const SET_ORDER_SHIPPING_PHONE = 'setOrderShippingPhone'
export const SET_ORDER_SHIPPING_NAME = 'setOrderShippingName'
export const SET_ORDER_SHIPPING_TRACK_URL = 'setOrderShippingTrackURL'
export const SET_ORDER_METADATA = 'setOrderMetadata'
export const UPDATE_ORDER_METADATA_ITEM = 'updateOrderMetadataItem'
export const ADD_ORDER_METADATA_ITEM = 'addOrderMetadataItem'
export const REMOVE_ORDER_METADATA_ITEM = 'removeOrderMetadataItem'
export const UPDATE_ORDER_CARTRIDGES = 'setOrderSelectedCartridges'
export const ADD_ORDER_CARTRIDGE = 'addOrderSelectedCartridge'
export const REMOVE_ORDER_CARTRIDGE = 'removeOrderSelectedCartridge'
