<template>
  <aside :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '']">
    <div class="main-navbar">
      <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
        <base-link
          class="navbar-brand w-100 mr-0"
          name="home"
          style="line-height: 25px;"
        >
          <div class="d-table m-auto">
            <d-img
              id="main-logo"
              class="d-inline-block align-middle mr-2"
              :src="require('@/assets/images/aromajoin-logo.png')"
              alt="Aromajoin Dashboard"
            />
            <span
              v-if="!hideLogoText"
              class="d-none d-md-inline ml-1"
            >
              Aromajoin CMS
            </span>
          </div>
        </base-link>
        <a
          class="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          @click="handleToggleSidebar()"
        >
          <i class="material-icons">
            &#xE5C4;
          </i>
        </a>
      </nav>
    </div>

    <form
      action="#"
      class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none"
    >
      <div class="input-group input-group-seamless ml-3">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-search" />
          </div>
        </div>
        <input
          class="navbar-search form-control"
          type="text"
          placeholder="Search for something..."
          aria-label="Search"
        >
      </div>
    </form>

    <div class="nav-wrapper">
      <d-nav class="flex-column">
        <li
          v-for="(item, navItemIdx) in items"
          :key="navItemIdx"
          class="nav-item dropdown"
        >
          <base-link
            v-if="displaySidebar(item)"
            v-d-toggle="`snc-${navItemIdx}`"
            :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
            :to="item.to"
          >
            <div
              v-if="item.htmlBefore"
              class="item-icon-wrapper"
              v-html="item.htmlBefore"
            />
            <span v-if="item.title">
              {{ item.title }}
            </span>
            <div
              v-if="item.htmlAfter"
              class="item-icon-wrapper"
              v-html="item.htmlAfter"
            />
          </base-link>

          <d-collapse
            v-if="displaySidebar(item) && item.items && item.items.length"
            :id="`snc-${navItemIdx}`"
            class="dropdown-menu dropdown-menu-small"
            accordion="sidebar-items-accordion"
          >
            <d-dropdown-item
              v-for="(subItem, subItemIdx) in item.items"
              :key="subItemIdx"
              :href="subItem.href"
              :to="subItem.to"
            >
              {{ subItem.title }}
            </d-dropdown-item>
          </d-collapse>
        </li>
      </d-nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainSidebar',
  props: {
    /**
      * Whether to hide the logo text, or not.
      */
    hideLogoText: {
      type: Boolean,
      default: false
    },
    /**
     * The menu items.
     */
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sidebarVisible: false
    }
  },
  created () {
    this.$eventHub.$on('toggle-sidebar', this.handleToggleSidebar)
  },
  beforeDestroy () {
    this.$eventHub.$off('toggle-sidebar')
  },
  computed: {
    ...mapGetters({
      currentUser: 'User/user'
    })
  },
  methods: {
    handleToggleSidebar () {
      this.sidebarVisible = !this.sidebarVisible
    },
    displaySidebar (item) {
      if (this.currentUser && (item.role === this.currentUser.user_type || this.currentUser.user_type === 'super_admin')) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-sidebar {
    overflow-y: auto;

    #main-logo {
      max-height: 30px;
    }
    .item-icon-wrapper {
      display: inline-block;
    }
    .dropdown-menu {
      display: block;
    }
  }
</style>
