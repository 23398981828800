<template>
  <div>
    <!-- New Firmware button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewFirmware"
          class="btn-royal-blue"
        >
          Create firmware version
        </d-button>
      </d-col>
    </d-row>

    <div class="row">
      <div class="col">
        <firmware-list />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FirmwareList from './FirmwareList'
import {
  CURRENT_FIRMWARE_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'FirmwareIndex',
  components: {
    FirmwareList
  },
  methods: {
    ...mapActions({
      updateCurrentFirmware: `AromaFirmwareManagement/${CURRENT_FIRMWARE_UPDATE}`
    }),
    handleNewFirmware () {
      this.updateCurrentFirmware({
        firmwareName: '',
        description: '',
        asVersion: '',
        version: '',
        binaryURL: '',
        binaryName: '',
        latest: true
      })
      this.$router.push({ name: 'create-aroma-shooter-firmware' })
    }
  }
}
</script>
