import faqsManagementStates from './FAQsManagementStates'
import faqsManagementGetters from './FAQsManagementGetters'
import faqsManagementMutations from './FAQsManagementMutations'
import faqsManagementActions from './FAQsManagementActions'

const faqsManagement = {
  namespaced: true,
  state: faqsManagementStates,
  getters: faqsManagementGetters,
  mutations: faqsManagementMutations,
  actions: faqsManagementActions
}

export default faqsManagement
