<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-button-toolbar class="mb-4">
        <d-button-group>
          <d-button
            @click="() => this.isEditable = !this.isEditable"
          >
            {{ this.isEditable ? 'View' : 'Edit' }}
          </d-button>
        </d-button-group>
      </d-button-toolbar>
      <h6
        v-if="!this.isEditable"
        class="text-muted"
      >
        {{ currentSDK.title }}
      </h6>
      <d-row v-else>
        <d-col>
          <d-input
            id="feNewsTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKSlug">Slug</label>
            <d-input
              id="feSDKSlug"
              v-model="slug"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- DOWNLOAD URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKDownloadURL">Download URL</label>
            <d-input
              id="feSDKDownloadURL"
              v-model="downloadURL"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- OVERVIEW -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKOverview">Overview</label>
            <VueTrix
              input-id="feSDKOverview"
              v-model="overview"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- INSTALL GUIDES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKInstallGuides">Install guides</label>
            <VueTrix
              input-id="feSDKInstallGuides"
              v-model="installGuides"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- Submit -->
        <d-form-row v-if="this.isEditable">
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleUpdateSDK"
            >
              Update
            </d-button>
            <d-button
              theme="danger"
              class="mr-3"
              @click.prevent="handleDeleteSDK"
            >
              Delete
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'
import {
  SDK_TITLE_UPDATE,
  SDK_SLUG_UPDATE,
  SDK_DOWNLOAD_URL_UPDATE,
  SDK_OVERVIEW_UPDATE,
  SDK_INSTALL_GUIDES_UPDATE,
  UPDATE_AROMA_SDK,
  DELETE_AROMA_SDK
} from '@/states/actionTypes'

export default {
  name: 'AromaSdkView',
  components: {
    VueTrix
  },
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentSDK: 'ControllerSdksManagement/currentSDK'
    }),
    title: {
      get () {
        return this.currentSDK.title || ''
      },
      set (newTitle) {
        this.updateSDKTitle(newTitle)
        this.updateSDKSlug(newTitle)
      }
    },
    slug: {
      get () {
        return this.currentSDK.slug
      },
      set (newSlug) {
        this.updateSDKSlug(newSlug)
      }
    },
    downloadURL: {
      get () {
        return this.currentSDK.link_url
      },
      set (newDownloadURL) {
        this.updateSDKDownloadURL(newDownloadURL)
      }
    },
    overview: {
      get () {
        return this.currentSDK.overview
      },
      set (newOverview) {
        this.updateSDKOverview(newOverview)
      }
    },
    installGuides: {
      get () {
        return this.currentSDK.install_guides
      },
      set (newGuides) {
        this.updateSDKInstallGuides(newGuides)
      }
    }
  },
  methods: {
    ...mapActions({
      updateSDKTitle: `ControllerSdksManagement/${SDK_TITLE_UPDATE}`,
      updateSDKSlug: `ControllerSdksManagement/${SDK_SLUG_UPDATE}`,
      updateSDKDownloadURL: `ControllerSdksManagement/${SDK_DOWNLOAD_URL_UPDATE}`,
      updateSDKOverview: `ControllerSdksManagement/${SDK_OVERVIEW_UPDATE}`,
      updateSDKInstallGuides: `ControllerSdksManagement/${SDK_INSTALL_GUIDES_UPDATE}`,
      updateAromaSDK: `ControllerSdksManagement/${UPDATE_AROMA_SDK}`,
      deleteAromaSDK: `ControllerSdksManagement/${DELETE_AROMA_SDK}`
    }),
    /** Handle updating SDK event */
    handleUpdateSDK () {
      this.updateAromaSDK({ sdk: this.currentSDK, locale: this.$i18n.locale })
    },
    /** Handle deleting SDK event */
    handleDeleteSDK () {
      let confirmDelete = confirm(`Are you sure to delete:\n ${this.currentSDK.title}?`)
      if (confirmDelete) {
        this.deleteAromaSDK(this.currentSDK)
      }
    }
  }
}
</script>
