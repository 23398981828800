<template>
  <div class="mx-lg-4">
    <base-notify
      :display="showMessage"
      :message="notifiedMessage"
      :dismiss-secs="4"
      :type="messageType"
      position="top-left"
    />

    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          Users
        </h3>
      </div>
    </div>

    <!-- New User button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewUser"
          class="btn-royal-blue"
        >
          New user
        </d-button>
      </d-col>
    </d-row>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <users-list />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'
import UsersList from './UsersList'

export default {
  name: 'UsersIndex',
  mixins: [
    ProcessErrorMessages('UsersManagement/remoteMessage')
  ],
  components: {
    UsersList
  },
  computed: {
    ...mapGetters({
      remoteMessage: 'UsersManagement/remoteMessage'
    })
  },
  methods: {
    handleNewUser () {
      console.log('Creating new user...')
      this.$router.push({
        name: 'user-new'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
