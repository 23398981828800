import subscriberStates from './SubscriberStates'
import subscriberGetters from './SubscriberGetters'
import subscriberMutations from './SubscriberMutations'
import subscriberActions from './SubscriberActions'

const subscribersManagement = {
  namespaced: true,
  state: subscriberStates,
  getters: subscriberGetters,
  mutations: subscriberMutations,
  actions: subscriberActions
}

export default subscribersManagement
