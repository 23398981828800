/**
 * Controller SDK management states
 **/
const controllerSdksManagementStates = {
  controllerSdks: [],
  currentSDK: null,
  paginationMeta: {
    current: 0,
    limit: 10,
    pages: 0,
    next: 0,
    prev: 0
  },
  remoteMessage: null
}

export default controllerSdksManagementStates
