/**
 * Aroma Cartridges management actions
 */
import Vue from 'vue'
import router from '@/router'

import {
  SET_REMOTE_MESSAGE,
  SET_CARTRIDGES,
  SET_PAGINATION_META,
  SET_CURRENT_CARTRIDGE_IMAGE,
  SET_CURRENT_CARTRIDGE,
  RESET_CURRENT_CARTRIDGE,
  SET_CARTRIDGE_SERIAL,
  SET_CARTRIDGE_ACCESS,
  SET_CARTRIDGE_LOCATION,
  SET_LOCATIONS,
  SET_CARTRIDGE_STOCK,
  SET_CARTRIDGE_QUANTITY,
  SET_CARTRIDGE_CLASSIFICATION,
  SET_CARTRIDGE_KIND,
  SET_CARTRIDGE_ENAME,
  SET_CARTRIDGE_JNAME,
  SET_CARTRIDGE_PRICE,
  SET_CARTRIDGE_DESCRIPTION,
  SET_CARTRIDGE_SEARCH_QUERY
} from '../../mutationTypes'
import {
  FETCH_CARTRIDGES,
  FETCH_LOCATIONS,
  CREATE_CARTRIDGE,
  UPDATE_CARTRIDGE,
  DELETE_CARTRIDGE,
  CURRENT_CARTRIDGE_UPDATE,
  CURRENT_CARTRIDGE_DESTROY,
  CARTRIDGE_SERIAL_UPDATE,
  CARTRIDGE_ACCESS_UPDATE,
  CARTRIDGE_LOCATION_UPDATE,
  CARTRIDGE_STOCK_UPDATE,
  CARTRIDGE_QUANTITY_UPDATE,
  CARTRIDGE_CLASSIFICATION_UPDATE,
  CARTRIDGE_KIND_UPDATE,
  CARTRIDGE_ENAME_UPDATE,
  CARTRIDGE_JNAME_UPDATE,
  CARTRIDGE_PRICE_UPDATE,
  CARTRIDGE_DESCRIPTION_UPDATE,
  CARTRIDGE_IMAGE_UPDATE,
  CARTRIDGE_SEARCH_QUERY_UPDATE
} from '../../actionTypes'

const cartridgesManagementActions = {
  /** Fetch Aroma Cartridges data from server API */
  async [FETCH_CARTRIDGES] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const cartridgeResponses = await Vue.axios.resourceServer.get(
        'aroma_cartridges',
        {
          params: {
            'locale': params.locale,
            'page[number]': params.page,
            'page[size]': params.size,
            'q[ename_or_serial_or_jname_or_kind_or_stock_location_location_cont]': params.query || ''
          },
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )
      if (cartridgeResponses.data.data) {
        commit(SET_CARTRIDGES, cartridgeResponses.data.data)
        commit(SET_PAGINATION_META, cartridgeResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + cartridgeResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Fetch Cartridges' location API */
  async [FETCH_LOCATIONS] ({ commit, rootGetters }) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const locationResponses = await Vue.axios.resourceServer.get(
        'stock_locations',
        {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )
      if (locationResponses.data) {
        // console.log('Locations:', locationResponses.data.data)
        commit(SET_LOCATIONS, locationResponses.data.data)
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Create Aroma Cartridge data by invoking API */
  async [CREATE_CARTRIDGE] ({ commit, rootGetters }, cartridge) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      // the API expects a user_id key in the request body
      cartridge['user_id'] = currentAccount.id

      // must stringify the cartridge object before we use it as our API request body
      const data = JSON.stringify(cartridge)
      await Vue.axios.resourceServer.post(
        'aroma_cartridges',
        data,
        {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )
      commit(SET_REMOTE_MESSAGE, {
        message: 'Created cartridge successfully!',
        type: 'success'
      })
      // after creating a new cartridge, the user will be redirected to the cartridges index
      setTimeout(function () {
        router.push({
          path: '/aroma-cartridges'
        })
      }, 3000)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Update Aroma Cartridge data by invoking API */
  async [UPDATE_CARTRIDGE] ({ commit, rootGetters }, cartridge) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      // the API expects a user_id key in the request body
      cartridge['user_id'] = currentAccount.id

      const data = JSON.stringify(cartridge)

      await Vue.axios.resourceServer.put(
        `aroma_cartridges/${cartridge.id}`,
        data,
        {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )

      commit(SET_REMOTE_MESSAGE, {
        message: `[${cartridge.serial}] was updated successfully.`,
        type: 'success'
      })
      setTimeout(function () {
        router.push({
          path: '/aroma-cartridges'
        })
      }, 3000)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Delete Aroma Cartridge data by invoking API */
  async [DELETE_CARTRIDGE] ({ commit, rootGetters }, cartridge) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      // the API expects a user_id key in the request body
      cartridge['user_id'] = currentAccount.id

      await Vue.axios.resourceServer.delete(
        `aroma_cartridges/${cartridge.id}`,
        {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )
      commit(SET_REMOTE_MESSAGE, {
        message: `[${cartridge.serial}] was deleted successfully.`,
        type: 'success'
      })
      setTimeout(function () {
        router.push({
          path: '/aroma-cartridges'
        })
      }, 3000)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_CARTRIDGE_UPDATE] ({ commit }, cartridge) {
    // updates the state's currentCartridge, not including image...
    commit(SET_CURRENT_CARTRIDGE, cartridge)
  },
  [CURRENT_CARTRIDGE_DESTROY] ({ commit }) {
    // updates the state's currentCartridge, not including image...
    commit(RESET_CURRENT_CARTRIDGE)
  },
  [CARTRIDGE_SERIAL_UPDATE] ({ commit }, serial) {
    commit(SET_CARTRIDGE_SERIAL, serial)
  },
  [CARTRIDGE_ACCESS_UPDATE] ({ commit }, access) {
    commit(SET_CARTRIDGE_ACCESS, access)
  },
  [CARTRIDGE_LOCATION_UPDATE] ({ commit }, location) {
    commit(SET_CARTRIDGE_LOCATION, location)
  },
  [CARTRIDGE_STOCK_UPDATE] ({ commit }, stock) {
    commit(SET_CARTRIDGE_STOCK, stock)
  },
  [CARTRIDGE_QUANTITY_UPDATE] ({ commit }, quantity) {
    commit(SET_CARTRIDGE_QUANTITY, quantity)
  },
  [CARTRIDGE_CLASSIFICATION_UPDATE] ({ commit }, classification) {
    commit(SET_CARTRIDGE_CLASSIFICATION, classification)
  },
  [CARTRIDGE_KIND_UPDATE] ({ commit }, kind) {
    commit(SET_CARTRIDGE_KIND, kind)
  },
  [CARTRIDGE_ENAME_UPDATE] ({ commit }, ename) {
    commit(SET_CARTRIDGE_ENAME, ename)
  },
  [CARTRIDGE_JNAME_UPDATE] ({ commit }, jname) {
    commit(SET_CARTRIDGE_JNAME, jname)
  },
  [CARTRIDGE_PRICE_UPDATE] ({ commit }, price) {
    commit(SET_CARTRIDGE_PRICE, price)
  },
  [CARTRIDGE_DESCRIPTION_UPDATE] ({ commit }, description) {
    commit(SET_CARTRIDGE_DESCRIPTION, description)
  },
  [CARTRIDGE_IMAGE_UPDATE] ({ commit }, image) {
    commit(SET_CURRENT_CARTRIDGE_IMAGE, image)
  },
  [CARTRIDGE_SEARCH_QUERY_UPDATE] ({ commit }, query) {
    commit(SET_CARTRIDGE_SEARCH_QUERY, query)
  }
}

export default cartridgesManagementActions
