/**
 * Web default mutations
 */
import {
  SET_GOOGLE_ACCESS_SESSION,
  SET_STATS_PAGEVIEW,
  SET_STATS_PAGELOADTIME,
  SET_STATS_CURRENT_USERS,
  SET_STATS_NEW_VISITORS,
  SET_STATS_SESSIONS,
  SET_STATS_DEVICE_CATEGORIES
} from '../../mutationTypes'

const WebDefaultMutations = {
  [SET_STATS_DEVICE_CATEGORIES]: (state, deviceCategoriesData) => {
    let deviceCategories = {
      datasets: [{
        hoverBorderColor: '#ffffff',
        data: [68.3, 24.2, 7.5],
        backgroundColor: [
          'rgba(0,123,255,0.9)',
          'rgba(0,123,255,0.5)',
          'rgba(0,123,255,0.3)'
        ]
      }],
      labels: ['Desktop', 'Tablet', 'Mobile']
    }
    deviceCategories.data = deviceCategoriesData
    state.deviceCategoryStats = deviceCategories
  },
  [SET_STATS_SESSIONS]: (state, sessions) => {
    state.sessionStats.value = sessions
  },
  [SET_STATS_NEW_VISITORS]: (state, newVisitors) => {
    state.newVisitorStats.value = newVisitors
  },
  [SET_STATS_CURRENT_USERS]: (state, currentUsers) => {
    state.currentUserStats.value = currentUsers
  },
  [SET_STATS_PAGELOADTIME]: (state, loadTime) => {
    state.timeLoadStats.value = loadTime
  },
  [SET_STATS_PAGEVIEW]: (state, pageviewData) => {
    state.pageviewStats.value = pageviewData
  },
  [SET_GOOGLE_ACCESS_SESSION]: (state, accessSession) => {
    state.googleAccessSession = accessSession
  }
}

export default WebDefaultMutations
