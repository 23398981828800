/** Vuex store declaration */
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/staticRegistration'
import usersManagement from './modules/UsersManagement'
import cartridgesManagement from './modules/CartridgesManagement'
import aromaShootersManagement from './modules/AromaShootersManagement'
import applicationsManagement from './modules/ApplicationsManagement'
import careersManagement from './modules/CareersManagement'
import contactsManagement from './modules/ContactsManagement'
import controllerSdksManagement from './modules/ControllerSDKsManagement'
import faqsManagement from './modules/FAQsManagement'
import membersManagement from './modules/MembersManagement'
import newsManagement from './modules/NewsManagement'
import firmwareManagement from './modules/AromaFirmwareManagement'
import auditManagement from './modules/AuditsManagement'
import subscribersManagement from './modules/SubscribersManagement'
import ordersManagement from './modules/OrdersManagement'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production'
})

store.registerModule('ApplicationsManagement', applicationsManagement)
store.registerModule('AromaShootersManagement', aromaShootersManagement)
store.registerModule('CartridgesManagement', cartridgesManagement)
store.registerModule('CareersManagement', careersManagement)
store.registerModule('ControllerSdksManagement', controllerSdksManagement)
store.registerModule('ContactsManagement', contactsManagement)
store.registerModule('FAQsManagement', faqsManagement)
store.registerModule('MembersManagement', membersManagement)
store.registerModule('NewsManagement', newsManagement)
store.registerModule('UsersManagement', usersManagement)
store.registerModule('AromaFirmwareManagement', firmwareManagement)
store.registerModule('AuditsManagement', auditManagement)
store.registerModule('SubscribersManagement', subscribersManagement)
store.registerModule('OrdersManagement', ordersManagement)

export default store
