<template>
  <div class="row">
    <div class="col">
      <div class="card mb-4">
        <div class="card-header border-bottom">
          <h6 class="m-0">
            Contacts List
          </h6>
        </div>
        <div class="card-body p-0 pb-2 text-left">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="border-0 text-center"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    Created at
                  </th>
                  <th
                    scope="col"
                    class="border-0"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <ContactItem
                  v-for="contact in contacts"
                  :key="contact.id"
                  :contact="contact"
                />
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer p-0">
          <!-- Pagination -->
          <base-pagination
            :pagination-meta="paginationMeta"
            @go-to-page="handleLoadPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FETCH_CONTACTS } from '@/states/actionTypes'
import ContactItem from './ContactItem'

export default {
  name: 'ContactIndex',
  components: {
    ContactItem
  },
  mounted () {
    this.fetchContacts({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      contacts: 'ContactsManagement/contacts',
      paginationMeta: 'ContactsManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchContacts: `ContactsManagement/${FETCH_CONTACTS}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchContacts({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchContacts({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchContacts({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    }
  }
}
</script>
