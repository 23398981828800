/**
 * Aroma Shooters management mutation
 **/
import {
  SET_AROMA_SHOOTERS,
  SET_CURRENT_SHOOTER,
  SET_SHOOTER_ACCESSORIES,
  SET_SHOOTER_BLENDING,
  SET_SHOOTER_COLOR,
  SET_SHOOTER_DESIGN,
  SET_SHOOTER_DISTANCE,
  SET_SHOOTER_LED,
  SET_SHOOTER_METHOD,
  SET_SHOOTER_PORTS,
  SET_SHOOTER_POWER,
  SET_SHOOTER_PREFIX,
  SET_SHOOTER_SIZE,
  SET_SHOOTER_SOFTWARES,
  SET_SHOOTER_TEMP,
  SET_SHOOTER_TITLE,
  SET_SHOOTER_TYPE,
  SET_SHOOTER_SLUG,
  SET_SHOOTER_VIDEO,
  SET_SHOOTER_VOLTAGE,
  SET_SHOOTER_WEIGHT,
  SET_SHOOTER_DOCUMENTS_EN,
  SET_SHOOTER_DOCUMENTS_JP,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

const aromaShootersManagementMutations = {
  [SET_CURRENT_SHOOTER]: (state, shooter) => {
    state.currentAromaShooter = shooter
  },
  [SET_AROMA_SHOOTERS]: (state, shooters) => {
    state.aromaShooters = shooters
  },
  [SET_SHOOTER_DOCUMENTS_EN]: (state, documentEN) => {
    state.currentAromaShooter.document_links = documentEN
  },
  [SET_SHOOTER_DOCUMENTS_JP]: (state, documentJP) => {
    state.currentAromaShooter.jp_document_links = documentJP
  },
  [SET_SHOOTER_ACCESSORIES]: (state, accessories) => {
    state.currentAromaShooter.accessories = accessories
  },
  [SET_SHOOTER_BLENDING]: (state, blending) => {
    state.currentAromaShooter.blending = blending
  },
  [SET_SHOOTER_COLOR]: (state, color) => {
    state.currentAromaShooter.color = color
  },
  [SET_SHOOTER_DESIGN]: (state, design) => {
    state.currentAromaShooter.design = design
  },
  [SET_SHOOTER_DISTANCE]: (state, distance) => {
    state.currentAromaShooter.distance = distance
  },
  [SET_SHOOTER_LED]: (state, led) => {
    state.currentAromaShooter.led = led
  },
  [SET_SHOOTER_METHOD]: (state, method) => {
    state.currentAromaShooter.method = method
  },
  [SET_SHOOTER_PORTS]: (state, ports) => {
    state.currentAromaShooter.ports = ports
  },
  [SET_SHOOTER_POWER]: (state, power) => {
    state.currentAromaShooter.power = power
  },
  [SET_SHOOTER_PREFIX]: (state, prefix) => {
    state.currentAromaShooter.prefix = prefix
  },
  [SET_SHOOTER_SIZE]: (state, size) => {
    state.currentAromaShooter.size = size
  },
  [SET_SHOOTER_SOFTWARES]: (state, softwares) => {
    state.currentAromaShooter.softwares = softwares
  },
  [SET_SHOOTER_TEMP]: (state, temp) => {
    state.currentAromaShooter.temp = temp
  },
  [SET_SHOOTER_VIDEO]: (state, videoUrl) => {
    state.currentAromaShooter.videoUrl = videoUrl
  },
  [SET_SHOOTER_VOLTAGE]: (state, voltage) => {
    state.currentAromaShooter.voltage = voltage
  },
  [SET_SHOOTER_WEIGHT]: (state, weight) => {
    state.currentAromaShooter.weight = weight
  },
  [SET_SHOOTER_TITLE]: (state, title) => {
    state.currentAromaShooter.title = title
  },
  [SET_SHOOTER_SLUG]: (state, slug) => {
    state.currentAromaShooter.slug = slug
  },
  [SET_SHOOTER_TYPE]: (state, type) => {
    state.currentAromaShooter.type = type
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default aromaShootersManagementMutations
