<template>
  <div>
    <!-- New Subscriber button -->
    <!-- <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewSubscriber"
          class="btn-royal-blue"
        >
          Create subscriber
        </d-button>
      </d-col>
    </d-row> -->

    <div class="row">
      <div class="col">
        <subscribers-list />
      </div>
    </div>
  </div>
</template>

<script>
import SubscribersList from './SubscribersList'

export default {
  name: 'SubscriberIndex',
  components: {
    SubscribersList
  },
  methods: {
    handleNewSubscriber () {
      console.log('Importing new subscriber')
    }
  }
}
</script>
