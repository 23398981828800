<template>
  <div>
    <!-- New Career button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewCareer"
          class="btn-royal-blue"
        >
          {{ $t("careers.newCareerTitle") }}
        </d-button>
      </d-col>
    </d-row>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <CareerList />
      </div>
    </div>
  </div>
</template>

<script>
import CareerList from '@/components/careers/CareerList'
import { mapActions } from 'vuex'
import {
  CURRENT_CAREER_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'CareersIndex',
  components: {
    CareerList
  },
  methods: {
    ...mapActions({
      updateCurrentCareer: `CareersManagement/${CURRENT_CAREER_UPDATE}`
    }),
    /** Handle going to new Career view */
    async handleNewCareer () {
      await this.updateCurrentCareer({})
      this.$router.push({
        name: 'new-career'
      })
    }
  }
}
</script>
