<template>
  <div>
    <!-- New App button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewSDK"
          class="btn-royal-blue"
        >
          Create SDK
        </d-button>
      </d-col>
    </d-row>

    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0 text-muted">
              SDKs List
            </h6>
          </div>
          <div class="card-body p-0 pb-2 text-left">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                    <th
                      scope="col"
                      class="border-0 text-center"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Overview
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Updated at
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AromaSdkItem
                    v-for="sdk in controllerSdks"
                    :key="sdk.id"
                    :sdk="sdk"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer p-0">
            <!-- Pagination -->
            <base-pagination
              :pagination-meta="paginationMeta"
              @go-to-page="handleLoadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AromaSdkItem from './AromaSdkItem'
import {
  FETCH_AROMA_SDKS
} from '@/states/actionTypes'

export default {
  name: 'AromaSdkIndex',
  components: {
    AromaSdkItem
  },
  mounted () {
    this.fetchControllerSdks({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      controllerSdks: 'ControllerSdksManagement/controllerSdks',
      paginationMeta: 'ControllerSdksManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchControllerSdks: `ControllerSdksManagement/${FETCH_AROMA_SDKS}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchControllerSdks({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchControllerSdks({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchControllerSdks({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    },
    handleNewSDK () {
      this.$router.push({
        name: 'create-aroma-sdk'
      })
    }
  }
}
</script>
