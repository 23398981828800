<template>
  <d-container
    fluid
  >
    <d-row>
      <d-col
        class="main-content p-0"
        tag="main"
        lg="12"
        md="12"
        sm="12"
      >
        <!-- Main Content View -->
        <slot name="body" />

        <!-- Main Footer -->
        <the-footer :menu-items="defaultFooterMenuItems" />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import TheFooter from '@/components/layout/TheFooter'
import { mapGetters } from 'vuex'

export default {
  name: 'UnauthorizedLayout',
  components: {
    TheFooter
  },
  computed: {
    ...mapGetters({
      defaultFooterMenuItems: 'WebDefault/defaultFooterMenuItems'
    })
  }
}
</script>
