/** Users management mutations */
import {
  SET_USERS,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

const usersManagementMutations = {
  [SET_USERS]: (state, users) => {
    state.users = users
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default usersManagementMutations
