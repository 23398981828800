/**
 * Order management mutations
 **/
import {
  SET_ORDER_NUMBER,
  SET_ORDER_LAST_EVALUATED_KEY,
  SET_ORDER_SHIPPING_TRACK_URL,
  UPDATE_ORDER_CARTRIDGES,
  ADD_ORDER_CARTRIDGE,
  REMOVE_ORDER_CARTRIDGE,
  REMOVE_ORDER_METADATA_ITEM,
  ADD_ORDER_METADATA_ITEM,
  SET_ORDER_METADATA,
  SET_ORDER_SHIPPING_CARRIER,
  SET_ORDER_SHIPPING_TRACKING_CODE,
  SET_ORDER_SHIPPING_PHONE,
  SET_ORDER_SHIPPING_NAME,
  SET_ORDER_CUSTOMER_NAME,
  SET_ORDER_CUSTOMER_EMAIL,
  SET_ORDER_CUSTOMER_PHONE,
  SET_ORDER_NET_AMOUNT,
  SET_ORDER_SHIPPING_AMOUNT,
  SET_ORDER_GROSS_AMOUNT,
  SET_ORDER_TRANSACTION_FEE,
  SET_ORDER_CHARGED_AMOUNT,
  SET_ORDER_STATE,
  SET_ORDERS_LIST,
  SET_CURRENT_ORDER,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const ordersManagementMutations = {
  [SET_ORDER_LAST_EVALUATED_KEY]: (state, lastKey) => {
    state.lastEvaluatedKey = lastKey
  },
  [SET_ORDER_SHIPPING_TRACK_URL]: (state, trackURL) => {
    state.currentOrder.shipping.tracking_url = trackURL
  },
  [REMOVE_ORDER_CARTRIDGE]: (state, cartridge) => {
    state.currentOrder.selectedCartridges = state.currentOrder.selectedCartridges
      .filter(selectedCartridge => selectedCartridge.serial !== cartridge.serial)
  },
  [ADD_ORDER_CARTRIDGE]: (state, cartridge) => {
    state.currentOrder.selectedCartridges.push(cartridge)
  },
  [UPDATE_ORDER_CARTRIDGES]: (state, cartridges) => {
    state.currentOrder.selectedCartridges = cartridges
  },
  [REMOVE_ORDER_METADATA_ITEM]: (state, metaItem) => {
    state.currentOrder.metadata = state.currentOrder.metadata
      .filter(item => Object.keys(item)[0] !== Object.keys(metaItem)[0])
  },
  [ADD_ORDER_METADATA_ITEM]: (state, metaItem) => {
    state.currentOrder.metadata.push(metaItem)
  },
  [SET_ORDER_METADATA]: (state, metadata) => {
    state.currentOrder.metadata = metadata
  },
  [SET_ORDER_SHIPPING_PHONE]: (state, shippingPhone) => {
    state.currentOrder.shipping.phone = shippingPhone
  },
  [SET_ORDER_SHIPPING_TRACKING_CODE]: (state, trackingCode) => {
    state.currentOrder.shipping.tracking_number = trackingCode
  },
  [SET_ORDER_SHIPPING_CARRIER]: (state, carrier) => {
    state.currentOrder.shipping.carrier = carrier
  },
  [SET_ORDER_SHIPPING_NAME]: (state, shippingName) => {
    state.currentOrder.shipping.name = shippingName
  },
  [SET_ORDER_CUSTOMER_PHONE]: (state, phone) => {
    state.currentOrder.customer.phone = phone
  },
  [SET_ORDER_CUSTOMER_EMAIL]: (state, email) => {
    state.currentOrder.customer.email = email
  },
  [SET_ORDER_CUSTOMER_NAME]: (state, name) => {
    state.currentOrder.customer.name = name
  },
  [SET_ORDER_CHARGED_AMOUNT]: (state, chargedAmount) => {
    state.currentOrder.totalChargeAmount = chargedAmount
  },
  [SET_ORDER_TRANSACTION_FEE]: (state, transactionFee) => {
    state.currentOrder.applicationFee = transactionFee
  },
  [SET_ORDER_GROSS_AMOUNT]: (state, grossAmount) => {
    state.currentOrder.grossAmount = grossAmount
  },
  [SET_ORDER_SHIPPING_AMOUNT]: (state, shippingAmount) => {
    state.currentOrder.shippingAmount = shippingAmount
  },
  [SET_ORDER_NET_AMOUNT]: (state, netAmount) => {
    state.currentOrder.netAmount = netAmount
  },
  [SET_ORDER_STATE]: (state, orderState) => {
    state.currentOrder.orderState = orderState
  },
  [SET_ORDER_NUMBER]: (state, orderNumber) => {
    state.currentOrder.orderNumber = orderNumber
  },
  [SET_ORDERS_LIST]: (state, orders) => {
    state.orders = [...state.orders, ...orders]
  },
  [SET_CURRENT_ORDER]: (state, currentOrder) => {
    state.currentOrder = currentOrder
  },
  [SET_PAGINATION_META]: (state, pagination) => {
    state.paginationMeta = pagination
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default ordersManagementMutations
