<template>
  <tr @mouseover="() => this.updateCurrentFirmware(this.firmware)">
    <td class="text-center">
      {{ firmware.latest ? "&#x2618;" : "" }}
    </td>
    <td>
      {{ firmware.firmwareName }}
    </td>
    <td>
      {{ firmware.asVersion }}
    </td>
    <td>
      {{ firmware.version }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group">
        <d-button
          class="btn-white"
          title="Set latest firmware"
          @click="handleLatestFirmwareChange"
        >
          <i class="material-icons">
            flash_on
          </i>
        </d-button>
        <d-button
          class="btn-info"
          title="Download firmware binary"
          @click="handleDownloadFirmware"
        >
          <i class="material-icons">
            cloud_download
          </i>
        </d-button>
        <d-button
          class="btn-danger"
          title="Remove firmware"
          @click="handleRemoveFirmware"
        >
          <i class="material-icons">
            delete_sweep
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  UPDATE_FIRMWARE,
  CURRENT_FIRMWARE_UPDATE,
  FIRMWARE_LATEST_UPDATE,
  DELETE_FIRMWARE,
  DOWNLOAD_FIRMWARE
} from '@/states/actionTypes'

export default {
  name: 'FirmwareItem',
  props: {
    firmware: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentFirmware: 'AromaFirmwareManagement/currentFirmware'
    })
  },
  methods: {
    ...mapActions({
      downloadFirmware: `AromaFirmwareManagement/${DOWNLOAD_FIRMWARE}`,
      updateFirmware: `AromaFirmwareManagement/${UPDATE_FIRMWARE}`,
      deleteFirmware: `AromaFirmwareManagement/${DELETE_FIRMWARE}`,
      updateCurrentFirmware: `AromaFirmwareManagement/${CURRENT_FIRMWARE_UPDATE}`,
      updateFirmwareLatest: `AromaFirmwareManagement/${FIRMWARE_LATEST_UPDATE}`
    }),
    handleLatestFirmwareChange () {
      if (confirm(`Are you sure to make this [${this.currentFirmware.firmwareName}] become the latest?`)) {
        this.updateFirmwareLatest(this.currentFirmware)
        this.updateFirmware(this.currentFirmware)
      }
    },
    handleRemoveFirmware () {
      if (confirm(`Are you sure to delete firmware [${this.currentFirmware.firmwareName || this.currentFirmware.version}]?`)) {
        this.deleteFirmware(this.currentFirmware)
      }
    },
    handleDownloadFirmware () {
      this.downloadFirmware(this.currentFirmware)
    }
  }
}
</script>
