/** Aroma Shooters management actions */
import { stringToSlug } from '@/utils/stringToSlug'
import Vue from 'vue'
import router from '@/router'
import {
  CREATE_SHOOTER,
  UPDATE_SHOOTER,
  DELETE_SHOOTER,
  FETCH_AROMA_SHOOTER_LIST,
  CURRENT_SHOOTER_UPDATE,
  SHOOTER_TITLE_UPDATE,
  SHOOTER_SLUG_UPDATE,
  SHOOTER_TYPE_UPDATE,
  SHOOTER_PREFIX_UPDATE,
  SHOOTER_COLOR_UPDATE,
  SHOOTER_PORTS_UPDATE,
  SHOOTER_BLENDING_UPDATE,
  SHOOTER_VOLTAGE_UPDATE,
  SHOOTER_DESIGN_UPDATE,
  SHOOTER_DISTANCE_UPDATE,
  SHOOTER_LED_UPDATE,
  SHOOTER_METHOD_UPDATE,
  SHOOTER_POWER_UPDATE,
  SHOOTER_SIZE_UPDATE,
  SHOOTER_SOFTWARES_UPDATE,
  SHOOTER_WEIGHT_UPDATE,
  SHOOTER_VIDEO_UPDATE,
  SHOOTER_TEMP_UPDATE,
  SHOOTER_ACCESSORIES_UPDATE,
  SHOOTER_DOCUMENTS_EN_UPDATE,
  SHOOTER_DOCUMENTS_JP_UPDATE
} from '../../actionTypes'
import {
  SET_AROMA_SHOOTERS,
  SET_CURRENT_SHOOTER,
  SET_SHOOTER_ACCESSORIES,
  SET_SHOOTER_BLENDING,
  SET_SHOOTER_COLOR,
  SET_SHOOTER_DESIGN,
  SET_SHOOTER_DISTANCE,
  SET_SHOOTER_LED,
  SET_SHOOTER_METHOD,
  SET_SHOOTER_PORTS,
  SET_SHOOTER_POWER,
  SET_SHOOTER_PREFIX,
  SET_SHOOTER_SIZE,
  SET_SHOOTER_SOFTWARES,
  SET_SHOOTER_TEMP,
  SET_SHOOTER_TITLE,
  SET_SHOOTER_TYPE,
  SET_SHOOTER_SLUG,
  SET_SHOOTER_VIDEO,
  SET_SHOOTER_VOLTAGE,
  SET_SHOOTER_WEIGHT,
  SET_SHOOTER_DOCUMENTS_EN,
  SET_SHOOTER_DOCUMENTS_JP,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

const aromaShootersManagementActions = {
  async [DELETE_SHOOTER] ({ commit, dispatch, getters, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const shooterResponses = await Vue.axios.resourceServer
        .delete(`aroma_products/${params.shooter.id}?locale=${params.locale}`, authHeader)

      if (shooterResponses.data.data) {
        await dispatch(FETCH_AROMA_SHOOTER_LIST, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })

        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })

        setTimeout(() => {
          router.push({ name: 'aroma-shooters-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_SHOOTER] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const shooterResponses = await Vue.axios.resourceServer
        .put(`aroma_products/${params.shooter.id}?locale=${params.locale}`, params.shooter, authHeader)

      if (shooterResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'aroma-shooters-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + shooterResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [CREATE_SHOOTER] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const shooterResponses = await Vue.axios.resourceServer
        .post(`aroma_products?locale=${params.locale}`, params.shooter, authHeader)

      if (shooterResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created Aroma Shooter successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'aroma-shooters-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + shooterResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [FETCH_AROMA_SHOOTER_LIST] ({ commit, rootGetters }, params) {
    // Fetching Aroma Cartridges data from server api
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const shooterResponses = await Vue.axios.resourceServer.get('aroma_products', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (shooterResponses.data.data) {
        commit(SET_AROMA_SHOOTERS, shooterResponses.data.data)
        commit(SET_PAGINATION_META, shooterResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + shooterResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_SHOOTER_UPDATE] ({ commit }, shooter) {
    commit(SET_CURRENT_SHOOTER, shooter)
  },
  [SHOOTER_ACCESSORIES_UPDATE] ({ commit }, accessories) {
    commit(SET_SHOOTER_ACCESSORIES, accessories)
  },
  [SHOOTER_BLENDING_UPDATE] ({ commit }, blending) {
    commit(SET_SHOOTER_BLENDING, blending)
  },
  [SHOOTER_COLOR_UPDATE] ({ commit }, color) {
    commit(SET_SHOOTER_COLOR, color)
  },
  [SHOOTER_DESIGN_UPDATE] ({ commit }, design) {
    commit(SET_SHOOTER_DESIGN, design)
  },
  [SHOOTER_DISTANCE_UPDATE] ({ commit }, distance) {
    commit(SET_SHOOTER_DISTANCE, distance)
  },
  [SHOOTER_LED_UPDATE] ({ commit }, led) {
    commit(SET_SHOOTER_LED, led)
  },
  [SHOOTER_METHOD_UPDATE] ({ commit }, method) {
    commit(SET_SHOOTER_METHOD, method)
  },
  [SHOOTER_PORTS_UPDATE] ({ commit }, ports) {
    commit(SET_SHOOTER_PORTS, ports)
  },
  [SHOOTER_POWER_UPDATE] ({ commit }, power) {
    commit(SET_SHOOTER_POWER, power)
  },
  [SHOOTER_PREFIX_UPDATE] ({ commit }, prefix) {
    commit(SET_SHOOTER_PREFIX, prefix)
  },
  [SHOOTER_SIZE_UPDATE] ({ commit }, size) {
    commit(SET_SHOOTER_SIZE, size)
  },
  [SHOOTER_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_SHOOTER_SLUG, stringToSlug(slug))
  },
  [SHOOTER_SOFTWARES_UPDATE] ({ commit }, softwares) {
    commit(SET_SHOOTER_SOFTWARES, softwares)
  },
  [SHOOTER_TEMP_UPDATE] ({ commit }, temperature) {
    commit(SET_SHOOTER_TEMP, temperature)
  },
  [SHOOTER_TITLE_UPDATE] ({ commit }, title) {
    commit(SET_SHOOTER_TITLE, title)
  },
  [SHOOTER_TYPE_UPDATE] ({ commit }, type) {
    commit(SET_SHOOTER_TYPE, type)
  },
  [SHOOTER_VIDEO_UPDATE] ({ commit }, video) {
    commit(SET_SHOOTER_VIDEO, video)
  },
  [SHOOTER_VOLTAGE_UPDATE] ({ commit }, voltage) {
    commit(SET_SHOOTER_VOLTAGE, voltage)
  },
  [SHOOTER_WEIGHT_UPDATE] ({ commit }, weight) {
    commit(SET_SHOOTER_WEIGHT, weight)
  },
  [SHOOTER_DOCUMENTS_EN_UPDATE] ({ commit }, documentEN) {
    commit(SET_SHOOTER_DOCUMENTS_EN, documentEN)
  },
  [SHOOTER_DOCUMENTS_JP_UPDATE] ({ commit }, documentJP) {
    commit(SET_SHOOTER_DOCUMENTS_JP, documentJP)
  }
}

export default aromaShootersManagementActions
