<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0 text-muted">
        News List
      </h6>
    </div>
    <div class="card-body p-0 pb-2 text-left">
      <div class="table-responsive">
        <table class="table table-hover m-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="border-0 text-center"
              >
                ID
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Title
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Description
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <NewsItem
              v-for="newsItem in news"
              :key="newsItem.slug"
              :news-item="newsItem"
            />
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer p-0">
      <!-- Pagination -->
      <base-pagination
        :pagination-meta="paginationMeta"
        @go-to-page="handleLoadPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FETCH_NEWS } from '@/states/actionTypes'
import NewsItem from './NewsItem'

export default {
  name: 'NewsList',
  components: {
    NewsItem
  },
  mounted () {
    this.fetchNews({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      news: 'NewsManagement/news',
      paginationMeta: 'NewsManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchNews: `NewsManagement/${FETCH_NEWS}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchNews({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchNews({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchNews({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    }
  }
}
</script>
