<template>
  <d-container
    fluid
    class="main-content-container"
  >
    <router-view />
  </d-container>
</template>

<script>
export default {
  name: 'UsersManagement',
  page: {
    title: 'Users management',
    links: [
      { rel: 'canonical', href: 'https://admin.aromajoin.com/users' }
    ]
  }
}
</script>

<style lang="scss" scoped>
</style>
