/**
 * News management getters
 */
const newsManagementGetters = {
  news: (state) => state.news,
  currentEvent: (state) => state.currentEvent,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default newsManagementGetters
