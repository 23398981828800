import Vue from 'vue'
import router from '@/router'

import { stringToSlug } from '@/utils/stringToSlug'
import {
  SET_CURRENT_NEWS,
  SET_NEWS_DESCRIPTION,
  SET_NEWS_END_DATE,
  SET_NEWS_HIGHLIGHTED,
  SET_NEWS_IMAGE,
  SET_NEWS_LIST,
  SET_NEWS_SLUG,
  SET_NEWS_START_DATE,
  SET_NEWS_TITLE,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  REMOVE_NEWS_IMAGE
} from '../../mutationTypes'
import {
  FETCH_NEWS,
  CREATE_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  CURRENT_NEWS_UPDATE,
  NEWS_DESCRIPTION_UPDATE,
  NEWS_END_DATE_UPDATE,
  NEWS_HIGHLIGHTED_UPDATE,
  NEWS_IMAGE_UPDATE,
  NEWS_SLUG_UPDATE,
  NEWS_START_DATE_UPDATE,
  NEWS_TITLE_UPDATE,
  NEWS_IMAGE_REMOVE
} from '../../actionTypes'

/**
 * News management actions
 **/
const newsManagementActions = {
  /** Fetch News data from server API */
  async [FETCH_NEWS] ({ commit, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const newsResponses = await Vue.axios.resourceServer.get('news', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (newsResponses.data.data) {
        commit(SET_NEWS_LIST, newsResponses.data.data)
        commit(SET_PAGINATION_META, newsResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + newsResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Create News data by invoking API */
  async [CREATE_NEWS] ({ commit, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const newsResponses = await Vue.axios.resourceServer
        .post(`news?locale=${params.locale}`, params.news, authHeader)

      if (newsResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created news successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'news-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + newsResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Update News data by invoking API */
  async [UPDATE_NEWS] ({ commit, getters, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const updatedId = getters['currentEvent'].id || params.news.id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const newsResponses = await Vue.axios.resourceServer
        .put(`news/${updatedId}?locale=${params.locale}`, params.news, authHeader)

      if (newsResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated News successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'news-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + newsResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Delete News data by invoking API */
  async [DELETE_NEWS] ({ commit, dispatch, getters, rootGetters }, id) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const deletedId = id || getters['currentEvent'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const newsResponses = await Vue.axios.resourceServer
        .delete(`news/${deletedId}`, authHeader)

      if (newsResponses.data.data) {
        await dispatch(FETCH_NEWS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_NEWS_UPDATE] ({ commit }, news) {
    commit(SET_CURRENT_NEWS, news)
  },
  [NEWS_TITLE_UPDATE] ({ commit }, title) {
    commit(SET_NEWS_TITLE, title)
  },
  [NEWS_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_NEWS_SLUG, stringToSlug(slug))
  },
  [NEWS_DESCRIPTION_UPDATE] ({ commit }, description) {
    commit(SET_NEWS_DESCRIPTION, description)
  },
  [NEWS_HIGHLIGHTED_UPDATE] ({ commit }, highlighted) {
    commit(SET_NEWS_HIGHLIGHTED, highlighted)
  },
  [NEWS_START_DATE_UPDATE] ({ commit }, startDate) {
    commit(SET_NEWS_START_DATE, startDate)
  },
  [NEWS_END_DATE_UPDATE] ({ commit }, endDate) {
    commit(SET_NEWS_END_DATE, endDate)
  },
  [NEWS_IMAGE_UPDATE] ({ commit }, image) {
    commit(SET_NEWS_IMAGE, image)
  },
  [NEWS_IMAGE_REMOVE] ({ commit }, imageId) {
    commit(REMOVE_NEWS_IMAGE, imageId)
  }
}

export default newsManagementActions
