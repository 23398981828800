<template>
  <tr>
    <td class="text-center">
      {{ careerItem.id }}
    </td>
    <td>{{ careerItem.title }}</td>
    <td>
      <div v-html="careerItem.description" />
    </td>
    <td>{{ careerItem.deadline | formatDate }}</td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          class="btn-white"
          @click="operation(careerItem, 'edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="operation(careerItem, 'view')"
        >
          <i class="material-icons">
            description
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'CareerItem',
  props: {
    careerItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    operation (career, type) {
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'career-details',
            params: { id: this.careerItem.id.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'career-details',
            params: { id: this.careerItem.id.toString(), action: 'edit' }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
