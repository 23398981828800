import careersManagementStates from './CareersManagementStates'
import careersManagementGetters from './CareersManagementGetters'
import careersManagementMutations from './CareersManagementMutations'
import careersManagementActions from './CareersManagementActions'

const careersManagement = {
  namespaced: true,
  state: careersManagementStates,
  getters: careersManagementGetters,
  mutations: careersManagementMutations,
  actions: careersManagementActions
}

export default careersManagement
