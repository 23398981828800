<template>
  <d-container
    fluid
    class="main-content-container px-4"
  >
    <base-notify
      :message="notifiedMessage"
      :display="showMessage"
      :dismiss-secs="dismissSecs"
      :type="messageType"
      position="top-left"
    />
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4"
    >
      <d-col
        col
        sm="4"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          User Profile
        </h3>
      </d-col>
    </d-row>

    <!-- Content -->
    <d-row>
      <d-col lg="4">
        <user-details />
      </d-col>
      <d-col lg="8">
        <account-details />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'
import UserDetails from '@/components/user-profile/UserDetails.vue'
import AccountDetails from '@/components/user-profile/AccountDetails.vue'
import { mapActions } from 'vuex'

export default {
  name: 'UserProfile',
  components: {
    UserDetails,
    AccountDetails
  },
  mixins: [
    ProcessErrorMessages('User/remoteMessage')
  ],
  mounted () {
    this.fetchProfile()
  },
  methods: {
    ...mapActions({
      fetchProfile: 'User/fetchUserProfile'
    })
  }
}
</script>
