<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-button-toolbar class="mb-4">
        <d-button-group>
          <d-button
            @click="() => this.isEditable = !this.isEditable"
          >
            {{ this.isEditable ? 'View' : 'Edit' }}
          </d-button>
        </d-button-group>
      </d-button-toolbar>
      <h6
        v-if="!this.isEditable"
        class="text-muted"
      >
        {{ currentFAQ.question }}
      </h6>
      <d-row v-else>
        <d-col>
          <d-input
            id="feFAQQuestion"
            v-model="question"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQSlug">Slug</label>
            <d-input
              id="feFAQSlug"
              v-model="slug"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- ANSWER -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQDescription">Answer</label>
            <VueTrix
              v-model="answer"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- CATEGORIES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQType">Question category</label>
            <d-select
              id="feFAQType"
              v-model="category"
              :disabled="!this.isEditable"
            >
              <option value="general">
                General
              </option>
              <option value="hardware">
                Hardware
              </option>
              <option value="software">
                Software
              </option>
            </d-select>
          </d-col>
        </d-form-row>
        <d-form-row v-if="this.isEditable">
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleUpdateFAQ"
            >
              Update
            </d-button>
            <d-button
              theme="danger"
              class="mr-3"
              @click.prevent="handleDeleteFAQ"
            >
              Delete
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
    <d-card-footer class="text-muted">
      Updated: {{ currentFAQ.updated_at | formatDate }} |
      Created: {{ currentFAQ.created_at | formatDate }}
    </d-card-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'
import {
  UPDATE_FAQ,
  DELETE_FAQ,
  FAQ_QUESTION_UPDATE,
  FAQ_SLUG_UPDATE,
  FAQ_ANSWER_UPDATE,
  FAQ_CATEGORY_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'FAQView',
  components: {
    VueTrix
  },
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentFAQ: 'FAQsManagement/currentFAQ'
    }),
    question: {
      get () {
        return this.currentFAQ.question
      },
      set (newQuestion) {
        this.updateFAQQuestion(newQuestion)
        if (this.$i18n.locale === 'en') {
          this.updateFAQSlug(newQuestion)
        }
      }
    },
    slug: {
      get () {
        return this.currentFAQ.slug
      },
      set (newSlug) {
        this.updateFAQSlug(newSlug)
      }
    },
    answer: {
      get () {
        return this.currentFAQ.answer
      },
      set (newAnswer) {
        this.updateFAQAnswer(newAnswer)
      }
    },
    category: {
      get () {
        return this.currentFAQ.question_type
      },
      set (newCategory) {
        this.updateFAQCategory(newCategory)
      }
    }
  },
  methods: {
    ...mapActions({
      updateFAQ: `FAQsManagement/${UPDATE_FAQ}`,
      deleteFAQ: `FAQsManagement/${DELETE_FAQ}`,
      updateFAQQuestion: `FAQsManagement/${FAQ_QUESTION_UPDATE}`,
      updateFAQSlug: `FAQsManagement/${FAQ_SLUG_UPDATE}`,
      updateFAQAnswer: `FAQsManagement/${FAQ_ANSWER_UPDATE}`,
      updateFAQCategory: `FAQsManagement/${FAQ_CATEGORY_UPDATE}`
    }),
    /** Handle updating FAQ event */
    handleUpdateFAQ () {
      this.updateFAQ({
        faq: this.currentFAQ,
        locale: this.$i18n.locale
      })
    },
    /** Handle deleting FAQ event */
    handleDeleteFAQ () {
      this.deleteFAQ(this.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
