/** Audit management getters */

const auditGetters = {
  audits: state => state.audits,
  currentAudit: state => state.currentAudit,
  paginationMeta: state => state.paginationMeta,
  remoteMessage: state => state.remoteMessage
}

export default auditGetters
