/**
 * Subscriber management mutations
 */
import {
  SET_SUBSCRIBERS_LIST,
  SET_CURRENT_SUBSCRIBER,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const subscriberMutations = {
  [SET_SUBSCRIBERS_LIST]: (state, subscribers) => {
    state.subscribers = subscribers
  },
  [SET_CURRENT_SUBSCRIBER]: (state, subscriber) => {
    state.currentSubscriber = subscriber
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default subscriberMutations
