import i18n from '../../../plugins/i18n'

/**
 * Web default getters
 */
const WebDefaultGetters = {
  googleAccessSession: (state) => state.googleAccessSession,
  timeLoadStats: (state) => state.timeLoadStats,
  pageviewStats: (state) => state.pageviewStats,
  currentUserStats: (state) => state.currentUserStats,
  newVisitorStats: (state) => state.newVisitorStats,
  sessionStats: (state) => state.sessionStats,
  deviceCategoryStats: (state) => state.deviceCategoryStats,
  defaultSidebarMenuItems: (state) => {
    return [
      {
        title: i18n.t('sideBarMenu.cartridgeManagement'),
        htmlBefore: '<i class="material-icons">widgets</i>',
        to: {
          name: 'aroma-cartridges-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.shooterManagement'),
        htmlBefore: '<i class="material-icons">group_work</i>',
        to: {
          name: 'aroma-shooters-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.firmwareManagement'),
        htmlBefore: '<i class="material-icons">memory</i>',
        to: {
          name: 'aroma-shooter-firmware-management'
        },
        role: 'super_admin'
      },
      {
        title: i18n.t('sideBarMenu.sdkManagement'),
        htmlBefore: '<i class="material-icons">view_quilt</i>',
        to: {
          name: 'sdks-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.applicationManagement'),
        htmlBefore: '<i class="material-icons">phonelink_setup</i>',
        to: {
          name: 'apps-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.auditLogsManagement'),
        htmlBefore: '<i class="material-icons">view_comfy</i>',
        to: {
          name: 'audit-logs-management'
        },
        role: 'super_admin'
      },
      {
        title: i18n.t('sideBarMenu.careersManagement'),
        htmlBefore: '<i class="material-icons">file_copy</i>',
        to: {
          name: 'careers-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.contactsManagement'),
        htmlBefore: '<i class="material-icons">contact_mail</i>',
        to: {
          name: 'contacts-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.faqsManagement'),
        htmlBefore: '<i class="material-icons">question_answer</i>',
        to: {
          name: 'faqs-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.membersManagement'),
        htmlBefore: '<i class="material-icons">supervised_user_circle</i>',
        to: {
          name: 'members-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.newsManagement'),
        htmlBefore: '<i class="material-icons">share</i>',
        to: {
          name: 'news-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.ordersManagement'),
        htmlBefore: '<i class="material-icons">shopping_cart</i>',
        to: {
          name: 'orders-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.subscribersManagement'),
        htmlBefore: '<i class="material-icons">people_outline</i>',
        to: {
          name: 'subscribers-management'
        },
        role: 'administrator'
      },
      {
        title: i18n.t('sideBarMenu.usersManagement'),
        to: {
          name: 'users-management'
        },
        htmlBefore: '<i class="material-icons">people</i>',
        htmlAfter: '',
        role: 'super_admin'
      }
    ]
  },
  defaultFooterMenuItems: (state) => {
    return [
      {
        title: 'Home',
        to: 'https://aromajoin.com/'
      },
      {
        title: 'Products',
        to: 'https://aromajoin.com/hardware/shooters/aroma-shooter-1'
      },
      {
        title: 'Apps',
        to: 'https://aromajoin.com/software/apps/aroma-controller'
      },
      {
        title: 'Company',
        to: 'https://aromajoin.com/about-aromajoin'
      },
      {
        title: 'Blog',
        to: 'https://aromajoin.com/blog'
      }
    ]
  }
}

export default WebDefaultGetters
