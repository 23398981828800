/**
 * Controller SDK management getters
 **/
const controllerSdksManagementGetters = {
  controllerSdks: (state) => state.controllerSdks,
  currentSDK: (state) => state.currentSDK,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default controllerSdksManagementGetters
