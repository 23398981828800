import webDefault from './WebDefault'
import user from './User'

const root = {
  modules: {
    User: user,
    WebDefault: webDefault
  }
}

export default root.modules
