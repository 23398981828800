/**
 * Aroma Firmware management mutations
 */
import {
  SET_FIRMWARE_LIST,
  SET_CURRENT_FIRMWARE,
  SET_FIRMWARE_LATEST,
  SET_FIRMWARE_AS_VERSION,
  SET_FIRMWARE_BINARY,
  SET_FIRMWARE_DESCRIPTION,
  SET_FIRMWARE_NAME,
  SET_FIRMWARE_VERSION,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const aromaFirmwareMutations = {
  [SET_FIRMWARE_LIST]: (state, firmwares) => {
    state.firmwares = firmwares
  },
  [SET_CURRENT_FIRMWARE]: (state, currentFirmware) => {
    state.currentFirmware = currentFirmware
  },
  [SET_FIRMWARE_LATEST]: (state, latestFirmware) => {
    state.currentFirmware.latest = true
    state.firmwares = state.firmwares.map((firmware) => {
      if (firmware.firmwareId === latestFirmware.firmwareId) {
        firmware.latest = true
      } else {
        firmware.latest = false
      }
      return firmware
    })
  },
  [SET_FIRMWARE_AS_VERSION]: (state, asVersion) => {
    state.currentFirmware.asVersion = asVersion
  },
  [SET_FIRMWARE_BINARY]: (state, binary) => {
    state.currentFirmware.binaryName = binary.name
    state.currentFirmware.binaryData = binary.data
  },
  [SET_FIRMWARE_DESCRIPTION]: (state, description) => {
    state.currentFirmware.description = description
  },
  [SET_FIRMWARE_NAME]: (state, name) => {
    state.currentFirmware.firmwareName = name
  },
  [SET_FIRMWARE_VERSION]: (state, version) => {
    state.currentFirmware.version = version
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default aromaFirmwareMutations
