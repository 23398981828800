/**
 * FAQ management actions
 **/
import Vue from 'vue'
import router from '@/router'

import { stringToSlug } from '@/utils/stringToSlug'
import {
  FETCH_FAQS,
  CREATE_FAQ,
  DELETE_FAQ,
  UPDATE_FAQ,
  CURRENT_FAQ_UPDATE,
  FAQ_QUESTION_UPDATE,
  FAQ_SLUG_UPDATE,
  FAQ_ANSWER_UPDATE,
  FAQ_CATEGORY_UPDATE
} from '@/states/actionTypes'
import {
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_FAQ_LIST,
  SET_CURRENT_FAQ,
  SET_FAQ_QUESTION,
  SET_FAQ_SLUG,
  SET_FAQ_ANSWER,
  SET_FAQ_CATEGORY
} from '@/states/mutationTypes'

const faqsManagementActions = {
  async [FETCH_FAQS] ({ commit, rootGetters }, params) {
    // Fetching Careers data from server api
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const faqResponses = await Vue.axios.resourceServer.get('faqs', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (faqResponses.data.data) {
        commit(SET_FAQ_LIST, faqResponses.data.data)
        commit(SET_PAGINATION_META, faqResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + faqResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [CREATE_FAQ] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const faqResponses = await Vue.axios.resourceServer
        .post(`faqs?locale=${params.locale}`, params.faq, authHeader)

      if (faqResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created FAQ successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'faqs-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + faqResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_FAQ] ({ commit, getters, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const updatedId = params.faq.id || getters['currentFAQ'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const faqResponses = await Vue.axios.resourceServer
        .put(`faqs/${updatedId}?locale=${params.locale}`, params.faq, authHeader)

      if (faqResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'faqs-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + faqResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DELETE_FAQ] ({ commit, dispatch, getters, rootGetters }, faqId) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const deleteId = faqId || getters['currentFAQ'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const faqsResponses = await Vue.axios.resourceServer
        .delete(`faqs/${deleteId}`, authHeader)

      if (faqsResponses.data.data) {
        await dispatch(FETCH_FAQS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })

        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })

        setTimeout(() => {
          router.push({ name: 'faqs-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_FAQ_UPDATE] ({ commit }, faq) {
    commit(SET_CURRENT_FAQ, faq)
  },
  [FAQ_QUESTION_UPDATE] ({ commit }, question) {
    commit(SET_FAQ_QUESTION, question)
  },
  [FAQ_ANSWER_UPDATE] ({ commit }, answer) {
    commit(SET_FAQ_ANSWER, answer)
  },
  [FAQ_CATEGORY_UPDATE] ({ commit }, category) {
    commit(SET_FAQ_CATEGORY, category)
  },
  [FAQ_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_FAQ_SLUG, stringToSlug(slug))
  }
}

export default faqsManagementActions
