<template>
  <d-container
    fluid
    class="main-content-container px-4 h-100"
  >
    <d-row class="h-100 no-gutters">
      <base-notify
        :message="notifiedMessage"
        :display="showMessage"
        :dismiss-secs="7"
        :type="messageType"
        position="top-left"
      />
      <div class="auth-form mx-auto my-auto col-md-5 col-lg-3">
        <d-card class="mb-2">
          <d-card-body>
            <img
              src="@/assets/images/aromajoin-logo-text.png"
              alt="Aromajoin Dashboard"
              class="auth-form__logo d-table mx-auto mb-4"
            >
            <h5 class="auth-form__title text-center mb-4">
              {{ $t("common.appTitle") }}
            </h5>
            <d-form @submit.prevent="login">
              <div class="form-group">
                <d-input-group
                  prepend="@"
                  class="mb-4"
                >
                  <base-input
                    id="feEmailAddress"
                    v-model="email"
                    class="form-control"
                    :placeholder="$t('signIn.emailInputHint')"
                    autocomplete="current-username"
                    type="email"
                  />
                </d-input-group>
              </div>
              <div class="form-group mb-4">
                <base-input
                  id="fePassword"
                  v-model="password"
                  type="password"
                  class="form-control"
                  :placeholder="$t('signIn.passwordInputHint')"
                  autocomplete="current-password"
                  @keyup.enter="login"
                />
              </div>
              <div class="form-group mb-3 text-center">
                <base-button
                  type="submit"
                  class="btn btn-pill btn-lg btn-accent mr-4"
                  :disabled="!validatedInputs"
                >
                  <span class="font-weight-light">
                    {{ $t("signIn.signInBtn") }}
                  </span>
                </base-button>
                <base-button
                  type="button"
                  class="btn btn-pill btn-lg btn-info"
                  :disabled="!validatedInputs"
                  @click="handleInputClear"
                >
                  <span class="font-weight-light">
                    {{ $t("signIn.clearBtn") }}
                  </span>
                </base-button>
              </div>
            </d-form>
          </d-card-body>
        </d-card>
        <div class="auth-form__meta d-table mt-4 mx-auto">
          <span class="font-weight-light">
            Forgot credentials? Please <a href="mailto:tranduchanh@aromajoin.com">contact administrator.</a>
          </span>
        </div>
      </div>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'
import {
  SIGN_IN,
  VERIFY_USER_TOKEN
} from '@/states/actionTypes'

export default {
  name: 'SignIn',
  mixins: [
    ProcessErrorMessages('User/remoteMessage')
  ],
  data () {
    return {
      email: '',
      password: ''
    }
  },
  created () {
    if (this.user) {
      this.$router.push({ name: 'home' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/user'
    }),
    validatedInputs () {
      return this.email && this.password && this.password.length >= 8
    }
  },
  methods: {
    ...mapActions({
      signIn: `User/${SIGN_IN}`,
      verifyToken: `User/${VERIFY_USER_TOKEN}`
    }),
    async login () {
      if (this.validatedInputs) {
        const token = await this.$recaptcha('login')
        if (token) {
          const verifyResponse = await this.verifyToken(token)
          if (verifyResponse) {
            // console.log('verfied: ', verifyResponse)
            await this.signIn({
              email: this.email,
              password: this.password
            })
          }
          this.email = this.password = ''
        } else {
          this.$store.commit('User/remoteMessage', 'Recaptcha invalid.')
        }
      }
    },
    handleInputClear () {
      this.email = this.password = ''
    }
  },
  page: {
    title: 'Login Dashboard',
    links: [
      { rel: 'canonical', href: 'https://admin.aromajoin.com/sign-in' }
    ]
  }
}
</script>

<style lang="scss" scoped>
.auth-form {
  min-width: 19.375rem;
  max-width: 350px;
  .card-body {
    overflow: hidden;
    -webkit-box-shadow: inset 0 4px 0 0 #17c671;
    box-shadow: inset 0 4px 0 0 #17c671;
    border-radius: .625rem;
    .btn {
      font-size: 1rem;
    }
  }
  .auth-form__logo {
    max-width: 250px;
  }
  .auth-form__meta a {
    font-weight: 400;
    color: #818ea3;
  }
}
</style>
