import Vue from 'vue'
import router from '@/router'
import { stringToSlug } from '@/utils/stringToSlug'
import {
  FETCH_AROMA_SDKS,
  CURRENT_SDK_UPDATE,
  SDK_TITLE_UPDATE,
  SDK_SLUG_UPDATE,
  SDK_DOWNLOAD_URL_UPDATE,
  SDK_OVERVIEW_UPDATE,
  SDK_INSTALL_GUIDES_UPDATE,
  CREATE_AROMA_SDK,
  UPDATE_AROMA_SDK,
  DELETE_AROMA_SDK
} from '../../actionTypes'
import {
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_AROMA_SDKS,
  SET_CURRENT_SDK,
  SET_SDK_TITLE,
  SET_SDK_SLUG,
  SET_SDK_DOWNLOAD_URL,
  SET_SDK_OVERVIEW,
  SET_SDK_INSTALL_GUIDES
} from '../../mutationTypes'

/**
 * Controller SDK management actions
 **/
const controllerSdksManagementActions = {
  async [DELETE_AROMA_SDK] ({ commit, getters, dispatch, rootGetters }, sdk) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const deletedId = sdk.id || getters['currentSDK'].id
      const sdkResponses = await Vue.axios.resourceServer
        .delete(`aroma_sdk_documents/${deletedId}`, authHeader)

      if (sdkResponses.data) {
        await dispatch(FETCH_AROMA_SDKS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete SDK successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'sdks-management' })
        }, 3500)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + sdkResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_AROMA_SDK] ({ commit, getters, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const sdk = params.sdk || getters['currentSDK']

      const sdkResponses = await Vue.axios.resourceServer
        .put(`aroma_sdk_documents/${sdk.id}?locale=${params.locale}`, { sdk }, authHeader)

      if (sdkResponses.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Update SDK successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'sdks-management' })
        }, 3500)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + sdkResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [CREATE_AROMA_SDK] ({ commit, getters, rootGetters }, params) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const sdk = params.sdk || getters['currentSDK']

      const sdkResponses = await Vue.axios.resourceServer
        .post(`aroma_sdk_documents?locale=${params.locale}`, { sdk }, authHeader)

      if (sdkResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Created SDK successfully!',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'sdks-management' })
        }, 3500)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + sdkResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [FETCH_AROMA_SDKS] ({ commit, rootGetters }, params) {
    // Fetching Careers data from server api
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const controllerSDKResponses = await Vue.axios.resourceServer.get('aroma_sdk_documents', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (controllerSDKResponses.data.data) {
        commit(SET_AROMA_SDKS, controllerSDKResponses.data.data)
        commit(SET_PAGINATION_META, controllerSDKResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + controllerSDKResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_SDK_UPDATE] ({ commit }, currentSDK) {
    commit(SET_CURRENT_SDK, currentSDK)
  },
  [SDK_TITLE_UPDATE] ({ commit }, title) {
    commit(SET_SDK_TITLE, title)
  },
  [SDK_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_SDK_SLUG, stringToSlug(slug))
  },
  [SDK_DOWNLOAD_URL_UPDATE] ({ commit }, downloadURL) {
    commit(SET_SDK_DOWNLOAD_URL, downloadURL)
  },
  [SDK_INSTALL_GUIDES_UPDATE] ({ commit }, guides) {
    commit(SET_SDK_INSTALL_GUIDES, guides)
  },
  [SDK_OVERVIEW_UPDATE] ({ commit }, overview) {
    commit(SET_SDK_OVERVIEW, overview)
  }
}

export default controllerSdksManagementActions
