<template>
  <d-container
    fluid
    class="main-content-container px-4"
  >
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4"
    >
      <d-col
        col
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">
          Dashboard
        </span>
        <h3 class="page-title">
          Website Analytics
        </h3>
      </d-col>
    </d-row>

    <d-row class="mb-4">
      <d-col
        lg="6"
        md="12"
        sm="12"
      >
        <!-- Google Sign-in button -->
        <g-signin-button
          :params="googleSignInParams"
          @success="onSignInSuccess"
          @error="onSignInError"
        >
          Sync data with Google Analytic
        </g-signin-button>
        <span class="text-muted page-subtitle">
          for latest 30 days
        </span>
      </d-col>
    </d-row>

    <!-- Small Stats Blocks -->
    <d-row>
      <d-col
        md="3"
        sm="12"
        class="mb-4"
      >
        <small-stats
          id="small-stats-timeload"
          variation="1"
          :label="pageviewStats.label"
          :value="pageviewStats.value"
        />
      </d-col>
      <d-col
        md="2"
        sm="12"
        class="mb-4"
      >
        <small-stats
          id="small-stats-timeload"
          variation="1"
          :label="timeLoadStats.label"
          :value="timeLoadStats.value"
        />
      </d-col>
      <d-col
        md="2"
        sm="12"
        class="mb-4"
      >
        <small-stats
          id="small-stats-current-users"
          variation="1"
          :label="currentUserStats.label"
          :value="currentUserStats.value"
        />
      </d-col>
      <d-col
        md="2"
        sm="12"
        class="mb-4"
      >
        <small-stats
          id="small-stats-new-visitors"
          variation="1"
          :label="newVisitorStats.label"
          :value="newVisitorStats.value"
        />
      </d-col>
      <d-col
        md="3"
        sm="12"
        class="mb-4"
      >
        <small-stats
          id="small-stats-sessions"
          variation="1"
          :label="sessionStats.label"
          :value="sessionStats.value"
        />
      </d-col>
    </d-row>

    <d-row>
      <!-- Users Overview -->
      <d-col
        lg="12"
        md="12"
        sm="12"
        class="mb-4"
      >
        <users-overview />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SmallStats from '@/components/dashboard/SmallStats.vue'
import UsersOverview from '@/components/dashboard/UsersOverview.vue'
import {
  SYNC_GA_PAGEVIEWS,
  SYNC_GA_PAGELOADTIME,
  SYNC_GA_CURRENT_USERS,
  SYNC_GA_NEW_VISITORS,
  SYNC_GA_SESSIONS,
  SYNC_GA_USER_DEVICES
} from '@/states/actionTypes'

export default {
  name: 'Dashboard',
  components: {
    SmallStats,
    UsersOverview
  },
  data () {
    return {
      googleSignInParams: {
        client_id: '659070910048-puprm5rfl1bnuu1ar9vc6jv66pvvi00t.apps.googleusercontent.com'
      }
    }
  },
  computed: {
    ...mapGetters({
      timeLoadStats: 'WebDefault/timeLoadStats',
      pageviewStats: 'WebDefault/pageviewStats',
      currentUserStats: 'WebDefault/currentUserStats',
      newVisitorStats: 'WebDefault/newVisitorStats',
      sessionStats: 'WebDefault/sessionStats',
      deviceCategoryStats: 'WebDefault/deviceCategoryStats'
    })
  },
  methods: {
    ...mapActions({
      syncGAPageviews: `WebDefault/${SYNC_GA_PAGEVIEWS}`,
      syncGAPageLoadTime: `WebDefault/${SYNC_GA_PAGELOADTIME}`,
      syncGACurrentUsers: `WebDefault/${SYNC_GA_CURRENT_USERS}`,
      syncGANewVistors: `WebDefault/${SYNC_GA_NEW_VISITORS}`,
      syncGASessions: `WebDefault/${SYNC_GA_SESSIONS}`,
      syncGAUserDevices: `WebDefault/${SYNC_GA_USER_DEVICES}`
    }),
    onSignInSuccess (googleUser) {
      /**
       * `googleUser` is the GoogleUser object that represents the just-signed-in user.
       * See https://developers.google.com/identity/sign-in/web/reference#users
       */
      const authSession = googleUser.getAuthResponse()
      if (authSession) {
        // console.log('GAuth Session:', authSession.access_token)
        // this.syncGAUserDevices(authSession.access_token)
        this.syncGAPageviews(authSession.access_token)
        this.syncGAPageLoadTime(authSession.access_token)
        this.syncGACurrentUsers(authSession.access_token)
        this.syncGANewVistors(authSession.access_token)
        this.syncGASessions(authSession.access_token)
      }
    },
    onSignInError (error) {
      console.log('Error:', error)
    }
  },
  page: {
    title: 'CMS Dashboard',
    links: [
      { rel: 'canonical', href: 'https://admin.aromajoin.com/' }
    ]
  }
}
</script>

<style lang="scss" scoped>
.g-signin-button {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
  cursor: pointer;
}
</style>
