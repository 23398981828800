<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-button-toolbar class="mb-4">
        <d-button-group>
          <d-button
            @click="() => this.isEditable = !this.isEditable"
          >
            {{ this.isEditable ? 'View' : 'Edit' }}
          </d-button>
        </d-button-group>
      </d-button-toolbar>
      <h6
        v-if="!this.isEditable"
        class="text-muted"
      >
        {{ currentEvent.title }}
      </h6>
      <d-row v-else>
        <d-col>
          <d-input
            id="feNewsTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsSlug">Slug</label>
            <d-input
              id="feNewsSlug"
              v-model="slug"
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- DESCRIPTION -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsDescription">Description</label>
            <VueTrix
              v-model="description"
              :disabled-editor="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- HIGHTLIHTED -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsHighlighted">Highlighted</label>
            <d-select
              id="feNewsHighlighted"
              v-model="highlighted"
              :disabled="!this.isEditable"
            >
              <option value="highlighted">
                Highlighted
              </option>
              <option value="secondaried">
                Secondaried
              </option>
            </d-select>
          </d-col>
        </d-form-row>
        <!-- START DATE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsStartDate">Start date</label>
            <d-datepicker
              id="feNewsStartDate"
              v-model="startDate"
              typeable
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- END DATE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsEndDate">End date</label>
            <d-datepicker
              id="feNewsEndDate"
              v-model="endDate"
              typeable
              :disabled="!this.isEditable"
            />
          </d-col>
        </d-form-row>
        <!-- IMAGE UPLOAD -->
        <d-form-row
          v-if="this.isEditable"
          class="mb-3"
        >
          <d-col>
            <label for="feNewsImageFile">
              News Image File
            </label>
            <div class="custom-file mb-3">
              <input
                id="feNewsImageFile"
                ref="feNewsImageFile"
                type="file"
                class="custom-file-input"
                @change="handlePreviewFile"
              >
              <label
                class="custom-file-label"
                for="feNewsImageFile"
              >
                Choose file...
              </label>
            </div>
          </d-col>
        </d-form-row>
        <!-- IMAGE VIEW -->
        <d-form-row
          v-if="this.isEditable"
          class="mb-3"
        >
          <d-col
            v-for="image in previewImageUrls"
            :key="image.id"
            cols="4"
          >
            <d-button
              v-if="image.id"
              outline
              pill
              theme="warning"
              size="sm"
              class="mb-2"
              @click.prevent="handleRemoveNewsImage(image.id)"
            >
              Remove
            </d-button>
            <d-img
              :src="image.url"
              fluid
              thumbnail
              rounded
              class="mb-2"
            />
          </d-col>
        </d-form-row>
        <d-form-row v-if="this.isEditable">
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleUpdateNews"
            >
              Update
            </d-button>
            <d-button
              theme="danger"
              class="mr-3"
              @click.prevent="handleDeleteNews"
            >
              Delete
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
    <d-card-footer class="text-muted">
      Updated: {{ currentEvent.updated_at | formatDate }} |
      Created: {{ currentEvent.created_at | formatDate }}
    </d-card-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'
import ReadFileAsync from '@/mixins/ReadFileAsync'
import {
  UPDATE_NEWS,
  DELETE_NEWS,
  NEWS_DESCRIPTION_UPDATE,
  NEWS_END_DATE_UPDATE,
  NEWS_HIGHLIGHTED_UPDATE,
  NEWS_IMAGE_UPDATE,
  NEWS_SLUG_UPDATE,
  NEWS_START_DATE_UPDATE,
  NEWS_TITLE_UPDATE,
  NEWS_IMAGE_REMOVE
} from '@/states/actionTypes'

export default {
  name: 'NewsView',
  components: {
    VueTrix
  },
  mixins: [
    ReadFileAsync()
  ],
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isEditable: false
    }
  },
  mounted () {
    this.isEditable = this.$route.params.action === 'edit'
  },
  computed: {
    ...mapGetters({
      currentEvent: 'NewsManagement/currentEvent'
    }),
    previewImageUrls () {
      let urls = []
      if (this.currentEvent.images && this.currentEvent.images.length > 0) {
        this.currentEvent.images.forEach((image) => {
          if (image.file && !image.deleted) {
            if (image.file.url) {
              urls.push({ id: image.id, url: image.file.url })
            } else if (typeof image.file === 'string') {
              urls.push({ id: null, url: image.file })
            }
          }
        })
      }
      return urls
    },
    title: {
      get () {
        return this.currentEvent.title
      },
      set (newTitle) {
        this.updateNewsTitle(newTitle)
        if (this.$i18n.locale === 'en') {
          this.updateNewsSlug(newTitle)
        }
      }
    },
    slug: {
      get () {
        return this.currentEvent.slug
      },
      set (newSlug) {
        this.updateNewsSlug(newSlug)
      }
    },
    description: {
      get () {
        return this.currentEvent.description
      },
      set (newDescription) {
        this.updateNewsDescription(newDescription)
      }
    },
    highlighted: {
      get () {
        return this.currentEvent.highlighted
      },
      set (newHighlighted) {
        this.updateNewsHighlighted(newHighlighted)
      }
    },
    startDate: {
      get () {
        return this.currentEvent.start_date
      },
      set (newStartDate) {
        this.updateNewsStartDate(newStartDate)
      }
    },
    endDate: {
      get () {
        return this.currentEvent.end_date
      },
      set (newEndDate) {
        this.updateNewsEndDate(newEndDate)
      }
    },
    images: {
      get () {
        return this.currentEvent.images ? this.currentEvent.images : []
      },
      set (newImage) {
        this.updateNewsImage(newImage)
      }
    }
  },
  methods: {
    ...mapActions({
      updateNews: `NewsManagement/${UPDATE_NEWS}`,
      deleteNews: `NewsManagement/${DELETE_NEWS}`,
      updateNewsTitle: `NewsManagement/${NEWS_TITLE_UPDATE}`,
      updateNewsSlug: `NewsManagement/${NEWS_SLUG_UPDATE}`,
      updateNewsDescription: `NewsManagement/${NEWS_DESCRIPTION_UPDATE}`,
      updateNewsHighlighted: `NewsManagement/${NEWS_HIGHLIGHTED_UPDATE}`,
      updateNewsStartDate: `NewsManagement/${NEWS_START_DATE_UPDATE}`,
      updateNewsEndDate: `NewsManagement/${NEWS_END_DATE_UPDATE}`,
      updateNewsImage: `NewsManagement/${NEWS_IMAGE_UPDATE}`,
      removeNewsImage: `NewsManagement/${NEWS_IMAGE_REMOVE}`
    }),
    /** Handle updating News event */
    handleUpdateNews () {
      this.updateNews({
        news: this.currentEvent,
        locale: this.$i18n.locale
      })
    },
    /** Handle deleting News event */
    handleDeleteNews () {
      let confirmDelete = confirm(`Are you sure to delete:\n ${this.currentEvent.title}?`)
      if (confirmDelete) {
        this.deleteNews(this.id)
      }
    },
    /** Handle upload image preview */
    async handlePreviewFile () {
      if (this.$refs.feNewsImageFile.files.length > 0) {
        const previewImageUrl = URL.createObjectURL(this.$refs.feNewsImageFile.files[0])
        this.previewImageUrls.push(previewImageUrl)
        const imageData = await this.readFileAsync(this.$refs.feNewsImageFile.files[0])
        this.updateNewsImage({ file: imageData })
      }
    },
    /** Handle remove uploaded image */
    handleRemoveNewsImage (imageId) {
      this.removeNewsImage(imageId)
    }
  }
}
</script>
