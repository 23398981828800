<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="text-muted">
              New Aroma Shooter firmware
            </h6>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form @submit.prevent="handleCreateFirmware">
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feName">Name (*)</label>
                  <d-input
                    id="feName"
                    v-model="name"
                    :required="true"
                    placeholder="200101-AS2"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feASVersion">AS Version (*)</label>
                  <d-input
                    id="feASVersion"
                    v-model="asVersion"
                    :required="true"
                    placeholder="ASN2A"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feVersion">FW Version (*)</label>
                  <d-input
                    id="feVersion"
                    v-model="version"
                    :required="true"
                    placeholder="1.0.0"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feDescription">Description</label>
                  <vue-trix
                    id="feDescription"
                    v-model="description"
                  />
                </d-col>
              </d-form-row>
              <!-- IMAGE UPLOAD -->
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feFirmwareFile">
                    Binary File (*)
                  </label>
                  <div class="custom-file mb-3">
                    <input
                      id="feFirmwareFile"
                      ref="feFirmwareFileRef"
                      type="file"
                      class="custom-file-input"
                      @change="handlePreviewFile"
                    >
                    <label
                      class="custom-file-label"
                      for="feFirmwareFile"
                    >
                      {{ uploadedBinary ? uploadedBinary.name : "Choose binary file..." }}
                    </label>
                  </div>
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <d-button
                    class="btn-accent mr-3"
                  >
                    Save
                  </d-button>
                </d-col>
              </d-form-row>
            </d-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueTrix from 'vue-trix'
import {
  CREATE_FIRMWARE,
  CURRENT_FIRMWARE_UPDATE,
  FIRMWARE_AS_VERSION_UPDATE,
  FIRMWARE_NAME_UPDATE,
  FIRMWARE_VERSION_UPDATE,
  FIRMWARE_DESCRIPTION_UPDATE,
  FIRMWARE_BINARY_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'FirmwareNew',
  components: {
    VueTrix
  },
  data () {
    return {
      uploadedBinary: null
    }
  },
  mounted () {
    this.updateCurrentFirmware({
      firmwareName: '',
      description: '',
      asVersion: '',
      version: '',
      binaryURL: '',
      binaryName: '',
      latest: true
    })
  },
  computed: {
    ...mapGetters({
      currentFirmware: 'AromaFirmwareManagement/currentFirmware'
    }),
    asVersion: {
      get () {
        return this.currentFirmware ? this.currentFirmware.asVersion : ''
      },
      set (newASVersion) {
        this.updateFirmwareASVersion(newASVersion)
      }
    },
    name: {
      get () {
        return this.currentFirmware ? this.currentFirmware.firmwareName : ''
      },
      set (newName) {
        this.updateFirmwareName(newName)
      }
    },
    version: {
      get () {
        return this.currentFirmware ? this.currentFirmware.version : ''
      },
      set (newVersion) {
        this.updateFirmwareVersion(newVersion)
      }
    },
    description: {
      get () {
        return this.currentFirmware ? this.currentFirmware.description : ''
      },
      set (newDesc) {
        this.updateFirmwareDescription(newDesc)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentFirmware: `AromaFirmwareManagement/${CURRENT_FIRMWARE_UPDATE}`,
      updateFirmwareASVersion: `AromaFirmwareManagement/${FIRMWARE_AS_VERSION_UPDATE}`,
      updateFirmwareName: `AromaFirmwareManagement/${FIRMWARE_NAME_UPDATE}`,
      updateFirmwareVersion: `AromaFirmwareManagement/${FIRMWARE_VERSION_UPDATE}`,
      updateFirmwareDescription: `AromaFirmwareManagement/${FIRMWARE_DESCRIPTION_UPDATE}`,
      updateFirmwareBinary: `AromaFirmwareManagement/${FIRMWARE_BINARY_UPDATE}`,
      createFirmware: `AromaFirmwareManagement/${CREATE_FIRMWARE}`
    }),
    handleCreateFirmware () {
      this.createFirmware(this.currentFirmware)
    },
    handlePreviewFile () {
      if (this.$refs.feFirmwareFileRef.files.length > 0) {
        this.uploadedBinary = this.$refs.feFirmwareFileRef.files[0]
        let binaryParams = {
          name: this.uploadedBinary.name,
          data: ''
        }
        var reader = new FileReader()
        reader.readAsDataURL(this.uploadedBinary)
        reader.onloadend = () => {
          binaryParams.data = reader.result
          this.updateFirmwareBinary(binaryParams)
        }
      }
    }
  }
}
</script>
