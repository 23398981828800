<template>
  <d-container
    fluid
  >
    <d-row>
      <!-- Main Sidebar -->
      <the-sidebar
        :items="defaultSidebarMenuItems"
      />

      <d-col
        class="main-content offset-lg-2 offset-md-3 p-0"
        tag="main"
        lg="10"
        md="9"
        sm="12"
      >
        <!-- Main Navbar -->
        <the-navbar />

        <!-- Main Content View -->
        <slot name="body" />

        <!-- Main Footer -->
        <the-footer :menu-items="defaultFooterMenuItems" />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import TheFooter from '@/components/layout/TheFooter'
import TheNavbar from '@/components/layout/TheNavbar/TheNavbar'
import TheSidebar from '@/components/layout/TheSidebar/TheSidebar'
import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    TheFooter,
    TheNavbar,
    TheSidebar
  },
  computed: {
    ...mapGetters({
      defaultSidebarMenuItems: 'WebDefault/defaultSidebarMenuItems',
      defaultFooterMenuItems: 'WebDefault/defaultFooterMenuItems'
    })
  }
}
</script>
