import contactsManagementStates from './ContactsManagementStates'
import contactsManagementGetters from './ContactsManagementGetters'
import contactsManagementMutations from './ContactsManagementMutations'
import contactsManagementActions from './ContactsManagementActions'

const contactsManagement = {
  namespaced: true,
  state: contactsManagementStates,
  getters: contactsManagementGetters,
  mutations: contactsManagementMutations,
  actions: contactsManagementActions
}

export default contactsManagement
