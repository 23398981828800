<template>
  <d-navbar-nav class="border-left flex-row">
    <li class="nav-item dropdown">
      <a
        v-d-toggle.user-actions
        class="nav-link dropdown-toggle text-nowrap pl-3 pr-3"
      >
        <d-img
          class="user-avatar rounded-circle mr-2"
          alt="User Avatar"
          :src="avatarUrl"
        />
        <span
          class="d-none d-md-inline-block"
          v-if="this.user"
        >
          {{ displayName }}
        </span>
      </a>
      <d-collapse
        id="user-actions"
        class="dropdown-menu dropdown-menu-small"
      >
        <d-dropdown-item
          href="/user-profile"
        >
          <i class="material-icons">
            &#xE7FD;
          </i> {{ $t("profileMenu.profileTitle") }}
        </d-dropdown-item>
        <d-dropdown-item
          href="/account-setting"
        >
          <i class="material-icons">
            repeat
          </i> {{ $t("profileMenu.accountSettingTitle") }}
        </d-dropdown-item>
        <d-dropdown-divider />
        <d-dropdown-item
          href="/sign-out"
          class="text-danger"
        >
          <i class="material-icons text-danger">
            &#xE879;
          </i> {{ $t("profileMenu.logoutTitle") }}
        </d-dropdown-item>
      </d-collapse>
    </li>
  </d-navbar-nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavbarNav',
  props: {
    user: {
      type: Object,
      required: false,
      default () {
        return { email: '' }
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'User/profile'
    }),
    avatarUrl () {
      return require('@/assets/images/avatars/no-user.png')
    },
    displayName () {
      if (this.profile) {
        return this.profile.name
      } else {
        return this.user.email
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  font-size: 16px;
  padding-top: 0.8rem;
  &:hover {
    cursor: pointer;
  }
}
</style>
