import WebDefaultStates from './WebDefaultStates'
import WebDefaultGetters from './WebDefaultGetters'
import WebDefaultMutations from './WebDefaultMutations'
import WebDefaultActions from './WebDefaultActions'

const WebDefault = {
  namespaced: true,
  state: WebDefaultStates,
  getters: WebDefaultGetters,
  mutations: WebDefaultMutations,
  actions: WebDefaultActions
}

export default WebDefault
