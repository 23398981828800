/**
 * Aroma Firmware management actions
 */
import Vue from 'vue'
import router from '@/router'
import {
  SET_REMOTE_MESSAGE,
  SET_FIRMWARE_LIST,
  SET_CURRENT_FIRMWARE,
  SET_FIRMWARE_LATEST,
  SET_FIRMWARE_AS_VERSION,
  SET_FIRMWARE_BINARY,
  SET_FIRMWARE_DESCRIPTION,
  SET_FIRMWARE_NAME,
  SET_FIRMWARE_VERSION
} from '../../mutationTypes'
import {
  DOWNLOAD_FIRMWARE,
  CREATE_FIRMWARE,
  DELETE_FIRMWARE,
  FETCH_FIRMWARE_LIST,
  UPDATE_FIRMWARE,
  CURRENT_FIRMWARE_UPDATE,
  FIRMWARE_LATEST_UPDATE,
  FIRMWARE_AS_VERSION_UPDATE,
  FIRMWARE_NAME_UPDATE,
  FIRMWARE_VERSION_UPDATE,
  FIRMWARE_DESCRIPTION_UPDATE,
  FIRMWARE_BINARY_UPDATE
} from '../../actionTypes'

const aromaFirmwareActions = {
  async [FETCH_FIRMWARE_LIST] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      if (currentAccount) {
        const firmwareListResponse = await Vue.axios.serverlessServer.get('ota/firmwares?debug=true&locale=en')
        if (firmwareListResponse.status === 200 && firmwareListResponse.data.status) {
          commit(SET_FIRMWARE_LIST, firmwareListResponse.data.data.data)
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_FIRMWARE] ({ commit, rootGetters }, firmware) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        const firmwareResponse = await Vue.axios.serverlessServer.put(`ota/firmwares/${firmware.firmwareId}?debug=true&locale=en`, { firmware })
        if (firmwareResponse.status === 200 && firmwareResponse.data.status) {
          commit(SET_CURRENT_FIRMWARE, firmwareResponse.data.data.data)
          commit(SET_REMOTE_MESSAGE, {
            message: 'Update firmware successfully',
            type: 'success'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [CREATE_FIRMWARE] ({ commit, rootGetters }, firmware) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        const firmwareResponse = await Vue.axios.serverlessServer.post('ota/firmwares', firmware, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (firmwareResponse.status === 200 && firmwareResponse.data.status) {
          if (firmwareResponse.data.data && firmwareResponse.data.data.success) {
            commit(SET_REMOTE_MESSAGE, {
              message: 'Create firmware successfully',
              type: 'success'
            })

            setTimeout(() => {
              router.push({
                path: '/aroma-shooter-firmware'
              })
            }, 3000)
          } else {
            commit(SET_REMOTE_MESSAGE, {
              message: `Error: ${firmwareResponse.data.data.data}`,
              type: 'danger'
            })
          }
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: creating new firmware version failed.',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DELETE_FIRMWARE] ({ commit, rootGetters, dispatch }, firmware) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        const firmwareResponse = await Vue.axios.serverlessServer.delete(`ota/firmwares/${firmware.firmwareId}?version=${firmware.version}&debug=true&locale=en`)
        if (firmwareResponse.status === 200 && firmwareResponse.data.status) {
          await dispatch(FETCH_FIRMWARE_LIST)
          commit(SET_REMOTE_MESSAGE, {
            message: 'Delete firmware successfully',
            type: 'success'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DOWNLOAD_FIRMWARE] ({ commit, rootGetters }, firmware) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        const firmwareResponse = await Vue.axios.serverlessServer.get(`ota/firmwares/${firmware.firmwareId}/download?debug=true&locale=en&version=${firmware.version}`)
        if (firmwareResponse.status === 200) {
          window.open(`${firmwareResponse.data}`)
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_FIRMWARE_UPDATE] ({ commit }, firmware) {
    commit(SET_CURRENT_FIRMWARE, firmware)
  },
  [FIRMWARE_LATEST_UPDATE] ({ commit }, latestFirmware) {
    commit(SET_FIRMWARE_LATEST, latestFirmware)
  },
  [FIRMWARE_AS_VERSION_UPDATE] ({ commit }, asVersion) {
    commit(SET_FIRMWARE_AS_VERSION, asVersion)
  },
  [FIRMWARE_NAME_UPDATE] ({ commit }, name) {
    commit(SET_FIRMWARE_NAME, name)
  },
  [FIRMWARE_DESCRIPTION_UPDATE] ({ commit }, description) {
    commit(SET_FIRMWARE_DESCRIPTION, description)
  },
  [FIRMWARE_BINARY_UPDATE] ({ commit }, binary) {
    commit(SET_FIRMWARE_BINARY, binary)
  },
  [FIRMWARE_VERSION_UPDATE] ({ commit }, version) {
    commit(SET_FIRMWARE_VERSION, version)
  }
}

export default aromaFirmwareActions
