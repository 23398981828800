<template>
  <tr @click="handleOperations('view')">
    <td class="order__id">
      {{ orderItem.orderId.split('-')[0] }}...
    </td>
    <td>
      {{ orderItem.orderState }}
    </td>
    <td>
      {{ orderItem.customer.email }}
    </td>
    <td>
      {{ orderItem.totalChargeAmount | toJPYCurrency }}
    </td>
    <td>
      {{ orderItem.paymentSource.toUpperCase() }}
    </td>
    <td>
      {{ orderItem.updatedAt | formatDate }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          class="btn-white"
          @click="handleOperations('view')"
        >
          <i class="material-icons">
            credit_card
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperations('edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_ORDER_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'OrderItem',
  props: {
    orderItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentOrder: `OrdersManagement/${CURRENT_ORDER_UPDATE}`
    }),
    handleOperations (type) {
      /** Select current order object */
      this.updateCurrentOrder(this.orderItem)

      /** Do news record opertions */
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'view-orders',
            params: { id: this.orderItem.orderId.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'view-orders',
            params: { id: this.orderItem.orderId.toString(), action: 'edit' }
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .order__id {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
