/** VueRouter store declaration */
import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress/nprogress'

import routes from './routes'
import store from '../states'
import { USER_KEY } from '@/constants/localStorages'
import { SET_USER } from '../states/mutationTypes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_ROUTE || '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  fallback: true,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  }
})

//  navigation guard for authentication
router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load
  if (routeFrom.name !== null) {
    // Start the route progress bar
    NProgress.start()
  }

  // Check if auth is required on this route
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next()

  const currentUser = store.getters['User/user']
  const authenticatedUser = JSON.parse(localStorage.getItem(USER_KEY)) || currentUser
  let isAuthorized = false
  if (authenticatedUser) {
    isAuthorized = authenticatedUser.user_type === 'super_admin' || authenticatedUser.user_type === 'administrator'
  }

  // If auth is required and the user is logged in...
  if (authRequired && authenticatedUser && isAuthorized) {
    // Reset user's states
    if (!store.getters['User/user']) {
      store.commit(`User/${SET_USER}`, authenticatedUser)
    }
    return next()
  } else {
    // If auth is required and the user is NOT currently logged in...
    return next({ name: 'sign-in' })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
