/**
 * Members management mutations
 **/
import {
  SET_MEMBERS,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

const membersManagementMutations = {
  [SET_MEMBERS]: (state, members) => {
    state.members = members
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default membersManagementMutations
