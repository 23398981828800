<template>
  <tr>
    <td>
      {{ cartridgeItem.serial }}
    </td>
    <td>
      {{ $i18n.locale == 'ja' ? cartridgeItem.jname : cartridgeItem.ename }}
    </td>
    <td v-if="cartridgeItem.stock_location">
      {{ cartridgeItem.stock_location.location }}
    </td>
    <td v-else>
      no location
    </td>
    <td>
      {{ cartridgeItem.active }}
    </td>
    <td>
      {{ cartridgeItem.quantity }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group">
        <d-button
          class="btn-white"
          @click="handleOperations('edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperations('view')"
        >
          <i class="material-icons">
            description
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_CARTRIDGE_UPDATE,
  DELETE_CARTRIDGE
} from '@/states/actionTypes'

export default {
  name: 'CartridgeItem',
  props: {
    cartridgeItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentCartridge: `CartridgesManagement/${CURRENT_CARTRIDGE_UPDATE}`,
      deleteCartridge: `CartridgesManagement/${DELETE_CARTRIDGE}`
    }),
    handleOperations (type) {
      /** Select current cartridge object */
      this.updateCurrentCartridge(this.cartridgeItem)

      /** Perform cartridge record operations */
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'cartridge-details',
            params: { id: this.cartridgeItem.id.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'cartridge-details',
            params: { id: this.cartridgeItem.id.toString(), action: 'edit' }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
