<template>
  <tr>
    <td class="text-muted">
      {{ user.id }}
    </td>
    <td @click="handleOperations('view')">
      {{ user.email }}
    </td>
    <td>
      {{ user.user_type | transformSnakeCase }}
    </td>
    <td>
      {{ user.updated_at | formatDate }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          v-if="user.user_type === 'ordinary_user'"
          class="btn-white"
          @click="handlePromoteAdministrator"
        >
          <i class="material-icons">
            arrow_upward
          </i>
        </d-button>
        <d-button
          v-else-if="user.user_type === 'administrator'"
          class="btn-white"
          @click="handleDegradeOrdinaryUser"
        >
          <i class="material-icons">
            arrow_downward
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperations('edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperations('delete')"
        >
          <i class="material-icons">
            delete_sweep
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  PROMOTE_TO_ADMINISTRATOR,
  DEGRADE_TO_ORDINARY
} from '@/states/actionTypes'

export default {
  name: 'UserItem',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      promoteToAdmin: `UsersManagement/${PROMOTE_TO_ADMINISTRATOR}`,
      degradeToOrdinary: `UsersManagement/${DEGRADE_TO_ORDINARY}`
    }),
    handleOperations (type) {
      // Do user record opertions
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'user-details',
            params: { id: this.user.id.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'user-details',
            params: { id: this.user.id.toString(), action: 'edit' }
          })
          break
        case 'delete':
          alert(`Are you sure to delete ${this.user.email}?`)
          break
        default:
          break
      }
    },
    handlePromoteAdministrator () {
      if (confirm(`Are you sure you want to promote this user [${this.user.email}] to admin?`)) {
        this.promoteToAdmin(this.user.id)
      }
    },
    handleDegradeOrdinaryUser () {
      if (confirm(`Are you sure you want to degrade this user [${this.user.email}] to ordinary account?`)) {
        this.degradeToOrdinary(this.user.id)
      }
    }
  }
}
</script>
