<template>
  <d-row>
    <d-col>
      <d-form @submit.prevent="handleUpdatingAccount">
        <d-form-row>
          <!-- Email -->
          <d-col
            class="form-group"
          >
            <label for="feInputEmail">
              Email
            </label>
            <d-form-input
              type="text"
              id="feInputEmail"
              placeholder="Your current email"
            />
          </d-col>
        </d-form-row>
        <d-form-row>
          <!-- Current Password -->
          <d-col
            class="form-group"
          >
            <label for="feInputCurrentPass">
              Current password
            </label>
            <d-form-input
              type="password"
              id="feInputCurrentPass"
              placeholder="Need your current password to confirm your changes"
              autocomplete="current-password"
            />
          </d-col>
        </d-form-row>
        <d-form-row>
          <!-- New Password -->
          <d-col
            class="form-group"
          >
            <label for="feInputNewPass">
              New password
            </label>
            <d-form-input
              type="password"
              id="feInputNewPass"
              placeholder="Leave blank if you don't want to change it"
            />
          </d-col>
        </d-form-row>
        <d-form-row>
          <!-- Confirm Password -->
          <d-col
            class="form-group"
          >
            <label for="feInputConfirmPass">
              Confirm password
            </label>
            <d-form-input
              type="password"
              id="feInputConfirmPass"
              placeholder="Make sure your new password again"
            />
          </d-col>
        </d-form-row>
        <d-button
          type="submit"
          class="btn-accent"
        >
          Update Account
        </d-button>
      </d-form>
    </d-col>
  </d-row>
</template>

<script>
export default {
  name: 'AccountForm',
  methods: {
    handleUpdatingAccount () {
      alert('Submitting new account info!')
    }
  }
}
</script>
