/**
 * Application routes
 */
const routes = [
  {
    path: '/',
    redirect: '/sign-in',
    meta: {
      authRequired: false,
      layout: 'unauthorized'
    }
  },
  {
    path: '/dashboard',
    name: 'home',
    component: () => lazyLoadView(import(/* webpackChunkName: "VDashboard" */ '@/views/Dashboard')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/users',
    component: () => lazyLoadView(import(/* webpackChunkName: "VUsers" */ '@/views/Users')),
    children: [
      {
        path: '',
        name: 'users-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VUsersIndex" */ '@/components/users/UsersIndex')),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'new',
        name: 'user-new',
        component: () => lazyLoadView(import(/* webpackChunkName: "VUserNew" */ '@/components/users/UserNew')),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'details/:id',
        name: 'user-details',
        component: () => lazyLoadView(import(/* webpackChunkName: "VUserView" */ '@/components/users/UserView')),
        meta: {
          authRequired: true
        },
        props: route => ({ id: route.params.id })
      }
    ],
    meta: {
      authRequired: true
    }
  },
  {
    path: '/audit-logs',
    component: () => lazyLoadView(import(/* webpackChunkName: "VAromaAudits" */ '@/views/AromaAudits')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'audit-logs-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAuditsIndex" */ '@/components/aroma-audits/AuditsIndex'))
      },
      {
        path: 'details/:id',
        name: 'view-audit',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAuditView" */ '@/components/aroma-audits/AuditView'))
      }
    ]
  },
  {
    path: '/aroma-shooters',
    component: () => lazyLoadView(import(/* webpackChunkName: "VAromaShooters" */ '@/views/AromaShooters')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'aroma-shooters-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VShootersIndex" */ '@/components/aroma-shooters/ShootersIndex'))
      },
      {
        path: 'new',
        name: 'create-aroma-shooter',
        component: () => lazyLoadView(import(/* webpackChunkName: "VShooterCreate" */ '@/components/aroma-shooters/ShooterCreate'))
      },
      {
        path: 'details/:id',
        name: 'shooter-details',
        component: () => lazyLoadView(import(/* webpackChunkName: "VShooterView" */ '@/components/aroma-shooters/ShooterView')),
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/aroma-cartridges',
    component: () => lazyLoadView(import(/* webpackChunkName: "VAromaCartridges" */ '@/views/AromaCartridges')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'aroma-cartridges-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCartridgesIndex" */ '@/components/aroma-cartridges/CartridgesIndex'))
      },
      {
        path: 'new',
        name: 'new-cartridge',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCartridgeNew" */ '@/components/aroma-cartridges/CartridgeNew')),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'details/:id',
        name: 'cartridge-details',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCartridgeView" */ '@/components/aroma-cartridges/CartridgeView')),
        meta: {
          authRequired: true
        },
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/aroma-shooter-firmware',
    component: () => lazyLoadView(import(/* webpackChunkName: "VAromaShooterFirmware" */ '@/views/AromaShooterFirmware')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'aroma-shooter-firmware-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VFirmwareIndex" */ '@/components/aroma-shooter-firmware/FirmwareIndex'))
      },
      {
        path: 'create',
        name: 'create-aroma-shooter-firmware',
        component: () => lazyLoadView(import(/* webpackChunkName: "VFirmwareNew" */ '@/components/aroma-shooter-firmware/FirmwareNew'))
      }
    ]
  },
  {
    path: '/apps',
    component: () => lazyLoadView(import(/* webpackChunkName: "VApplications" */ '@/views/Applications')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'apps-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaApplicationIndex" */ '@/components/aroma-applications/AromaApplicationIndex'))
      },
      {
        path: ':id',
        name: 'view-aroma-app',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaApplicationView" */ '@/components/aroma-applications/AromaApplicationView'))
      },
      {
        path: 'create',
        name: 'create-aroma-app',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaApplicationCreate" */ '@/components/aroma-applications/AromaApplicationCreate'))
      }
    ]
  },
  {
    path: '/sdks',
    component: () => lazyLoadView(import(/* webpackChunkName: "VSDKs" */ '@/views/SDKs')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'sdks-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaSdkIndex" */ '@/components/aroma-sdks/AromaSdkIndex'))
      },
      {
        path: ':id',
        name: 'view-aroma-sdk',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaSdkView" */ '@/components/aroma-sdks/AromaSdkView'))
      },
      {
        path: 'create',
        name: 'create-aroma-sdk',
        component: () => lazyLoadView(import(/* webpackChunkName: "VAromaSdkCreate" */ '@/components/aroma-sdks/AromaSdkCreate'))
      }
    ]
  },
  {
    path: '/subscribers',
    component: () => lazyLoadView(import(/* webpackChunkName: "VSubscribers" */ '@/views/Subscribers')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'subscribers-management',
        component: () => lazyLoadView(import(/* */ '@/components/subscribers/SubscriberIndex'))
      }
    ]
  },
  {
    path: '/contacts',
    component: () => lazyLoadView(import(/* webpackChunkName: "VContacts" */ '@/views/Contacts')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'contacts-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VContactIndex" */ '@/components/contacts/ContactIndex'))
      },
      {
        path: ':id',
        name: 'view-contact',
        component: () => lazyLoadView(import(/* webpackChunkName: "VContactView" */ '@/components/contacts/ContactView')),
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/faqs',
    component: () => lazyLoadView(import(/* webpackChunkName: "VFAQs" */ '@/views/FAQs')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'faqs-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VFAQIndex" */ '@/components/faq/FAQIndex'))
      },
      {
        path: 'create',
        name: 'create-faq',
        component: () => lazyLoadView(import(/* webpackChunkName: "VFAQCreate" */ '@/components/faq/FAQCreate')),
        meta: {
          authRequired: true
        }
      },
      {
        path: ':id',
        name: 'view-faq',
        component: () => lazyLoadView(import(/* webpackChunkName: "VFAQView" */ '@/components/faq/FAQView')),
        meta: {
          authRequired: true
        },
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/members',
    name: 'members-management',
    component: () => lazyLoadView(import(/* webpackChunkName: "VMembers" */ '@/views/Members')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/news',
    component: () => lazyLoadView(import(/* webpackChunkName: "VNews" */ '@/views/News')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'news-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VNewsIndex" */ '@/components/news/NewsIndex'))
      },
      {
        path: 'create',
        name: 'create-news',
        component: () => lazyLoadView(import(/* webpackChunkName: "VNewsCreate" */ '@/components/news/NewsCreate')),
        meta: {
          authRequired: true
        }
      },
      {
        path: ':id',
        name: 'view-news',
        component: () => lazyLoadView(import(/* webpackChunkName: "VNewsView" */ '@/components/news/NewsView')),
        meta: {
          authRequired: true
        },
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/careers',
    component: () => lazyLoadView(import(/* webpackChunkName: "VCareers" */ '@/views/Careers')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'careers-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCareersIndex" */ '@/components/careers/CareersIndex'))
      },
      {
        path: 'new',
        name: 'new-career',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCareerNew" */ '@/components/careers/CareerNew')),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'details/:id',
        name: 'career-details',
        component: () => lazyLoadView(import(/* webpackChunkName: "VCareerView" */ '@/components/careers/CareerView')),
        meta: {
          authRequired: true
        },
        props: route => ({ id: route.params.id })
      }
    ]
  },
  {
    path: '/orders',
    component: () => lazyLoadView(import(/* webpackChunkName: "VOrders" */ '@/views/Orders')),
    meta: {
      authRequired: true
    },
    children: [
      {
        path: '',
        name: 'orders-management',
        component: () => lazyLoadView(import(/* webpackChunkName: "VOrdersIndex" */ '@/components/orders/OrdersIndex')),
        meta: {
          authRequired: true
        }
      },
      {
        path: 'create',
        name: 'create-orders',
        component: () => lazyLoadView(import(/* webpackChunkName: "VOrdersCreate" */ '@/components/orders/OrdersCreate')),
        meta: {
          authRequired: true
        }
      },
      {
        path: ':id',
        name: 'view-orders',
        component: () => lazyLoadView(import(/* webpackChunkName: "VOrdersView" */ '@/components/orders/OrdersView')),
        props: route => ({ id: route.params.id }),
        meta: {
          authRequired: true
        }
      }
    ]
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => lazyLoadView(import(/* webpackChunkName: "VSignIn" */ '@/views/SignIn')),
    meta: {
      authRequired: false,
      layout: 'unauthorized'
    }
  },
  {
    path: '/sign-out',
    name: 'sign-out',
    component: () => lazyLoadView(import(/* webpackChunkName: "VSignOut" */ '@/views/SignOut')),
    meta: {
      authRequired: true,
      layout: 'unauthorized'
    }
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => lazyLoadView(import(/* webpackChunkName: "VUserProfile" */ '@/views/UserProfile')),
    meta: {
      authRequired: true,
      layout: 'default'
    }
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => lazyLoadView(import(/* webpackChunkName: "VAccountSetting" */ '@/views/AccountSetting')),
    meta: {
      authRequired: true,
      layout: 'default'
    }
  },
  {
    path: '/404',
    name: '404',
    component: require('@/views/_NotFound').default,
    meta: {
      layout: 'unauthorized'
    },
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404'
  }
]

/**
 * Lazy-loads view components, but with better UX. A loading view
 * will be used if the component takes a while to load, falling
 * back to a timeout view in case the page fails to load. You can
 * use this component to lazy-load a route with:
 *
 * component: () => lazyLoadView(import('@/views/my-view))
 *
 * @param {*} AsyncView
 */
function lazyLoadView (AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@/views/_Loading').default,
    delay: 400,
    error: require('@/views/_Timeout').default,
    timeout: 10000
  })

  return Promise.resolve({
    functional: true,
    render (h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
}

export default routes
