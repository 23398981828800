/**
 * Careers management actions
 **/
import Vue from 'vue'
import router from '@/router'
import { stringToSlug } from '@/utils/stringToSlug'

import {
  FETCH_CAREERS,
  CURRENT_CAREER_UPDATE,
  CREATE_CAREER,
  UPDATE_CAREER,
  DELETE_CAREER,
  CAREER_TITLE_UPDATE,
  CAREER_DEADLINE_UPDATE,
  CAREER_SLUG_UPDATE,
  CAREER_REQUIREMENTS_UPDATE,
  CAREER_DESCRIPTION_UPDATE,
  CAREER_BENEFITS_UPDATE,
  CAREER_CONDITIONS_UPDATE,
  CAREER_LOCATION_UPDATE
} from '@/states/actionTypes'
import {
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_CAREER_LIST,
  SET_CURRENT_CAREER,
  SET_CAREER_TITLE,
  SET_CAREER_DEADLINE,
  SET_CAREER_SLUG,
  SET_CAREER_REQUIREMENTS,
  SET_CAREER_DESCRIPTION,
  SET_CAREER_BENEFITS,
  SET_CAREER_CONDITIONS,
  SET_CAREER_LOCATION
} from '@/states/mutationTypes'

const careersManagementActions = {
  async [FETCH_CAREERS] ({ commit, rootGetters }, params) {
    // Fetching Careers data from server api
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const appResponses = await Vue.axios.resourceServer.get('recruitments', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: currentAccount.authentication_token
        }
      })
      if (appResponses.data.data) {
        commit(SET_CAREER_LIST, appResponses.data.data)
        commit(SET_PAGINATION_META, appResponses.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + appResponses.data.error,
          type: 'danger'
        })
      }
      // console.log(shooterResponses)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Create job listing data by invoking API */
  async [CREATE_CAREER] ({ commit, rootGetters }, params) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      // must stringify the career object before we use it as our API request body
      await Vue.axios.resourceServer.post(
        `recruitments?locale=${params.locale}`,
        { career: params.career },
        {
          headers: {
            Authorization: currentAccount.authentication_token
          }
        }
      )
      commit(SET_REMOTE_MESSAGE, {
        message: 'Created job listing successfully!',
        type: 'success'
      })
      // after creating a new career, the user will be redirected to the careers index
      setTimeout(function () {
        router.push({
          path: '/careers'
        })
      }, 3000)
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Update Career data by invoking API */
  async [UPDATE_CAREER] ({ commit, getters, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      const updatedId = getters['currentCareer'].id || params.id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const careerResponses = await Vue.axios.resourceServer.put(
        `recruitments/${updatedId}?locale=${params.locale}`,
        { career: params.career },
        authHeader
      )
      if (careerResponses.data.data) {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Updated job listing successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'careers-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + careerResponses.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  /** Delete Career data by invoking API */
  async [DELETE_CAREER] ({ commit, dispatch, getters, rootGetters }, id) {
    // Reset error messages
    commit(SET_REMOTE_MESSAGE, null)
    try {
      const currentAccount = rootGetters['User/user']
      const deletedId = id || getters['currentCareer'].id
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }

      const careerResponses = await Vue.axios.resourceServer
        .delete(`recruitments/${deletedId}`, authHeader)

      if (careerResponses.data.data) {
        await dispatch(FETCH_CAREERS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Deleted successfully.',
          type: 'success'
        })
        setTimeout(() => {
          router.push({ name: 'careers-management' })
        }, 3000)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Delete failed!',
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_CAREER_UPDATE] ({ commit }, career) {
    commit(SET_CURRENT_CAREER, career)
  },
  [CAREER_TITLE_UPDATE] ({ commit }, title) {
    commit(SET_CAREER_TITLE, title)
  },
  [CAREER_DEADLINE_UPDATE] ({ commit }, deadline) {
    commit(SET_CAREER_DEADLINE, deadline)
  },
  [CAREER_SLUG_UPDATE] ({ commit }, slug) {
    commit(SET_CAREER_SLUG, stringToSlug(slug))
  },
  [CAREER_REQUIREMENTS_UPDATE] ({ commit }, requirements) {
    commit(SET_CAREER_REQUIREMENTS, requirements)
  },
  [CAREER_DESCRIPTION_UPDATE] ({ commit }, description) {
    commit(SET_CAREER_DESCRIPTION, description)
  },
  [CAREER_BENEFITS_UPDATE] ({ commit }, benefits) {
    commit(SET_CAREER_BENEFITS, benefits)
  },
  [CAREER_CONDITIONS_UPDATE] ({ commit }, conditions) {
    commit(SET_CAREER_CONDITIONS, conditions)
  },
  [CAREER_LOCATION_UPDATE] ({ commit }, location) {
    commit(SET_CAREER_LOCATION, location)
  }
}

export default careersManagementActions
