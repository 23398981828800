/** Users management actions */
import Vue from 'vue'
import {
  FETCH_USERS,
  PROMOTE_TO_ADMINISTRATOR,
  DEGRADE_TO_ORDINARY
} from '../../actionTypes'
import {
  SET_USERS,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

const usersManagementActions = {
  async [FETCH_USERS] ({ commit, rootGetters }, params) {
    // Fetching users from server api
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const account = rootGetters['User/user']
      const response = await Vue.axios.resourceServer.get('users', {
        params: {
          'locale': params.locale,
          'page[number]': params.page,
          'page[size]': params.size
        },
        headers: {
          Authorization: account.authentication_token
        }
      })
      if (response.data.data) {
        commit(SET_USERS, response.data.data)
        commit(SET_PAGINATION_META, response.data.meta)
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + response.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.log(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [PROMOTE_TO_ADMINISTRATOR] ({ commit, getters, dispatch, rootGetters }, userId) {
    console.log('Promote user to administrator')
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const response = await Vue.axios.resourceServer.put(`users/${userId}/promote_to_administrator`, {}, authHeader)
      if (response.data.data) {
        await dispatch(FETCH_USERS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Promote user successfully!',
          type: 'success'
        })
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + response.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [DEGRADE_TO_ORDINARY] ({ commit, getters, dispatch, rootGetters }, userId) {
    console.log('Degrade user to ordinary role')
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      const authHeader = {
        headers: {
          Authorization: currentAccount.authentication_token
        }
      }
      const response = await Vue.axios.resourceServer.put(`users/${userId}/degrade_to_ordinary_user`, {}, authHeader)
      if (response.data.data) {
        await dispatch(FETCH_USERS, {
          page: getters['paginationMeta'].current,
          size: getters['paginationMeta'].limit
        })
        commit(SET_REMOTE_MESSAGE, {
          message: 'Degrade user successfully!',
          type: 'success'
        })
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: ' + response.data.error,
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  }
}

export default usersManagementActions
