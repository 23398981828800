import auditStates from './AuditStates'
import auditGetters from './AuditGetters'
import auditMutations from './AuditMutations'
import auditActions from './AuditActions'

const auditManagement = {
  namespaced: true,
  state: auditStates,
  getters: auditGetters,
  mutations: auditMutations,
  actions: auditActions
}

export default auditManagement
