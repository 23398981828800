/** Aroma Firmware management getters */

const aromaFirmwareGetters = {
  firmwares: state => state.firmwares,
  currentFirmware: state => state.currentFirmware,
  paginationMeta: state => state.paginationMeta,
  remoteMessage: state => state.remoteMessage,
  latestFirmware: (state) => {
    return state.firmwares.find((firmware) => {
      return firmware.latest
    })
  }
}

export default aromaFirmwareGetters
