/**
 * Admin User getters
 */
const userGetters = {
  user: state => state.user,
  profile: state => state.profile,
  remoteMessage: state => state.remoteMessage
}

export default userGetters
