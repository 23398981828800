import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true
  }
})
