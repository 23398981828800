<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-row>
        <d-col>
          <label for="feNewsTitle">Title</label>
          <d-input
            id="feNewsTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsSlug">Slug</label>
            <d-input
              id="feNewsSlug"
              v-model="slug"
            />
          </d-col>
        </d-form-row>
        <!-- DESCRIPTION -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsDescription">Description</label>
            <VueTrix
              v-model="description"
            />
          </d-col>
        </d-form-row>
        <!-- HIGHTLIHTED -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsHighlighted">Highlighted</label>
            <d-select
              id="feNewsHighlighted"
              v-model="highlighted"
            >
              <option value="highlighted">
                Highlighted
              </option>
              <option value="secondaried">
                Secondaried
              </option>
            </d-select>
          </d-col>
        </d-form-row>
        <!-- START DATE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsStartDate">Start date</label>
            <d-datepicker
              id="feNewsStartDate"
              v-model="startDate"
              typeable
            />
          </d-col>
        </d-form-row>
        <!-- END DATE -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feNewsEndDate">End date</label>
            <d-datepicker
              id="feNewsEndDate"
              v-model="endDate"
              typeable
            />
          </d-col>
        </d-form-row>
        <!-- IMAGE UPLOAD -->
        <d-form-row
          class="mb-3"
        >
          <d-col>
            <label for="feNewsImageFile">
              Upload image
            </label>
            <div class="custom-file mb-3">
              <input
                type="file"
                class="custom-file-input"
                id="feNewsImageFile"
                ref="feNewsImageFile"
                @change="handlePreviewFile"
              >
              <label
                class="custom-file-label"
                for="feNewsImageFile"
              >
                Choose file...
              </label>
            </div>
          </d-col>
        </d-form-row>
        <!-- IMAGE VIEW -->
        <d-form-row
          class="mb-3"
        >
          <d-col>
            <d-img
              :src="previewImageUrl"
              fluid
              rounded
              center
            />
          </d-col>
        </d-form-row>
        <d-form-row>
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleCreateNews"
            >
              Create
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
    <d-card-footer class="text-muted" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'

import {
  CREATE_NEWS,
  CURRENT_NEWS_UPDATE,
  NEWS_DESCRIPTION_UPDATE,
  NEWS_END_DATE_UPDATE,
  NEWS_HIGHLIGHTED_UPDATE,
  NEWS_IMAGE_UPDATE,
  NEWS_SLUG_UPDATE,
  NEWS_START_DATE_UPDATE,
  NEWS_TITLE_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'NewsCreate',
  components: {
    VueTrix
  },
  data () {
    return {
      previewImageUrl: ''
    }
  },
  created () {
    this.updateCurrentEvent({
      title: 'New Event',
      slug: '',
      description: '',
      highlighted: 'highlighted',
      start_date: new Date(),
      end_date: new Date(),
      images: null
    })
  },
  computed: {
    ...mapGetters({
      currentEvent: 'NewsManagement/currentEvent'
    }),
    title: {
      get () {
        return this.currentEvent.title
      },
      set (newTitle) {
        this.updateNewsTitle(newTitle)
        if (this.$i18n.locale === 'en') {
          this.updateNewsSlug(newTitle)
        }
      }
    },
    slug: {
      get () {
        return this.currentEvent.slug
      },
      set (newSlug) {
        this.updateNewsSlug(newSlug)
      }
    },
    description: {
      get () {
        return this.currentEvent.description
      },
      set (newDescription) {
        this.updateNewsDescription(newDescription)
      }
    },
    highlighted: {
      get () {
        return this.currentEvent.highlighted
      },
      set (newHighlighted) {
        this.updateNewsHighlighted(newHighlighted)
      }
    },
    startDate: {
      get () {
        return this.currentEvent.start_date
      },
      set (newStartDate) {
        this.updateNewsStartDate(newStartDate)
      }
    },
    endDate: {
      get () {
        return this.currentEvent.end_date
      },
      set (newEndDate) {
        this.updateNewsEndDate(newEndDate)
      }
    },
    images: {
      get () {
        return this.currentEvent.images ? this.currentEvent.images : []
      },
      set (newImage) {
        this.updateNewsImage(newImage)
      }
    }
  },
  methods: {
    ...mapActions({
      createNews: `NewsManagement/${CREATE_NEWS}`,
      updateCurrentEvent: `NewsManagement/${CURRENT_NEWS_UPDATE}`,
      updateNewsTitle: `NewsManagement/${NEWS_TITLE_UPDATE}`,
      updateNewsSlug: `NewsManagement/${NEWS_SLUG_UPDATE}`,
      updateNewsDescription: `NewsManagement/${NEWS_DESCRIPTION_UPDATE}`,
      updateNewsHighlighted: `NewsManagement/${NEWS_HIGHLIGHTED_UPDATE}`,
      updateNewsStartDate: `NewsManagement/${NEWS_START_DATE_UPDATE}`,
      updateNewsEndDate: `NewsManagement/${NEWS_END_DATE_UPDATE}`,
      updateNewsImage: `NewsManagement/${NEWS_IMAGE_UPDATE}`
    }),
    /** Handle creating News event */
    handleCreateNews () {
      this.createNews({
        news: this.currentEvent,
        locale: this.$i18n.locale
      })
    },
    handlePreviewFile () {
      if (this.$refs.feNewsImageFile.files.length > 0) {
        this.previewImageUrl = URL.createObjectURL(this.$refs.feNewsImageFile.files[0])
        var reader = new FileReader()
        reader.readAsDataURL(this.$refs.feNewsImageFile.files[0])
        reader.onloadend = () => {
          this.updateNewsImage({ file: reader.result })
        }
      }
    }
  }
}
</script>
