/**
 * FAQ management states
 **/
const faqsManagementStates = {
  faqs: [],
  currentFAQ: null,
  paginationMeta: {
    current: 0,
    limit: 10,
    pages: 0,
    next: 0,
    prev: 0
  },
  remoteMessage: null
}

export default faqsManagementStates
