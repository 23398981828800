<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0 text-muted">
        Firmware list
      </h6>
    </div>
    <div class="card-body p-0 pb-2 text-left">
      <div
        v-if="firmwareList && firmwareList.length > 0"
        class="table-responsive"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="border-0 text-center"
              >
                Latest
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Name
              </th>
              <th
                scope="col"
                class="border-0"
              >
                AS Version
              </th>
              <th
                scope="col"
                class="border-0"
              >
                FW Version
              </th>
              <th
                scope="col"
                class="border-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <firmware-item
              v-for="firmware in firmwareList"
              :key="firmware.id"
              :firmware="firmware"
            />
          </tbody>
        </table>
      </div>
      <base-loading
        v-else
      />
    </div>
    <div class="card-footer">
      <span class="text-muted">
        Latest version: {{ latestFirmware ? latestFirmware.version : '' }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirmwareItem from './FirmwareItem'
import {
  FETCH_FIRMWARE_LIST
} from '@/states/actionTypes'

export default {
  name: 'FirmwareList',
  components: {
    FirmwareItem
  },
  mounted () {
    this.fetchFirmwareList()
  },
  computed: {
    ...mapGetters({
      firmwareList: 'AromaFirmwareManagement/firmwares',
      latestFirmware: 'AromaFirmwareManagement/latestFirmware'
    })
  },
  methods: {
    ...mapActions({
      fetchFirmwareList: `AromaFirmwareManagement/${FETCH_FIRMWARE_LIST}`
    })
  }
}
</script>
