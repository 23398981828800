<template>
  <d-card
    v-if="profile"
    class="card-small mb-4 pt-3"
  >
    <!-- Card Header -->
    <d-card-header class="border-bottom text-center">
      <!-- User Avatar -->
      <div
        class="mb-3 mx-auto"
      >
        <d-img
          v-if="profile.picture_url === 'picture.png'"
          fluid
          thumbnail
          rounded
          class="h-50 w-50"
          :src="profile.picture_url"
          :alt="profile.name"
        />
        <d-img
          v-else
          fluid
          thumbnail
          rounded
          :src="require('@/assets/images/avatars/no-user.png')"
          class="h-50 w-50"
        />
      </div>

      <!-- User Name -->
      <h4 class="mb-0">
        {{ profile.name }}
      </h4>

      <!-- User Job Title -->
      <span class="text-muted d-block mb-2">
        {{ profile.date_of_birth | formatDate }}
      </span>
    </d-card-header>

    <d-list-group flush>
      <!-- User Meta -->
      <d-list-group-item>
        <strong class="text-muted d-block mb-2">
          Description
        </strong>
        <span>{{ profile.biography }}</span>
      </d-list-group-item>
    </d-list-group>
  </d-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserDetails',
  computed: {
    ...mapGetters({
      profile: 'User/profile'
    })
  }
}
</script>
