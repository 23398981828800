<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-row>
        <d-col>
          <label for="feSDKTitle">Title</label>
          <d-input
            id="feSDKTitle"
            v-model="title"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKSlug">Slug</label>
            <d-input
              id="feSDKSlug"
              v-model="slug"
            />
          </d-col>
        </d-form-row>
        <!-- DOWNLOAD URL -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKDownloadURL">Download URL</label>
            <d-input
              id="feSDKDownloadURL"
              v-model="downloadURL"
            />
          </d-col>
        </d-form-row>
        <!-- OVERVIEW -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKOverview">Overview</label>
            <VueTrix
              input-id="feSDKOverview"
              v-model="overview"
            />
          </d-col>
        </d-form-row>
        <!-- INSTALL GUIDES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feSDKInstallGuides">Install guides</label>
            <VueTrix
              input-id="feSDKInstallGuides"
              v-model="installGuides"
            />
          </d-col>
        </d-form-row>
        <!-- Submit -->
        <d-form-row>
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleCreateSDK"
            >
              Create
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
  </div>
</template>

<script>
import VueTrix from 'vue-trix'
import { mapGetters, mapActions } from 'vuex'
import {
  CURRENT_SDK_UPDATE,
  SDK_TITLE_UPDATE,
  SDK_SLUG_UPDATE,
  SDK_DOWNLOAD_URL_UPDATE,
  SDK_OVERVIEW_UPDATE,
  SDK_INSTALL_GUIDES_UPDATE,
  CREATE_AROMA_SDK
} from '@/states/actionTypes'

export default {
  name: 'AromaSdkCreate',
  components: {
    VueTrix
  },
  created () {
    /** Select current app */
    this.updateCurrentSDK({
      install_guides: '',
      link_url: '',
      overview: '',
      slug: '',
      title: 'New SDK',
      images: []
    })
  },
  computed: {
    ...mapGetters({
      currentSDK: 'ControllerSdksManagement/currentSDK'
    }),
    title: {
      get () {
        return this.currentSDK.title || ''
      },
      set (newTitle) {
        this.updateSDKTitle(newTitle)
        this.updateSDKSlug(newTitle)
      }
    },
    slug: {
      get () {
        return this.currentSDK.slug
      },
      set (newSlug) {
        this.updateSDKSlug(newSlug)
      }
    },
    downloadURL: {
      get () {
        return this.currentSDK.link_url
      },
      set (newDownloadURL) {
        this.updateSDKDownloadURL(newDownloadURL)
      }
    },
    overview: {
      get () {
        return this.currentSDK.overview
      },
      set (newOverview) {
        this.updateSDKOverview(newOverview)
      }
    },
    installGuides: {
      get () {
        return this.currentSDK.install_guides
      },
      set (newGuides) {
        this.updateSDKInstallGuides(newGuides)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentSDK: `ControllerSdksManagement/${CURRENT_SDK_UPDATE}`,
      updateSDKTitle: `ControllerSdksManagement/${SDK_TITLE_UPDATE}`,
      updateSDKSlug: `ControllerSdksManagement/${SDK_SLUG_UPDATE}`,
      updateSDKDownloadURL: `ControllerSdksManagement/${SDK_DOWNLOAD_URL_UPDATE}`,
      updateSDKOverview: `ControllerSdksManagement/${SDK_OVERVIEW_UPDATE}`,
      updateSDKInstallGuides: `ControllerSdksManagement/${SDK_INSTALL_GUIDES_UPDATE}`,
      createAromaSDK: `ControllerSdksManagement/${CREATE_AROMA_SDK}`
    }),
    handleCreateSDK () {
      this.createAromaSDK({ sdk: this.currentSDK, locale: this.$i18n.locale })
    }
  }
}
</script>
