/**
 * Aroma Cartridges management mutations
 **/
import {
  SET_CARTRIDGE_SEARCH_QUERY,
  SET_CARTRIDGES,
  SET_CURRENT_CARTRIDGE,
  RESET_CURRENT_CARTRIDGE,
  SET_CARTRIDGE_SERIAL,
  SET_CARTRIDGE_ACCESS,
  SET_CARTRIDGE_LOCATION,
  SET_LOCATIONS,
  SET_CARTRIDGE_STOCK,
  SET_CARTRIDGE_QUANTITY,
  SET_CARTRIDGE_CLASSIFICATION,
  SET_CARTRIDGE_KIND,
  SET_CARTRIDGE_ENAME,
  SET_CARTRIDGE_JNAME,
  SET_CARTRIDGE_PRICE,
  SET_CARTRIDGE_DESCRIPTION,
  SET_CURRENT_CARTRIDGE_IMAGE,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const cartridgesManagementMutations = {
  [SET_CARTRIDGE_SEARCH_QUERY]: (state, query) => {
    state.searchCartridge.query = query
  },
  [SET_CARTRIDGES]: (state, cartridges) => {
    state.cartridges = cartridges
  },
  [SET_CURRENT_CARTRIDGE]: (state, cartridge) => {
    state.currentCartridge = cartridge
  },
  [RESET_CURRENT_CARTRIDGE]: (state) => {
    state.currentCartridge = {
      serial: '',
      active: '',
      quantity: '',
      classification: '',
      kind: '',
      ename: '',
      jname: '',
      price: '',
      description: ''
    }
  },
  [SET_CURRENT_CARTRIDGE_IMAGE]: (state, image) => {
    state.currentCartridge.images = [image]
  },
  [SET_CARTRIDGE_SERIAL]: (state, serial) => {
    state.currentCartridge.serial = serial
  },
  [SET_CARTRIDGE_ACCESS]: (state, access) => {
    state.currentCartridge.access = access
  },
  [SET_CARTRIDGE_LOCATION]: (state, locationId) => {
    const targetLocation = state.cartridgeLocations.find((location) => {
      return location.id === locationId
    })
    state.currentCartridge.stock_location = targetLocation
    state.currentCartridge.stock_location_id = locationId
  },
  [SET_LOCATIONS]: (state, locations) => {
    state.cartridgeLocations = locations
  },
  [SET_CARTRIDGE_STOCK]: (state, stock) => {
    state.currentCartridge.active = stock
  },
  [SET_CARTRIDGE_QUANTITY]: (state, quantity) => {
    state.currentCartridge.quantity = quantity
  },
  [SET_CARTRIDGE_CLASSIFICATION]: (state, classification) => {
    state.currentCartridge.classification = classification
  },
  [SET_CARTRIDGE_KIND]: (state, kind) => {
    state.currentCartridge.kind = kind
  },
  [SET_CARTRIDGE_ENAME]: (state, ename) => {
    state.currentCartridge.ename = ename
  },
  [SET_CARTRIDGE_JNAME]: (state, jname) => {
    state.currentCartridge.jname = jname
  },
  [SET_CARTRIDGE_PRICE]: (state, price) => {
    state.currentCartridge.price = price
  },
  [SET_CARTRIDGE_DESCRIPTION]: (state, description) => {
    state.currentCartridge.description = description
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default cartridgesManagementMutations
