// Core
import Vue from 'vue'
import './registerServiceWorker'
import App from './App.vue'

// Plugins
import './plugins/bootstrap'
import './plugins/shardsVue'
import './plugins/googleSignIn'
import './plugins/recaptcha'
import './plugins/toasted'
import './plugins/sentry'
import * as filters from './utils/filters'
import '@/components/base/_globals'
import router from './router'
import store from './states'
import { AuthServer, ResourceServer, ServerlessServer } from './plugins/http'
import i18n from './plugins/i18n'
import metaInfo from './plugins/meta'

// Layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import UnauthorizedLayout from '@/layouts/UnauthorizedLayout.vue'

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('unauthorized-layout', UnauthorizedLayout)
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('default-layout', DefaultLayout)

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()

for (const key in filters) {
  Vue.filter(key, filters[key])
}

function main () {
  const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <a>.'

  Vue.config.warnHandler = function (msg, vm, trace) {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
      msg = null
      vm = null
      trace = null
    }
  }

  return new Vue({
    i18n,
    router,
    store,
    http: { AuthServer, ResourceServer, ServerlessServer },
    page: metaInfo,
    render: h => h(App)
  }).$mount('#aj-website')
}

main()

// If running inside Cypress...
if (window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = main()
}
