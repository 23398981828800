/** Audit management actions */
import Vue from 'vue'
import {
  SET_AUDITS_LIST,
  SET_CURRENT_AUDIT,
  SET_REMOTE_MESSAGE,
  SET_PAGINATION_META
} from '../../mutationTypes'

import {
  FETCH_AUDITS,
  CURRENT_AUDIT_UPDATE
} from '../../actionTypes'

const auditActions = {
  async [FETCH_AUDITS] ({ commit, rootGetters }, params) {
    try {
      // Reset error messages
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount) {
        const auditResponse = await Vue.axios.resourceServer.get('aroma_audits', {
          params: {
            'locale': params.locale,
            'page[number]': params.page,
            'page[size]': params.size
          },
          headers: {
            Authorization: currentAccount.authentication_token
          }
        })

        if (auditResponse.data.data) {
          commit(SET_AUDITS_LIST, auditResponse.data.data)
          commit(SET_PAGINATION_META, auditResponse.data.meta)
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: user is invalid',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_AUDIT_UPDATE] ({ commit }, audit) {
    commit(SET_CURRENT_AUDIT, audit)
  }
}

export default auditActions
