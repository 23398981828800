<template>
  <div :class="['main-navbar', 'bg-white', stickyTop ? 'sticky-top' : '']">
    <d-navbar
      type="light"
      class="align-items-stretch flex-md-nowrap p-0"
    >
      <!-- LANGUAGES -->
      <d-navbar-nav class="border-left flex-row">
        <li class="nav-item dropdown">
          <a
            v-d-toggle.language-actions
            class="nav-link dropdown-toggle text-nowrap pl-3 pr-3"
          >
            <d-img
              class="user-avatar mr-2"
              :src="require('@/assets/images/translate.svg')"
              alt=""
            />
            <span class="d-none d-md-inline-block">
              {{ this.$i18n.locale === 'ja' ? '日本語' : 'English' }}
            </span>
          </a>
          <d-collapse
            id="language-actions"
            class="dropdown-menu dropdown-menu-small"
          >
            <d-dropdown-item
              @click.native="handleLanguageChange"
            >
              <i class="material-icons">
                language
              </i>
              <span>
                {{ $i18n.locale === 'ja' ? 'English' : '日本語' }}
              </span>
            </d-dropdown-item>
          </d-collapse>
        </li>
      </d-navbar-nav>
      <!-- SEARCH BAR -->
      <navbar-search />
      <!-- USER PROFILE -->
      <navbar-nav :user="user" />
      <navbar-toggle />
    </d-navbar>
  </div>
</template>

<script>
import NavbarSearch from './NavbarSearch.vue'
import NavbarNav from './NavbarNav.vue'
import NavbarToggle from './NavbarToggle.vue'
import { mapGetters } from 'vuex'
import {
  FETCH_CARTRIDGES,
  FETCH_CONTACTS,
  FETCH_AROMA_APPLICATIONS,
  FETCH_AROMA_SDKS,
  FETCH_USERS,
  FETCH_FIRMWARE_LIST,
  FETCH_NEWS,
  FETCH_FAQS,
  FETCH_AROMA_SHOOTER_LIST,
  FETCH_MEMBERS_LIST,
  FETCH_CAREERS
} from '@/states/actionTypes'

export default {
  name: 'TheNavbar',
  components: {
    NavbarNav,
    NavbarToggle,
    NavbarSearch
  },
  props: {
    /**
     * Whether the main navbar should be sticky, or not.
     */
    stickyTop: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/user'
    })
  },
  methods: {
    handleLanguageChange () {
      this.$i18n.locale = this.$i18n.locale === 'ja' ? 'en' : 'ja'
      this.refetchResource(this.$route.name)
    },
    refetchResource (resource) {
      switch (resource) {
        case 'aroma-cartridges-management':
          this.$store.dispatch(`CartridgesManagement/${FETCH_CARTRIDGES}`, {
            query: '',
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'news-management':
          this.$store.dispatch(`NewsManagement/${FETCH_NEWS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'users-management':
          this.$store.dispatch(`UsersManagement/${FETCH_USERS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'aroma-shooter-firmware-management':
          this.$store.dispatch(`AromaFirmwareManagement/${FETCH_FIRMWARE_LIST}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'apps-management':
          this.$store.dispatch(`ApplicationsManagement/${FETCH_AROMA_APPLICATIONS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'sdks-management':
          this.$store.dispatch(`ControllerSdksManagement/${FETCH_AROMA_SDKS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'contacts-management':
          this.$store.dispatch(`ContactsManagement/${FETCH_CONTACTS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'faqs-management':
          this.$store.dispatch(`FAQsManagement/${FETCH_FAQS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'members-management':
          this.$store.dispatch(`MembersManagement/${FETCH_MEMBERS_LIST}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'aroma-shooters-management':
          this.$store.dispatch(`AromaShootersManagement/${FETCH_AROMA_SHOOTER_LIST}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'careers-management':
          this.$store.dispatch(`CareersManagement/${FETCH_CAREERS}`, {
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-navbar {
  .dropdown-menu {
    display: block;
  }
  .nav-link {
    font-size: 16px;
    padding-top: 0.7rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
