import controllerSdksManagementStates from './ControllerSdksManagementStates'
import controllerSdksManagementGetters from './ControllerSdksManagementGetters'
import controllerSdksManagementMutations from './ControllerSdksManagementMutations'
import controllerSdksManagementActions from './ControllerSdksManagementActions'

const controllerSdksManagement = {
  namespaced: true,
  state: controllerSdksManagementStates,
  getters: controllerSdksManagementGetters,
  mutations: controllerSdksManagementMutations,
  actions: controllerSdksManagementActions
}

export default controllerSdksManagement
