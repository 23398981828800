/** Audit management mutations */
import {
  SET_AUDITS_LIST,
  SET_CURRENT_AUDIT,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE
} from '../../mutationTypes'

const auditMutations = {
  [SET_AUDITS_LIST]: (state, audits) => {
    state.audits = audits
  },
  [SET_CURRENT_AUDIT]: (state, audit) => {
    state.currentAudit = audit
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  }
}

export default auditMutations
