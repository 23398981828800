import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
  icon: 'cached',
  theme: 'outline',
  position: 'top-right',
  duration: 7000
})

export default Toasted
