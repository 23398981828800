<template>
  <div>
    <!-- Create News button -->
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewEvent"
          class="btn-royal-blue"
        >
          Create News
        </d-button>
      </d-col>
    </d-row>

    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <NewsList />
      </div>
    </div>
  </div>
</template>

<script>
import NewsList from '@/components/news/NewsList'

export default {
  name: 'NewsIndex',
  components: {
    NewsList
  },
  methods: {
    /** Handle going to adding News view */
    handleNewEvent () {
      this.$router.push({
        name: 'create-news'
      })
    }
  }
}
</script>
