<template>
  <div>
    <!-- Page Content -->
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="text-muted">
              New cartridge
            </h6>
          </div>
          <div class="card-body text-left border-bottom">
            <d-form @submit.prevent="handleCreateCartridge">
              <d-form-row>
                <d-col
                  md="12"
                  class="mb-3"
                >
                  <label for="feSerial">Serial*</label>
                  <d-input
                    v-model="serial"
                    id="feSerial"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feAccess">Access state</label>
                  <d-select
                    id="feAccess"
                    class="mb-3"
                    v-model="access"
                  >
                    <option value="published">
                      Public
                    </option>
                    <option value="customed">
                      Private
                    </option>
                  </d-select>
                </d-col>
                <d-col md="6">
                  <label for="feStockLocation">Stock Location</label>
                  <d-form-select
                    id="feStockLocation"
                    class="autocomplete"
                    v-model="stockLocation"
                    :options="locationOptions"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feStockStatus">Stock</label>
                  <d-select
                    id="feStockStatus"
                    class="mb-3"
                    v-model="active"
                  >
                    <option value="liquid">
                      Liquid oil
                    </option>
                    <option value="solid">
                      Solid tablet
                    </option>
                    <option value="sold_out">
                      Sold out
                    </option>
                  </d-select>
                </d-col>
                <d-col md="6">
                  <label for="feQuantity">Stock quantity</label>
                  <d-input
                    v-model="quantity"
                    type="number"
                    id="feQuantity"
                    class="mb-3"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feClassification">Classification</label>
                  <d-input
                    v-model="classification"
                    id="feClassification"
                    class="mb-3"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feKind">Kind*</label>
                  <d-input
                    v-model="kind"
                    id="feKind"
                    class="mb-3"
                    :required="true"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col md="6">
                  <label for="feEName">EName*</label>
                  <d-input
                    v-model="ename"
                    id="feEName"
                    class="mb-3"
                    :required="true"
                  />
                </d-col>
                <d-col md="6">
                  <label for="feJName">JName</label>
                  <d-input
                    v-model="jname"
                    id="feJName"
                    class="mb-3"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="fePrice">Price*</label>
                  <d-input-group
                    prepend="¥"
                    class="mb-3"
                  >
                    <d-input
                      v-model="price"
                      id="fePrice"
                      :required="true"
                    />
                  </d-input-group>
                </d-col>
              </d-form-row>
              <d-form-row>
                <d-col>
                  <label for="feDescription">
                    Description
                  </label>
                  <d-input
                    v-model="description"
                    name="feDescription"
                    class="mb-3"
                  />
                </d-col>
              </d-form-row>
              <!-- IMAGE UPLOAD -->
              <d-form-row>
                <d-col>
                  <label for="feCartridgeImageFile">
                    Cartridge Image File
                  </label>
                  <div class="custom-file mb-3">
                    <input
                      id="feCartridgeImageFile"
                      ref="feCartridgeImageFile"
                      type="file"
                      class="custom-file-input"
                      @change="handlePreviewFile"
                    >
                    <label
                      class="custom-file-label"
                      for="feCartridgeImageFile"
                    >
                      Choose file...
                    </label>
                  </div>
                </d-col>
              </d-form-row>
              <!-- IMAGE VIEW -->
              <d-form-row
                class="mb-3"
              >
                <d-col>
                  <d-img
                    :src="previewImageUrl"
                    fluid
                    rounded
                    center
                  />
                </d-col>
              </d-form-row>
              <d-button
                class="btn-accent mr-3"
              >
                Create new cartridge
              </d-button>
            </d-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  CURRENT_CARTRIDGE_UPDATE,
  CREATE_CARTRIDGE,
  CARTRIDGE_IMAGE_UPDATE,
  CARTRIDGE_SERIAL_UPDATE,
  CARTRIDGE_ACCESS_UPDATE,
  CARTRIDGE_LOCATION_UPDATE,
  CARTRIDGE_STOCK_UPDATE,
  CARTRIDGE_QUANTITY_UPDATE,
  CARTRIDGE_CLASSIFICATION_UPDATE,
  CARTRIDGE_KIND_UPDATE,
  CARTRIDGE_ENAME_UPDATE,
  CARTRIDGE_JNAME_UPDATE,
  CARTRIDGE_PRICE_UPDATE,
  CARTRIDGE_DESCRIPTION_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'CartridgeView',
  data () {
    return {
      previewImageUrl: null,
      valid: null
    }
  },
  async created () {
    await this.updateCurrentCartridge({
      access: '',
      active: '',
      classification: '',
      description: '',
      ename: '',
      jname: '',
      kind: '',
      price: 0,
      quantity: 0,
      serial: '',
      stock_location_id: 1
    })
  },
  computed: {
    ...mapGetters({
      currentCartridge: 'CartridgesManagement/currentCartridge',
      cartridgeLocations: 'CartridgesManagement/cartridgeLocations'
    }),
    locationOptions () {
      let locations = []
      this.cartridgeLocations.forEach((location) => {
        if (location) {
          locations.push({ text: location.location, value: location.id })
        }
      })
      return locations
    },
    serial: {
      get () {
        return this.currentCartridge.serial
      },
      set (newSerial) {
        this.updateCartridgeSerial(newSerial)
      }
    },
    access: {
      get () {
        return this.currentCartridge.access
      },
      set (newAccess) {
        this.updateCartridgeAccess(newAccess)
      }
    },
    stockLocation: {
      get () {
        return this.currentCartridge.stock_location_id
      },
      set (newLocationId) {
        this.updateCartridgeLocation(newLocationId)
      }
    },
    active: {
      get () {
        return this.currentCartridge.active
      },
      set (newStock) {
        this.updateCartridgeStock(newStock)
      }
    },
    quantity: {
      get () {
        return this.currentCartridge.quantity
      },
      set (newQuantity) {
        this.updateCartridgeQuantity(newQuantity)
      }
    },
    classification: {
      get () {
        return this.currentCartridge.classification
      },
      set (newClassification) {
        this.updateCartridgeClassification(newClassification)
      }
    },
    kind: {
      get () {
        return this.currentCartridge.kind
      },
      set (newKind) {
        this.updateCartridgeKind(newKind)
      }
    },
    ename: {
      get () {
        return this.currentCartridge.ename
      },
      set (newEname) {
        this.updateCartridgeEname(newEname)
      }
    },
    jname: {
      get () {
        return this.currentCartridge.jname
      },
      set (newJname) {
        this.updateCartridgeJname(newJname)
      }
    },
    price: {
      get () {
        return this.currentCartridge.price
      },
      set (newPrice) {
        this.updateCartridgePrice(newPrice)
      }
    },
    description: {
      get () {
        return this.currentCartridge.description
      },
      set (newDescription) {
        this.updateCartridgeDescription(newDescription)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCurrentCartridge: `CartridgesManagement/${CURRENT_CARTRIDGE_UPDATE}`,
      createCartridge: `CartridgesManagement/${CREATE_CARTRIDGE}`,
      updateCartridgeImage: `CartridgesManagement/${CARTRIDGE_IMAGE_UPDATE}`,
      updateCartridgeSerial: `CartridgesManagement/${CARTRIDGE_SERIAL_UPDATE}`,
      updateCartridgeAccess: `CartridgesManagement/${CARTRIDGE_ACCESS_UPDATE}`,
      updateCartridgeLocation: `CartridgesManagement/${CARTRIDGE_LOCATION_UPDATE}`,
      updateCartridgeStock: `CartridgesManagement/${CARTRIDGE_STOCK_UPDATE}`,
      updateCartridgeQuantity: `CartridgesManagement/${CARTRIDGE_QUANTITY_UPDATE}`,
      updateCartridgeClassification: `CartridgesManagement/${CARTRIDGE_CLASSIFICATION_UPDATE}`,
      updateCartridgeKind: `CartridgesManagement/${CARTRIDGE_KIND_UPDATE}`,
      updateCartridgeEname: `CartridgesManagement/${CARTRIDGE_ENAME_UPDATE}`,
      updateCartridgeJname: `CartridgesManagement/${CARTRIDGE_JNAME_UPDATE}`,
      updateCartridgePrice: `CartridgesManagement/${CARTRIDGE_PRICE_UPDATE}`,
      updateCartridgeDescription: `CartridgesManagement/${CARTRIDGE_DESCRIPTION_UPDATE}`
    }),
    handleCartridgeChanges (newCartridge) {
      this.updateCurrentCartridge(newCartridge)
    },
    handleCreateCartridge () {
      this.createCartridge(this.currentCartridge)
    },
    handlePreviewFile () {
      if (this.$refs.feCartridgeImageFile.files.length > 0) {
        this.previewImageUrl = URL.createObjectURL(this.$refs.feCartridgeImageFile.files[0])
        const reader = new FileReader()
        reader.readAsDataURL(this.$refs.feCartridgeImageFile.files[0])
        reader.onloadend = () => {
          this.updateCartridgeImage({ file: reader.result })
        }
      }
    }
  }
}
</script>
