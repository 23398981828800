import applicationsManagementStates from './ApplicationsManagementStates'
import applicationsManagementGetters from './ApplicationsManagementGetters'
import applicationsManagementMutations from './ApplicationsManagementMutations'
import applicationsManagementActions from './ApplicationsManagementActions'

const applicationsManagement = {
  namespaced: true,
  state: applicationsManagementStates,
  getters: applicationsManagementGetters,
  mutations: applicationsManagementMutations,
  actions: applicationsManagementActions
}

export default applicationsManagement
