/**
 * Contact management mutations
 **/
import {
  SET_CONTACT_LIST,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_CURRENT_CONTACT
} from '../../mutationTypes'

const contactsManagementMutations = {
  [SET_CONTACT_LIST]: (state, contacts) => {
    state.contacts = contacts
  },
  [SET_PAGINATION_META]: (state, meta) => {
    state.paginationMeta = meta
  },
  [SET_REMOTE_MESSAGE]: (state, message) => {
    state.remoteMessage = message
  },
  [SET_CURRENT_CONTACT]: (state, contact) => {
    state.currentContact = contact
  }
}

export default contactsManagementMutations
