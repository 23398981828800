import membersManagementStates from './MembersManagementStates'
import membersManagementGetters from './MembersManagementGetters'
import membersManagementMutations from './MembersManagementMutations'
import membersManagementActions from './MembersManagementActions'

const membersManagement = {
  namespaced: true,
  state: membersManagementStates,
  getters: membersManagementGetters,
  mutations: membersManagementMutations,
  actions: membersManagementActions
}

export default membersManagement
