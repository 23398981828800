<template>
  <tr>
    <td class="text-center">
      {{ faq.id }}
    </td>
    <td>
      <span>
        {{ faq.question }}
      </span>
    </td>
    <td>
      {{ faq.question_type }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          class="btn-white"
          @click="handleOperation(faq, 'view')"
        >
          <i class="material-icons">
            credit_card
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperation(faq, 'edit')"
        >
          <i class="material-icons">
            create
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperation(faq, 'delete')"
        >
          <i class="material-icons">
            delete_sweep
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import {
  CURRENT_FAQ_UPDATE,
  DELETE_FAQ
} from '@/states/actionTypes'

export default {
  name: 'FAQItem',
  props: {
    faq: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentFAQ: `FAQsManagement/${CURRENT_FAQ_UPDATE}`,
      deleteFAQ: `FAQsManagement/${DELETE_FAQ}`
    }),
    handleOperation (faq, type) {
      /** Select current news object */
      this.updateCurrentFAQ(faq)

      // Do FAQ record opertions
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'view-faq',
            params: { id: faq.id.toString(), action: 'view' }
          })
          break
        case 'edit':
          this.$router.push({
            name: 'view-faq',
            params: { id: faq.id.toString(), action: 'edit' }
          })
          break
        case 'delete':
          let confirmDelete = confirm(`Are you sure to delete:\n ${faq.question}`)
          if (confirmDelete) {
            this.deleteFAQ(faq.id)
          }
          break
        default:
          break
      }
    }
  }
}
</script>
