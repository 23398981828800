/**
 * Web default states
 */
const WebDefaultStates = {
  googleAccessSession: null,
  pageviewStats: {
    label: 'Views',
    value: '0',
    increase: true,
    decrease: false
  },
  timeLoadStats: {
    label: 'Avg. Page Load Time (sec)',
    value: '0',
    increase: true,
    decrease: false
  },
  currentUserStats: {
    label: 'Current Users',
    value: '0',
    increase: false,
    decrease: true
  },
  newVisitorStats: {
    label: 'New Visitors',
    value: '0',
    increase: false,
    decrease: true
  },
  sessionStats: {
    label: 'Sessions',
    value: '0',
    increase: false,
    decrease: true
  },
  deviceCategoryStats: {
    datasets: [{
      hoverBorderColor: '#ffffff',
      data: [68.3, 24.2, 7.5],
      backgroundColor: [
        'rgba(0,123,255,0.9)',
        'rgba(0,123,255,0.5)',
        'rgba(0,123,255,0.3)'
      ]
    }],
    labels: ['Desktop', 'Tablet', 'Mobile']
  }
}

export default WebDefaultStates
