<template>
  <div>
    <d-row class="mb-2">
      <d-col class="mb-4">
        <d-button
          @click="handleNewOrder"
          class="btn-royal-blue"
          disabled
        >
          Create Order
        </d-button>
      </d-col>
    </d-row>
    <div class="row">
      <div class="col">
        <OrdersList />
      </div>
    </div>
  </div>
</template>

<script>
import OrdersList from '@/components/orders/OrdersList'

export default {
  name: 'OrdersIndex',
  components: {
    OrdersList
  },
  methods: {
    /** Handle going to adding Order view */
    handleNewOrder () {
      this.$router.push({
        name: 'create-orders'
      })
    }
  }
}
</script>
