/** User */
export const SIGN_IN = 'signIn'
export const SIGN_OUT = 'signOut'
export const FETCH_USER_PROFILE = 'fetchUserProfile'
export const UPDATE_USER_PROFILE = 'updateRemoteProfile'
export const CURRENT_USER_UPDATE = 'updateUser'
export const USER_PROFILE_UPDATE = 'updateLocalProfile'
export const VERIFY_USER_TOKEN = 'verifyUserToken'

/** Dashboard */
export const GOOGLE_ACCESS_SESSION_UPDATE = 'fetchGoogleAccessToken'
export const SYNC_GA_PAGEVIEWS = 'fetchGAPageviews'
export const SYNC_GA_PAGELOADTIME = 'fetchGAPageLoadTime'
export const SYNC_GA_CURRENT_USERS = 'fetchGACurrentUsers'
export const SYNC_GA_NEW_VISITORS = 'fetchGANewVisitors'
export const SYNC_GA_SESSIONS = 'fetchGASessions'
export const SYNC_GA_USER_DEVICES = 'fetchGAUserDevices'
export const GA_PAGEVIEWS_UPDATE = 'GAPageviewsUpdate'
export const GA_PAGELOADTIME_UPDATE = 'GAPageLoadTimeUpdate'

/** News **/
export const FETCH_NEWS = 'fetchNews'
export const CREATE_NEWS = 'createNews'
export const UPDATE_NEWS = 'updateNews'
export const DELETE_NEWS = 'deleteNews'
export const CURRENT_NEWS_UPDATE = 'updateCurrentEvent'
export const NEWS_TITLE_UPDATE = 'updateNewsTitle'
export const NEWS_SLUG_UPDATE = 'updateNewsSlug'
export const NEWS_DESCRIPTION_UPDATE = 'updateNewsDescription'
export const NEWS_HIGHLIGHTED_UPDATE = 'updateNewsHighlighted'
export const NEWS_START_DATE_UPDATE = 'updateNewsStartDate'
export const NEWS_END_DATE_UPDATE = 'updateNewsEndDate'
export const NEWS_IMAGE_UPDATE = 'updateNewsImage'
export const NEWS_IMAGE_REMOVE = 'removeNewsImage'

/** FAQ */
export const FETCH_FAQS = 'fetchFAQs'
export const CREATE_FAQ = 'createFAQ'
export const UPDATE_FAQ = 'updateFAQ'
export const DELETE_FAQ = 'deleteFAQ'
export const CURRENT_FAQ_UPDATE = 'updateCurrentFAQ'
export const FAQ_QUESTION_UPDATE = 'updateFAQQuestion'
export const FAQ_SLUG_UPDATE = 'updateFAQSlug'
export const FAQ_ANSWER_UPDATE = 'updateFAQAnswer'
export const FAQ_CATEGORY_UPDATE = 'updateFAQCategory'

/** Cartridge **/
export const FETCH_CARTRIDGES = 'fetchCartridges'
export const FETCH_LOCATIONS = 'fetchLocations'
export const CREATE_CARTRIDGE = 'createCartridge'
export const UPDATE_CARTRIDGE = 'updateCartridge'
export const DELETE_CARTRIDGE = 'deleteCartridge'
export const CURRENT_CARTRIDGE_UPDATE = 'updateCurrentCartridge'
export const CURRENT_CARTRIDGE_DESTROY = 'destroyCurrentCartridge'
export const CARTRIDGE_SERIAL_UPDATE = 'updateCartridgeSerial'
export const CARTRIDGE_ACCESS_UPDATE = 'updateCartridgeAccess'
export const CARTRIDGE_LOCATION_UPDATE = 'updateCartridgeLocation'
export const CARTRIDGE_STOCK_UPDATE = 'updateCartridgeStock'
export const CARTRIDGE_QUANTITY_UPDATE = 'updateCartridgeQuantity'
export const CARTRIDGE_CLASSIFICATION_UPDATE = 'updateCartridgeClassification'
export const CARTRIDGE_KIND_UPDATE = 'updateCartridgeKind'
export const CARTRIDGE_ENAME_UPDATE = 'updateCartridgeEname'
export const CARTRIDGE_JNAME_UPDATE = 'updateCartridgeJname'
export const CARTRIDGE_PRICE_UPDATE = 'updateCartridgePrice'
export const CARTRIDGE_DESCRIPTION_UPDATE = 'updateCartridgeDescription'
export const CARTRIDGE_IMAGE_UPDATE = 'updateCartridgeImage'
export const CARTRIDGE_SEARCH_QUERY_UPDATE = 'updateCartridgeSearchQuery'

/** Contact */
export const FETCH_CONTACTS = 'fetchContacts'
export const DELETE_CONTACT = 'deleteContact'
export const RESEND_CONTACT = 'resendContact'
export const CURRENT_CONTACT_UPDATE = 'updateCurrentContact'

/** Aroma Applications */
export const FETCH_AROMA_APPLICATIONS = 'fetchAromaApplications'
export const CREATE_AROMA_APPLICATION = 'createAromaApplication'
export const DELETE_AROMA_APPLICATION = 'deleteAromaApplication'
export const UPDATE_AROMA_APPLICATION = 'updateAromaApplication'
export const CURRENT_APPLICATION_UPDATE = 'updateCurrentApplication'
export const APPLICATION_TITLE_UPDATE = 'updateApplicationTitle'
export const APPLICATION_SLUG_UPDATE = 'updateApplicationSlug'
export const APPLICATION_DESC_UPDATE = 'updateApplicationDesc'
export const APPLICATION_PRICE_UPDATE = 'updateApplicationPrice'
export const APPLICATION_SUPPORT_UPDATE = 'updateApplicationSupport'
export const APPLICATION_FEATURES_UPDATE = 'updateApplicationFeatures'
export const APPLICATION_ANDROID_UPDATE = 'updateApplicationAndroidUrl'
export const APPLICATION_IOS_UPDATE = 'updateApplicationIOSUrl'
export const APPLICATION_LINUX_UPDATE = 'updateApplicationLinuxUrl'
export const APPLICATION_MACOS_UPDATE = 'updateApplicationMacOSUrl'
export const APPLICATION_WINDOW_UPDATE = 'updateApplicationWindowUrl'
export const APPLICATION_VIDEO_UPDATE = 'updateApplicationVideoUrl'
export const APPLICATION_DOCUMENT_UPDATE = 'updateApplicationDocumentUrl'
export const APPLICATION_IMAGES_UPDATE = 'updateApplicationImages'
export const APPLICATION_IMAGE_UPDATE = 'updateApplicationImage'
export const APPLICATION_IMAGE_REMOVE = 'removeApplicationImage'

/** Aroma SDK */
export const DELETE_AROMA_SDK = 'deleteAromaSDK'
export const UPDATE_AROMA_SDK = 'updateAromaSDK'
export const CREATE_AROMA_SDK = 'createAromaSDK'
export const FETCH_AROMA_SDKS = 'fetchAromaSDKS'
export const CURRENT_SDK_UPDATE = 'updateCurrentSDK'
export const SDK_TITLE_UPDATE = 'updateSDKTitle'
export const SDK_SLUG_UPDATE = 'updateSDKSlug'
export const SDK_DOWNLOAD_URL_UPDATE = 'updateSDKDownloadUrl'
export const SDK_OVERVIEW_UPDATE = 'updateSDKOverview'
export const SDK_INSTALL_GUIDES_UPDATE = 'updateSDKInstallGuides'

/** Users management */
export const FETCH_USERS = 'fetchUsers'
export const PROMOTE_TO_ADMINISTRATOR = 'promoteToAdministrator'
export const DEGRADE_TO_ORDINARY = 'degradeToOrdinaryUser'

/** Firmware management */
export const FETCH_FIRMWARE_LIST = 'fetchFirmwareList'
export const CREATE_FIRMWARE = 'createFirmware'
export const UPDATE_FIRMWARE = 'updateFirmware'
export const DELETE_FIRMWARE = 'deleteFirmware'
export const DOWNLOAD_FIRMWARE = 'downloadFirmware'
export const CURRENT_FIRMWARE_UPDATE = 'updateCurrentFirmware'
export const FIRMWARE_LATEST_UPDATE = 'updateFirmwareLatest'
export const FIRMWARE_NAME_UPDATE = 'updateFirmwareName'
export const FIRMWARE_AS_VERSION_UPDATE = 'updateFirmwareASVersion'
export const FIRMWARE_VERSION_UPDATE = 'updateFirmwareVersion'
export const FIRMWARE_DESCRIPTION_UPDATE = 'updateFirmwareDescription'
export const FIRMWARE_BINARY_UPDATE = 'updateFirmwareBinaryPath'

/** Aroma Shooter management */
export const FETCH_AROMA_SHOOTER_LIST = 'fetchAromaShooterList'
export const CREATE_SHOOTER = 'createAromaShooter'
export const UPDATE_SHOOTER = 'updateAromaShooter'
export const DELETE_SHOOTER = 'deleteAromaShooter'
export const CURRENT_SHOOTER_UPDATE = 'updateCurrentShooter'
export const SHOOTER_TITLE_UPDATE = 'updateShooterTitle'
export const SHOOTER_SLUG_UPDATE = 'updateShooterSlug'
export const SHOOTER_TYPE_UPDATE = 'updateShooterType'
export const SHOOTER_PREFIX_UPDATE = 'updateShooterPrefix'
export const SHOOTER_COLOR_UPDATE = 'updateShooterColor'
export const SHOOTER_PORTS_UPDATE = 'updateShooterPorts'
export const SHOOTER_BLENDING_UPDATE = 'updateShooterBlending'
export const SHOOTER_VOLTAGE_UPDATE = 'updateShooterVoltage'
export const SHOOTER_DESIGN_UPDATE = 'updateShooterDesign'
export const SHOOTER_DISTANCE_UPDATE = 'updateShooterDistance'
export const SHOOTER_LED_UPDATE = 'updateShooterLed'
export const SHOOTER_METHOD_UPDATE = 'updateShooterDiffuseMethod'
export const SHOOTER_POWER_UPDATE = 'updateShooterPower'
export const SHOOTER_SIZE_UPDATE = 'updateShooterSize'
export const SHOOTER_SOFTWARES_UPDATE = 'updateShooterSoftwares'
export const SHOOTER_WEIGHT_UPDATE = 'updateShooterWeight'
export const SHOOTER_VIDEO_UPDATE = 'updateShooterVideoUrl'
export const SHOOTER_TEMP_UPDATE = 'updateShooterTempRange'
export const SHOOTER_ACCESSORIES_UPDATE = 'updateShooterAccessories'
export const SHOOTER_DOCUMENTS_EN_UPDATE = 'updateShooterDocumentEN'
export const SHOOTER_DOCUMENTS_JP_UPDATE = 'updateShooterDocumentJP'

/** Members management */
export const FETCH_MEMBERS_LIST = 'fetchMembersList'

/** Careers management */
export const FETCH_CAREERS = 'fetchCareers'
export const CREATE_CAREER = 'createCareer'
export const UPDATE_CAREER = 'updateCareer'
export const DELETE_CAREER = 'deleteCareer'
export const CURRENT_CAREER_UPDATE = 'updateCurrentCareer'
export const CAREER_TITLE_UPDATE = 'updateCareerTitle'
export const CAREER_DEADLINE_UPDATE = 'updateCareerDeadline'
export const CAREER_SLUG_UPDATE = 'updateCareerSlug'
export const CAREER_REQUIREMENTS_UPDATE = 'updateCareerRequirements'
export const CAREER_DESCRIPTION_UPDATE = 'updateCareerDescription'
export const CAREER_BENEFITS_UPDATE = 'updateCareerBenefits'
export const CAREER_CONDITIONS_UPDATE = 'updateCareerConditions'
export const CAREER_LOCATION_UPDATE = 'updateCareerLocation'

/** Audits management */
export const FETCH_AUDITS = 'fetchAudits'
export const CURRENT_AUDIT_UPDATE = 'updateCurrentAudit'

/** Subscribers management */
export const FETCH_SUBSCRIBERS = 'fetchSubscribers'
export const CURRENT_SUBSCRIBER_UPDATE = 'updateCurrentSubscriber'

/** Orders management */
export const SEND_TRACKING_CODE_BY_EMAIL = 'sendTrackingCodeByEmail'
export const UPDATE_ORDER = 'updateOrderById'
export const FETCH_ORDERS = 'fetchOrders'
export const FETCH_ORDER = 'fetchOrderById'
export const CURRENT_ORDER_UPDATE = 'updateCurrentOrder'
export const ORDER_STATE_UPDATE = 'updateOrderState'
export const ORDER_NUMBER_UPDATE = 'updateOrderNumber'
export const ORDER_NET_AMOUNT_UPDATE = 'updateOrderNetAmount'
export const ORDER_SHIPPING_AMOUNT_UPDATE = 'updateOrderShippingAmount'
export const ORDER_GROSS_AMOUNT_UPDATE = 'updateOrderGrossAmount'
export const ORDER_TRANSACTION_FEE_UPDATE = 'updateOrderTransactionFeeAmount'
export const ORDER_CHARGED_AMOUNT_UPDATE = 'updateOrderChangedAmount'
export const ORDER_CUSTOMER_NAME_UPDATE = 'updateOrderCustomerName'
export const ORDER_CUSTOMER_EMAIL_UPDATE = 'updateOrderCustomerEmail'
export const ORDER_CUSTOMER_PHONE_UPDATE = 'updateOrderCustomerPhone'
export const ORDER_SHIPPING_CARRIER_UPDATE = 'updateOrderShippingCarrier'
export const ORDER_SHIPPING_TRACKING_CODE_UPDATE = 'updateOrderShippingTrackingCode'
export const ORDER_SHIPPING_NAME_UPDATE = 'updateOrderShippingName'
export const ORDER_SHIPPING_PHONE_UPDATE = 'updateOrderShippingPhone'
export const ORDER_SHIPPING_TRACK_URL_UPDATE = 'updateOrderShippingTrackURL'
export const ORDER_METADATA_UPDATE = 'updateOrderMetadata'
export const ORDER_METADATA_ITEM_ADD = 'addOrderMetadataItem'
export const ORDER_METADATA_ITEM_REMOVE = 'removeOrderMetadataItem'
export const ORDER_CARTRIDGES_UPDATE = 'updateOrderSelectedCartridges'
export const ORDER_CARTRIDGE_ITEM_ADD = 'addOrderCartridgeItem'
export const ORDER_CARTRIDGE_ITEM_REMOVE = 'removeOrderCartridgeItem'
