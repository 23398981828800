<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0 text-muted">
              Audits List
            </h6>
          </div>
          <div class="card-body p-0 pb-2 text-left">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                    <th
                      scope="col"
                      class="border-0 text-center"
                    >
                      User ID
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Remote Address
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Auditable Type
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Action
                    </th>
                    <th
                      scope="col"
                      class="border-0"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AuditItem
                    v-for="audit in audits"
                    :key="audit.id"
                    :audit="audit"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer p-0">
            <!-- Pagination -->
            <base-pagination
              :pagination-meta="paginationMeta"
              @go-to-page="handleLoadPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AuditItem from './AuditItem'
import {
  FETCH_AUDITS
} from '@/states/actionTypes'

export default {
  name: 'AuditsIndex',
  components: {
    AuditItem
  },
  mounted () {
    this.fetchAudits({
      locale: this.$i18n.locale,
      page: 1,
      size: 10
    })
  },
  computed: {
    ...mapGetters({
      audits: 'AuditsManagement/audits',
      paginationMeta: 'AuditsManagement/paginationMeta'
    })
  },
  methods: {
    ...mapActions({
      fetchAudits: `AuditsManagement/${FETCH_AUDITS}`
    }),
    handleLoadPage (pageNumber) {
      // Go to specific page number
      switch (pageNumber) {
        case 'first':
          this.fetchAudits({
            locale: this.$i18n.locale,
            page: 1,
            size: 10
          })
          break
        case 'last':
          this.fetchAudits({
            locale: this.$i18n.locale,
            page: this.paginationMeta.pages,
            size: 10
          })
          break
        default:
          this.fetchAudits({
            locale: this.$i18n.locale,
            page: pageNumber,
            size: 10
          })
          break
      }
    }
  }
}
</script>
