<template>
  <d-container
    fluid
    class="main-content-container px-4"
  >
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4"
    >
      <d-col
        col
        sm="4"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          Account Settings
        </h3>
      </d-col>
    </d-row>

    <!-- Content -->
    <d-row>
      <d-col>
        <d-card class="card-small mb-4">
          <!-- Card Header -->
          <d-card-header class="border-bottom">
            <h5 class="m-0">
              Current Account
            </h5>
          </d-card-header>
          <d-list-group
            flush
          >
            <d-list-group-item class="p-3">
              <account-form />
            </d-list-group-item>
            <d-list-group-item class="p-3">
              <account-token-form />
            </d-list-group-item>
          </d-list-group>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import AccountForm from '@/components/account-setting/AccountForm.vue'
import AccountTokenForm from '@/components/account-setting/AccountTokenForm.vue'

export default {
  name: 'AccountSetting',
  components: {
    AccountForm,
    AccountTokenForm
  }
}
</script>

<style lang="scss" scoped>
</style>
