<template>
  <d-container
    fluid
    class="main-content-container"
  >
    <!-- Notification -->
    <base-notify
      :display="showMessage"
      :message="notifiedMessage"
      :dismiss-secs="5"
      :type="messageType"
      position="top-left"
    />

    <!-- Page Header -->
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-4 text-center text-sm-left mb-0">
        <span class="text-uppercase page-subtitle">
          Overview
        </span>
        <h3 class="page-title">
          Orders Information Management
        </h3>
      </div>
    </div>

    <!-- Page Content -->
    <router-view />
  </d-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcessErrorMessages from '@/mixins/ProcessErrorMessages.js'

export default {
  name: 'Orders',
  mixins: [
    ProcessErrorMessages('OrdersManagement/remoteMessage')
  ],
  computed: {
    ...mapGetters({
      remoteMessage: 'OrdersManagement/remoteMessage'
    })
  },
  page: {
    title: 'Orders management',
    links: [
      {
        rel: 'canonical',
        href: 'https://admin.aromajoin.com/orders'
      }
    ]
  }
}
</script>
