<template>
  <FontAwesomeIcon
    v-if="source === 'font-awesome'"
    v-bind="$attrs"
    :icon="name"
  />
  <span
    v-else
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import camelCase from 'lodash/camelCase'

// https://fontawesome.com/icons
library.add(
  fas,
  fab
)

export default {
  name: 'BaseIcon',
  components: {
    FontAwesomeIcon
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: 'font-awesome'
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    // Gets a CSS module class
    customIconClass () {
      return this.$style[camelCase('icon-custom-') + this.name]
    }
  }
}
</script>
