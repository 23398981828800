import newsManagementStates from './NewsManagementStates'
import newsManagementGetters from './NewsManagementGetters'
import newsManagementMutations from './NewsManagementMutations'
import newsManagementActions from './NewsManagementActions'

const newsManagement = {
  namespaced: true,
  state: newsManagementStates,
  getters: newsManagementGetters,
  mutations: newsManagementMutations,
  actions: newsManagementActions
}

export default newsManagement
