/**
 * Contact management getters
 **/
const contactsManagementGetters = {
  contacts: (state) => state.contacts,
  currentContact: (state) => state.currentContact,
  paginationMeta: (state) => state.paginationMeta,
  remoteMessage: (state) => state.remoteMessage
}

export default contactsManagementGetters
