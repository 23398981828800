<template>
  <d-card class="card-small mb-4">
    <!-- Card Header -->
    <d-card-header class="border-bottom">
      <h6 class="m-0">
        {{ title }}
      </h6>
    </d-card-header>

    <d-list-group
      v-if="lcProfile"
      flush
    >
      <d-list-group-item class="p-3">
        <d-row>
          <d-col>
            <d-form @submit.prevent="handleUpdateProfile">
              <d-form-row>
                <!-- First Name -->
                <d-col
                  md="12"
                  class="form-group"
                >
                  <label for="feName">
                    Name
                  </label>
                  <d-form-input
                    type="text"
                    id="feName"
                    placeholder="Your name"
                    v-model="lcProfile.name"
                  />
                </d-col>
              </d-form-row>

              <d-form-row>
                <!-- Email -->
                <d-col
                  md="6"
                  class="form-group"
                >
                  <label for="feCompany">
                    Company
                  </label>
                  <d-form-input
                    type="text"
                    id="feCompany"
                    placeholder="Company"
                    v-model="lcProfile.company"
                  />
                </d-col>

                <!-- Password -->
                <d-col
                  md="6"
                  class="form-group"
                >
                  <label for="feGender">
                    Gender
                  </label>
                  <d-select
                    v-model="lcProfile.gender"
                    id="feGender"
                  >
                    <option
                      value="male"
                    >
                      Male
                    </option>
                    <option value="female">
                      Female
                    </option>
                    <option value="others">
                      Others
                    </option>
                  </d-select>
                </d-col>
              </d-form-row>

              <!-- Timezone -->
              <div class="form-group">
                <label for="feTimezone">
                  Timezone
                </label>
                <d-form-input
                  type="text"
                  id="feTimezone"
                  v-model="lcProfile.timezone"
                />
              </div>

              <d-form-row>
                <!-- Country -->
                <d-col
                  class="form-group"
                >
                  <label for="feCountry">
                    Country
                  </label>
                  <d-form-input
                    type="text"
                    id="feCountry"
                    v-model="lcProfile.country"
                  />
                </d-col>
              </d-form-row>

              <d-form-row>
                <!-- Website -->
                <d-col
                  md="12"
                  class="form-group"
                >
                  <label for="feWebsite">
                    Website
                  </label>
                  <d-form-input
                    type="text"
                    id="feWebsite"
                    v-model="lcProfile.websites"
                  />
                </d-col>
              </d-form-row>
              <d-form-row>
                <!-- Description -->
                <d-col
                  md="12"
                  class="form-group"
                >
                  <label for="feBiography">
                    Biography
                  </label>
                  <vue-trix
                    v-model="lcProfile.biography"
                    placeholder="Enter something about you"
                    name="feBiography"
                    :rows="5"
                    :max-rows="8"
                  />
                </d-col>
              </d-form-row>

              <d-button
                type="submit"
                class="btn-accent"
              >
                Update Profile
              </d-button>
            </d-form>
          </d-col>
        </d-row>
      </d-list-group-item>
    </d-list-group>
  </d-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueTrix from 'vue-trix'

export default {
  name: 'AccountDetails',
  components: {
    VueTrix
  },
  props: {
    /**
     * The component title.
     */
    title: {
      type: String,
      default: 'Account Details'
    }
  },
  computed: {
    ...mapGetters({
      profile: 'User/profile'
    }),
    lcProfile: {
      get () {
        return this.profile
      },
      set (value) {
        console.log(value)
        this.updateLocalProfile(value)
      }
    }
  },
  methods: {
    ...mapActions({
      updateRemoteProfile: 'User/updateRemoteProfile',
      updateLocalProfile: 'User/updateLocalProfile'
    }),
    handleUpdateProfile () {
      this.updateRemoteProfile(this.lcProfile)
    }
  }
}
</script>
