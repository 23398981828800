<template>
  <div class="row">
    <div class="col">
      <div class="card mb-4">
        <div class="card-header border-bottom">
          <d-button-toolbar class="mb-4">
            <d-button
              id="toggle-edit-mode"
              @click="toggleEditMode"
            >
              <span>{{ this.editing ? 'Read-only' : 'Editable' }}</span>
              <d-tooltip
                target="#toggle-edit-mode"
              >
                {{ this.editing ? 'Disable' : 'Enable' }} editing mode
              </d-tooltip>
            </d-button>
          </d-button-toolbar>
          <h6 class="text-muted">
            {{ currentOrder.type.toUpperCase() }} --- {{ currentOrder.orderId }}
          </h6>
        </div>
        <div class="card-body text-left border-bottom">
          <d-form
            @submit.prevent="handleUpdateOrder"
          >
            <d-form-row>
              <!-- ORDER STATE -->
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feOrderState">
                  State
                </label>
                <d-input
                  v-if="!editing"
                  id="feOrderState"
                  :value="currentOrder.orderState"
                  disabled
                />
                <d-form-select
                  v-else
                  id="feOrderState"
                  v-model="orderState"
                  :options="orderStateOptions"
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feOrderNumber">
                  Order number
                </label>
                <d-input
                  v-if="!editing"
                  id="feOrderNumber"
                  :value="currentOrder.orderNumber"
                  disabled
                />
                <d-input
                  v-else
                  id="feOrderState"
                  v-model="orderNumber"
                />
              </d-col>
            </d-form-row>
            <!-- PAYMENT DETAILS  -->
            <d-form-row class="border-top mb-3">
              <d-col
                md="12"
                class="mt-2"
              >
                <h5 class="text-muted">
                  Payment details
                </h5>
              </d-col>
              <!-- END ORDER STATE -->
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="fePaymentId">
                  Payment Id
                </label>
                <d-input
                  id="fePaymentId"
                  :value="currentOrder.paymentId"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feNetAmount">Net amount</label>
                <d-input-group :prepend="displayedCurrency">
                  <d-input
                    v-if="editing"
                    id="feNetAmount"
                    v-model="netAmount"
                  />
                  <d-input
                    v-else
                    id="feNetAmount"
                    :value="currentOrder.netAmount | toNumber"
                    disabled
                  />
                </d-input-group>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feShippingAmount">Shipping amount</label>
                <d-input-group :prepend="displayedCurrency">
                  <d-input
                    v-if="editing"
                    id="feShippingAmount"
                    v-model="shippingAmount"
                  />
                  <d-input
                    v-else
                    id="feShippingAmount"
                    :value="currentOrder.shippingAmount | toNumber"
                    disabled
                  />
                </d-input-group>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feAppFee">Transaction fee</label>
                <d-input-group :prepend="displayedCurrency">
                  <d-input
                    v-if="editing"
                    id="feAppFee"
                    v-model="applicationFee"
                  />
                  <d-input
                    v-else
                    id="feAppFee"
                    :value="currentOrder.applicationFee | toNumber"
                    disabled
                  />
                </d-input-group>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feGrossAmount">Gross amount</label>
                <d-input-group :prepend="displayedCurrency">
                  <d-input
                    v-if="editing"
                    id="feGrossAmount"
                    v-model="grossAmount"
                  />
                  <d-input
                    v-else
                    id="feGrossAmount"
                    :value="currentOrder.grossAmount | toNumber"
                    disabled
                  />
                </d-input-group>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feTotalChargedAmount">Charged amount</label>
                <d-input-group :prepend="displayedCurrency">
                  <d-input
                    v-if="editing"
                    id="feTotalChargedAmount"
                    v-model="totalChargeAmount"
                  />
                  <d-input
                    v-else
                    id="feTotalChargedAmount"
                    :value="currentOrder.totalChargeAmount | toNumber"
                    disabled
                  />
                </d-input-group>
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feTotalAmountPaid">Amount paid</label>
                <d-input
                  id="feTotalAmountPaid"
                  :value="currentOrder.totalAmountPaid | toNumber"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="fePaymentSource">Payment source</label>
                <d-input
                  id="fePaymentSource"
                  :value="currentOrder.paymentSource.toUpperCase()"
                  disabled
                />
              </d-col>
            </d-form-row>
            <!-- END PAYMENT DETAILS  -->
            <!-- PRODUCT DETAILS  -->
            <d-form-row class="border-top mb-3">
              <d-col
                md="12"
                class="mt-2"
              >
                <h5 class="text-muted">
                  Product details
                </h5>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feLineItems">
                  Line items
                </label>
                <d-list-group-item
                  id="feLineItems"
                  v-for="item in currentOrder.lineItems"
                  :key="item.id"
                >
                  <span>
                    Name: <b>{{ item.name }}</b>
                    <br>
                    Quantity: <b>{{ item.quantity }}</b>
                    <br>
                    Price: <b>{{ item.baseAmount | toJPYCurrency }}</b>
                  </span>
                </d-list-group-item>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feSelectedCartridges">
                  Selected cartridges [Total: {{ totalSelectedCartridges }}]
                </label>
                <d-list-group>
                  <d-list-group-item
                    v-for="cartridge in currentOrder.selectedCartridges"
                    :key="cartridge.serial"
                  >
                    <b>{{ cartridge.serial }}</b>
                    <br>
                    <span>
                      {{ $i18n.locale === 'ja' ? cartridge.jname : cartridge.ename }} x {{ cartridge.quantity }}
                    </span>
                    <br>
                    <d-button
                      v-if="editing"
                      size="sm"
                      theme="warning"
                      class="ml-0 mt-1"
                      @click.prevent="deleteOrderSelectedCartridgeItem(cartridge)"
                    >
                      Remove
                    </d-button>
                  </d-list-group-item>

                  <d-form
                    v-if="editing"
                    inline
                    class="mt-3"
                  >
                    <label
                      class="sr-only"
                      for="ac-serial"
                    >
                      Serial
                    </label>
                    <d-input
                      id="ac-serial"
                      v-model="selectedCartridge.serial"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="Serial"
                    />

                    <label
                      class="sr-only"
                      for="ac-ename"
                    >
                      EName
                    </label>
                    <d-input
                      id="ac-ename"
                      v-model="selectedCartridge.ename"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="EName"
                    />

                    <label
                      class="sr-only"
                      for="ac-jname"
                    >
                      JName
                    </label>
                    <d-input
                      id="ac-jname"
                      v-model="selectedCartridge.jname"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="JName"
                    />

                    <label
                      class="sr-only"
                      for="ac-quantity"
                    >
                      Quantity
                    </label>
                    <d-input
                      id="ac-quantity"
                      v-model="selectedCartridge.quantity"
                      type="number"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="Quantity"
                    />

                    <d-button
                      v-if="currentOrder.selectedCartridges && currentOrder.selectedCartridges.length > 0"
                      theme="primary"
                      @click.prevent="insertOrderSelectedCartridgeItem(selectedCartridge)"
                    >
                      Add
                    </d-button>
                    <d-button
                      v-else
                      theme="primary"
                      @click.prevent="setOrderSelectedCartridges(selectedCartridge)"
                    >
                      Save
                    </d-button>
                  </d-form>
                </d-list-group>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feSelectedCartridges">
                  Selected premium SDKs
                </label>
                <d-list-group v-if="currentOrder.selectedSDK && currentOrder.selectedSDK.length > 0">
                  <d-list-group-item
                    v-for="sdk in currentOrder.selectedSDK"
                    :key="sdk"
                  >
                    <b>{{ sdk.toString().toUpperCase() }}</b>
                  </d-list-group-item>
                </d-list-group>
                <p
                  v-else
                  class="text-muted"
                >
                  -- Empty data --
                </p>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feMetadata">
                  Packaged Aroma Shooters [Total: {{ totalAromaShooterQuantity }}]
                </label>
                <d-list-group-item
                  id="feMetadata"
                  v-for="item in currentOrder.metadata"
                  :key="Object.keys(item)[0]"
                >
                  <span>
                    <b>{{ Object.keys(item)[0] }}</b> x {{ Object.values(item)[0] }}
                  </span>
                  <d-button
                    v-if="editing"
                    size="sm"
                    theme="warning"
                    class="ml-2"
                    @click.prevent="deleteOrderMetadataItem(item)"
                  >
                    Remove
                  </d-button>
                </d-list-group-item>
                <d-form
                  v-if="editing"
                  inline
                  class="mt-3"
                >
                  <label
                    class="sr-only"
                    for="as-serial"
                  >
                    Serial
                  </label>
                  <d-input
                    id="as-serial"
                    v-model="packagedAromaShooterSerial"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Serial"
                  />

                  <d-button
                    v-if="currentOrder.metadata && currentOrder.metadata.length > 0"
                    theme="primary"
                    @click.prevent="insertOrderMetadataItem(packagedAromaShooterSerial)"
                  >
                    Add
                  </d-button>
                  <d-button
                    v-else
                    theme="primary"
                    @click.prevent="setOrderMetadata(packagedAromaShooterSerial)"
                  >
                    Save
                  </d-button>
                </d-form>
              </d-col>
            </d-form-row>
            <!-- END PRODUCT  -->
            <!-- CUSTOMER  -->
            <d-form-row class="border-top mb-3">
              <d-col
                md="12"
                class="mt-3"
              >
                <h5 class="text-muted">
                  Customer [StripeId: {{ currentOrder.stripeCustomerId }}]
                </h5>
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feCustomerName">
                  Name
                </label>
                <d-input
                  v-if="editing"
                  id="feCustomerName"
                  v-model="customerName"
                />
                <d-input
                  v-else
                  id="feCustomerName"
                  :value="currentOrder.customer.name"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feCustomerPhone">
                  Phone
                </label>
                <d-input
                  v-if="editing"
                  id="feCustomerPhone"
                  v-model="customerPhone"
                />
                <d-input
                  v-else
                  id="feCustomerPhone"
                  :value="currentOrder.customer.phone"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feCustomerEmail">
                  Email
                </label>
                <d-input
                  v-if="editing"
                  id="feCustomerEmail"
                  v-model="customerEmail"
                />
                <d-input
                  v-else
                  id="feCustomerEmail"
                  :value="currentOrder.customer.email"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feCustomerDescription">
                  Notes
                </label>
                <d-input
                  id="feCustomerDescription"
                  :value="currentOrder.customer.description"
                  disabled
                />
              </d-col>
            </d-form-row>
            <!-- END CUSTOMER -->
            <!-- SHIPPING -->
            <d-form-row class="border-top mb-3">
              <d-col
                md="12"
                class="mt-3"
              >
                <h5 class="text-muted">
                  Shipping
                </h5>
              </d-col>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feShippingCountry">
                  Country
                </label>
                <d-input
                  id="feShippingCountry"
                  :value="currentOrder.shipping.address.country"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingState">
                  State
                </label>
                <d-input
                  id="feShippingState"
                  :value="currentOrder.shipping.address.state"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingCity">
                  City
                </label>
                <d-input
                  id="feShippingCity"
                  :value="currentOrder.shipping.address.city"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingPostalCode">
                  Postal code
                </label>
                <d-input
                  id="feShippingPostalCode"
                  :value="currentOrder.shipping.address.postal_code"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingLine1">
                  Address
                </label>
                <d-input
                  id="feShippingLine1"
                  :value="currentOrder.shipping.address.line1"
                  disabled
                />
              </d-col>
            </d-form-row>
            <d-form-row>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feShippingMethod">
                  Shipping method selected
                </label>
                <d-input
                  id="feShippingMethod"
                  :value="currentOrder.shippingMethod"
                  disabled
                />
              </d-col>
            </d-form-row>
            <d-form-row>
              <d-col
                md="12"
                class="mb-3"
              >
                <label for="feShippingTrackURL">
                  Tracking URL
                </label>
                <d-input
                  v-if="editing"
                  id="feShippingTrackURL"
                  v-model="shippingTrackURL"
                />
                <d-input
                  v-else
                  id="feShippingTrackURL"
                  :value="currentOrder.shipping.tracking_url"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingCarrier">
                  Carrier
                </label>
                <d-input
                  v-if="editing"
                  id="feShippingCarrier"
                  v-model="shippingCarrier"
                />
                <d-input
                  v-else
                  id="feShippingCarrier"
                  :value="currentOrder.shipping.carrier"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingTrackingNumber">
                  Tracking number
                </label>
                <d-form inline>
                  <d-form-row
                    class="w-100"
                  >
                    <d-col
                      md="9"
                    >
                      <d-input
                        v-if="editing"
                        id="feShippingTrackingNumber"
                        v-model="shippingTrackCode"
                        class="w-100"
                      />
                      <d-input
                        v-else
                        id="feShippingTrackingNumber"
                        :value="currentOrder.shipping.tracking_number"
                        class="w-100"
                        disabled
                      />
                    </d-col>
                    <d-col
                      md="3"
                      class="text-center"
                    >
                      <d-button
                        id="send-tracking-code"
                        :disabled="!editing && !isTrackingCodeSendable"
                        @click.prevent="handleSendingTrackCodeByEmail"
                      >
                        <span>Send tracking code</span>
                        <d-tooltip
                          target="#send-tracking-code"
                        >
                          Send carrier's tracking code to customer by email
                        </d-tooltip>
                      </d-button>
                    </d-col>
                  </d-form-row>
                </d-form>
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingShipperName">
                  Shipper name
                </label>
                <d-input
                  v-if="editing"
                  id="feShippingShipperName"
                  v-model="shippingName"
                />
                <d-input
                  v-else
                  id="feShippingShipperName"
                  :value="currentOrder.shipping.name"
                  disabled
                />
              </d-col>
              <d-col
                md="6"
                class="mb-3"
              >
                <label for="feShippingPhone">
                  Phone
                </label>
                <d-input
                  v-if="editing"
                  id="feShippingPhone"
                  v-model="shippingPhone"
                />
                <d-input
                  v-else
                  id="feShippingPhone"
                  :value="currentOrder.shipping.phone"
                  disabled
                />
              </d-col>
            </d-form-row>
            <!-- END SHIPPING -->
            <d-form-row
              v-if="this.editing"
              class="mt-3"
            >
              <d-button
                type="submit"
                class="btn-accent mr-3"
                :disabled="submiting"
              >
                Update
              </d-button>
              <d-button
                id="send-receipt"
                class="mr-3"
              >
                <span>Send receipt</span>
                <d-tooltip
                  target="#send-receipt"
                >
                  Send order's receipt by email
                </d-tooltip>
              </d-button>
            </d-form-row>
          </d-form>
        </div>
        <d-card-footer>
          <span class="text-muted">Created: {{ currentOrder.createdAt | formatDate }}</span> |
          <span class="text-muted">Updated: {{ currentOrder.updatedAt | formatDate }}</span>
        </d-card-footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  SEND_TRACKING_CODE_BY_EMAIL,
  UPDATE_ORDER,
  FETCH_ORDER,
  ORDER_STATE_UPDATE,
  ORDER_NET_AMOUNT_UPDATE,
  ORDER_SHIPPING_AMOUNT_UPDATE,
  ORDER_GROSS_AMOUNT_UPDATE,
  ORDER_TRANSACTION_FEE_UPDATE,
  ORDER_CHARGED_AMOUNT_UPDATE,
  ORDER_CUSTOMER_NAME_UPDATE,
  ORDER_CUSTOMER_EMAIL_UPDATE,
  ORDER_CUSTOMER_PHONE_UPDATE,
  ORDER_SHIPPING_CARRIER_UPDATE,
  ORDER_SHIPPING_TRACKING_CODE_UPDATE,
  ORDER_SHIPPING_NAME_UPDATE,
  ORDER_SHIPPING_PHONE_UPDATE,
  ORDER_METADATA_UPDATE,
  ORDER_METADATA_ITEM_ADD,
  ORDER_METADATA_ITEM_REMOVE,
  ORDER_CARTRIDGES_UPDATE,
  ORDER_CARTRIDGE_ITEM_ADD,
  ORDER_CARTRIDGE_ITEM_REMOVE,
  ORDER_SHIPPING_TRACK_URL_UPDATE,
  ORDER_NUMBER_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'OrdersView',
  props: {
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      editing: false,
      submiting: false,
      orderStateOptions: [
        { value: 'CREATED', text: 'CREATED' },
        { value: 'PENDING_PAYMENT', text: 'PENDING_PAYMENT' },
        { value: 'PAID', text: 'PAID' },
        { value: 'FULFILLED', text: 'FULFILLED' },
        { value: 'SHIPPED', text: 'SHIPPED' },
        { value: 'COMPLETED', text: 'COMPLETED' },
        { value: 'RETURNED', text: 'RETURNED' },
        { value: 'CANCELED', text: 'CANCELED' }
      ],
      packagedAromaShooterSerial: null,
      selectedCartridge: {
        serial: null,
        ename: null,
        jname: null,
        quantity: 0
      }
    }
  },
  async created () {
    this.editing = this.$route.params.action === 'edit'
    if (this.id) {
      const orderParams = {
        orderId: this.id,
        debug: true,
        locale: this.$i18n.locale
      }
      await this.fetchOrderById(orderParams)
    }
  },
  computed: {
    ...mapGetters({
      currentOrder: 'OrdersManagement/currentOrder',
      totalSelectedCartridges: 'OrdersManagement/totalCartridgesQuantity',
      totalAromaShooterQuantity: 'OrdersManagement/totalAromaShooterQuantity'
    }),
    displayedCurrency () {
      return this.currentOrder.currency.toLowerCase() === 'jpy' ? '¥' : '$'
    },
    orderState: {
      get () {
        return this.currentOrder.orderState
      },
      set (newState) {
        this.updateOrderState(newState)
      }
    },
    orderNumber: {
      get () {
        return this.currentOrder.orderNumber
      },
      set (newNumber) {
        this.updateOrderNumber(newNumber)
      }
    },
    netAmount: {
      get () {
        return this.currentOrder.netAmount
      },
      set (newNetAmount) {
        this.updateOrderNetAmount(parseInt(newNetAmount))
      }
    },
    shippingAmount: {
      get () {
        return this.currentOrder.shippingAmount
      },
      set (newShippingAmount) {
        this.updateOrderShippingAmount(parseInt(newShippingAmount))
      }
    },
    grossAmount: {
      get () {
        return this.currentOrder.grossAmount
      },
      set (newGrossAmount) {
        this.updateOrderGrossAmount(parseInt(newGrossAmount))
      }
    },
    applicationFee: {
      get () {
        return this.currentOrder.applicationFee
      },
      set (newAppFee) {
        this.updateOrderTransactionFeeAmount(parseInt(newAppFee))
      }
    },
    totalChargeAmount: {
      get () {
        return this.currentOrder.totalChargeAmount
      },
      set (newChargedAmount) {
        this.updateOrderChargedAmount(newChargedAmount)
      }
    },
    customerName: {
      get () {
        return this.currentOrder.customer.name
      },
      set (newName) {
        this.updateOrderCustomerName(newName)
      }
    },
    customerEmail: {
      get () {
        return this.currentOrder.customer.email
      },
      set (newEmail) {
        this.updateOrderCustomerEmail(newEmail)
      }
    },
    customerPhone: {
      get () {
        return this.currentOrder.customer.phone
      },
      set (newPhone) {
        this.updateOrderCustomerPhone(newPhone)
      }
    },
    shippingCarrier: {
      get () {
        return this.currentOrder.shipping.carrier
      },
      set (newCarrier) {
        this.updateOrderShippingCarrier(newCarrier)
      }
    },
    shippingTrackCode: {
      get () {
        return this.currentOrder.shipping.tracking_number
      },
      set (newCode) {
        this.updateOrderShippingTrackingCode(newCode)
      }
    },
    shippingTrackURL: {
      get () {
        return this.currentOrder.shipping.tracking_url
      },
      set (newTrackingURL) {
        this.updateOrderShippingTrackURL(newTrackingURL)
      }
    },
    shippingName: {
      get () {
        return this.currentOrder.shipping.name
      },
      set (newName) {
        this.updateOrderShippingName(newName)
      }
    },
    shippingPhone: {
      get () {
        return this.currentOrder.shipping.phone
      },
      set (newPhone) {
        this.updateOrderShippingPhone(newPhone)
      }
    },
    isTrackingCodeSendable () {
      return this.currentOrder.shipping.tracking_number && this.currentOrder.shipping.tracking_url
    }
  },
  methods: {
    ...mapActions({
      updateOrderById: `OrdersManagement/${UPDATE_ORDER}`,
      fetchOrderById: `OrdersManagement/${FETCH_ORDER}`,
      updateOrderState: `OrdersManagement/${ORDER_STATE_UPDATE}`,
      updateOrderNetAmount: `OrdersManagement/${ORDER_NET_AMOUNT_UPDATE}`,
      updateOrderShippingAmount: `OrdersManagement/${ORDER_SHIPPING_AMOUNT_UPDATE}`,
      updateOrderGrossAmount: `OrdersManagement/${ORDER_GROSS_AMOUNT_UPDATE}`,
      updateOrderTransactionFeeAmount: `OrdersManagement/${ORDER_TRANSACTION_FEE_UPDATE}`,
      updateOrderChargedAmount: `OrdersManagement/${ORDER_CHARGED_AMOUNT_UPDATE}`,
      updateOrderCustomerName: `OrdersManagement/${ORDER_CUSTOMER_NAME_UPDATE}`,
      updateOrderCustomerEmail: `OrdersManagement/${ORDER_CUSTOMER_EMAIL_UPDATE}`,
      updateOrderCustomerPhone: `OrdersManagement/${ORDER_CUSTOMER_PHONE_UPDATE}`,
      updateOrderShippingCarrier: `OrdersManagement/${ORDER_SHIPPING_CARRIER_UPDATE}`,
      updateOrderShippingTrackingCode: `OrdersManagement/${ORDER_SHIPPING_TRACKING_CODE_UPDATE}`,
      updateOrderShippingName: `OrdersManagement/${ORDER_SHIPPING_NAME_UPDATE}`,
      updateOrderShippingPhone: `OrdersManagement/${ORDER_SHIPPING_PHONE_UPDATE}`,
      updateOrderShippingTrackURL: `OrdersManagement/${ORDER_SHIPPING_TRACK_URL_UPDATE}`,
      updateOrderMetadata: `OrdersManagement/${ORDER_METADATA_UPDATE}`,
      addOrderMetadataItem: `OrdersManagement/${ORDER_METADATA_ITEM_ADD}`,
      removeOrderMetadataItem: `OrdersManagement/${ORDER_METADATA_ITEM_REMOVE}`,
      updateOrderCartridges: `OrdersManagement/${ORDER_CARTRIDGES_UPDATE}`,
      addOrderCartridgeItem: `OrdersManagement/${ORDER_CARTRIDGE_ITEM_ADD}`,
      removeOrderCartridgeItem: `OrdersManagement/${ORDER_CARTRIDGE_ITEM_REMOVE}`,
      sendingTrackingCodeByEmail: `OrdersManagement/${SEND_TRACKING_CODE_BY_EMAIL}`,
      updateOrderNumber: `OrdersManagement/${ORDER_NUMBER_UPDATE}`
    }),
    toggleEditMode () {
      this.editing = !this.editing
    },
    async handleUpdateOrder () {
      this.submiting = true
      const updateOrderParams = {
        order: this.currentOrder,
        debug: true,
        locale: this.$i18n.locale
      }

      await this.updateOrderById(updateOrderParams)
      this.submiting = false
    },
    setOrderMetadata (metadataItem) {
      let metadata = []
      let item = {}
      item[metadataItem] = 1
      metadata.push(item)
      this.updateOrderMetadata(metadata)
    },
    insertOrderMetadataItem (metadataKey) {
      let metadataItem = {}
      metadataItem[metadataKey] = 1
      this.addOrderMetadataItem(metadataItem)
    },
    deleteOrderMetadataItem (metadataItem) {
      this.removeOrderMetadataItem(metadataItem)
    },
    setOrderSelectedCartridges (cartridgeItem) {
      let cartridges = []
      cartridges.push(cartridgeItem)
      this.updateOrderCartridges(cartridges)
    },
    insertOrderSelectedCartridgeItem (cartridgeItem) {
      this.addOrderCartridgeItem(cartridgeItem)
    },
    deleteOrderSelectedCartridgeItem (cartridgeItem) {
      this.removeOrderCartridgeItem(cartridgeItem)
    },
    handleSendingTrackCodeByEmail () {
      const trackingCodeParams = {
        order: this.currentOrder,
        locale: this.$i18n.locale
      }

      this.sendingTrackingCodeByEmail(trackingCodeParams)
    }
  }
}
</script>
