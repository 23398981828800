<template>
  <div class="card mb-4">
    <div class="card-header border-bottom">
      <d-row>
        <d-col>
          <label for="feNewsTitle">Question</label>
          <d-input
            id="feFAQQuestion"
            v-model="question"
          />
        </d-col>
      </d-row>
    </div>
    <div class="card-body text-left border-bottom">
      <d-form>
        <!-- SLUG -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQSlug">Slug</label>
            <d-input
              id="feFAQSlug"
              v-model="slug"
            />
          </d-col>
        </d-form-row>
        <!-- DESCRIPTION -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQAnswer">Answer</label>
            <VueTrix
              v-model="answer"
            />
          </d-col>
        </d-form-row>
        <!-- TYPES -->
        <d-form-row class="mb-3">
          <d-col>
            <label for="feFAQType">Question category</label>
            <d-select
              id="feFAQType"
              v-model="category"
            >
              <option value="general">
                General
              </option>
              <option value="hardware">
                Hardware
              </option>
              <option value="software">
                Software
              </option>
            </d-select>
          </d-col>
        </d-form-row>
        <!-- CREATE BUTTON -->
        <d-form-row>
          <d-col>
            <d-button
              type="submit"
              class="btn-accent mr-3"
              @click.prevent="handleCreateFAQ"
            >
              Create
            </d-button>
          </d-col>
        </d-form-row>
      </d-form>
    </div>
    <d-card-footer class="text-muted" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTrix from 'vue-trix'
import {
  CREATE_FAQ,
  CURRENT_FAQ_UPDATE,
  FAQ_QUESTION_UPDATE,
  FAQ_SLUG_UPDATE,
  FAQ_ANSWER_UPDATE,
  FAQ_CATEGORY_UPDATE
} from '@/states/actionTypes'

export default {
  name: 'FAQCreate',
  components: {
    VueTrix
  },
  created () {
    this.updateCurrentFAQ({
      question: 'New question',
      slug: 'new-question',
      answer: '',
      question_type: 'general'
    })
  },
  computed: {
    ...mapGetters({
      currentFAQ: 'FAQsManagement/currentFAQ'
    }),
    question: {
      get () {
        return this.currentFAQ.question
      },
      set (newQuestion) {
        this.updateFAQQuestion(newQuestion)
        if (this.$i18n.locale === 'en') {
          this.updateFAQSlug(newQuestion)
        }
      }
    },
    slug: {
      get () {
        return this.currentFAQ.slug
      },
      set (newSlug) {
        this.updateFAQSlug(newSlug)
      }
    },
    answer: {
      get () {
        return this.currentFAQ.answer
      },
      set (newAnswer) {
        this.updateFAQAnswer(newAnswer)
      }
    },
    category: {
      get () {
        return this.currentFAQ.question_type
      },
      set (newCategory) {
        this.updateFAQCategory(newCategory)
      }
    }
  },
  methods: {
    ...mapActions({
      createFAQ: `FAQsManagement/${CREATE_FAQ}`,
      updateCurrentFAQ: `FAQsManagement/${CURRENT_FAQ_UPDATE}`,
      updateFAQQuestion: `FAQsManagement/${FAQ_QUESTION_UPDATE}`,
      updateFAQAnswer: `FAQsManagement/${FAQ_ANSWER_UPDATE}`,
      updateFAQCategory: `FAQsManagement/${FAQ_CATEGORY_UPDATE}`,
      updateFAQSlug: `FAQsManagement/${FAQ_SLUG_UPDATE}`
    }),
    /** Handle creating FAQ event */
    handleCreateFAQ () {
      this.createFAQ({
        faq: this.currentFAQ,
        locale: this.$i18n.locale
      })
    }
  }
}
</script>
