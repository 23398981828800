/**
 * Orders management actions
 **/
import Vue from 'vue'
import router from '@/router'
import {
  SEND_TRACKING_CODE_BY_EMAIL,
  UPDATE_ORDER,
  FETCH_ORDERS,
  FETCH_ORDER,
  CURRENT_ORDER_UPDATE,
  ORDER_STATE_UPDATE,
  ORDER_NET_AMOUNT_UPDATE,
  ORDER_SHIPPING_AMOUNT_UPDATE,
  ORDER_GROSS_AMOUNT_UPDATE,
  ORDER_TRANSACTION_FEE_UPDATE,
  ORDER_CHARGED_AMOUNT_UPDATE,
  ORDER_CUSTOMER_NAME_UPDATE,
  ORDER_CUSTOMER_EMAIL_UPDATE,
  ORDER_CUSTOMER_PHONE_UPDATE,
  ORDER_SHIPPING_CARRIER_UPDATE,
  ORDER_SHIPPING_TRACKING_CODE_UPDATE,
  ORDER_SHIPPING_NAME_UPDATE,
  ORDER_SHIPPING_PHONE_UPDATE,
  ORDER_METADATA_UPDATE,
  ORDER_METADATA_ITEM_ADD,
  ORDER_METADATA_ITEM_REMOVE,
  ORDER_CARTRIDGES_UPDATE,
  ORDER_CARTRIDGE_ITEM_ADD,
  ORDER_CARTRIDGE_ITEM_REMOVE,
  ORDER_SHIPPING_TRACK_URL_UPDATE,
  ORDER_NUMBER_UPDATE
} from '../../actionTypes'
import {
  SET_ORDER_LAST_EVALUATED_KEY,
  SET_ORDER_SHIPPING_TRACK_URL,
  UPDATE_ORDER_CARTRIDGES,
  ADD_ORDER_CARTRIDGE,
  REMOVE_ORDER_CARTRIDGE,
  ADD_ORDER_METADATA_ITEM,
  REMOVE_ORDER_METADATA_ITEM,
  SET_ORDER_METADATA,
  SET_ORDER_SHIPPING_CARRIER,
  SET_ORDER_SHIPPING_TRACKING_CODE,
  SET_ORDER_SHIPPING_PHONE,
  SET_ORDER_SHIPPING_NAME,
  SET_ORDER_CUSTOMER_NAME,
  SET_ORDER_CUSTOMER_EMAIL,
  SET_ORDER_CUSTOMER_PHONE,
  SET_ORDER_NET_AMOUNT,
  SET_ORDER_SHIPPING_AMOUNT,
  SET_ORDER_GROSS_AMOUNT,
  SET_ORDER_TRANSACTION_FEE,
  SET_ORDER_CHARGED_AMOUNT,
  SET_ORDER_STATE,
  SET_PAGINATION_META,
  SET_REMOTE_MESSAGE,
  SET_ORDERS_LIST,
  SET_CURRENT_ORDER,
  SET_ORDER_NUMBER
} from '../../mutationTypes'

function sortOrderCondition (firstOrder, secondOrder) {
  if (firstOrder.updatedAt > secondOrder.updatedAt) {
    return -1
  }
  if (firstOrder.updatedAt < secondOrder.updatedAt) {
    return 1
  }

  return 0
}

const ordersManagementActions = {
  async [SEND_TRACKING_CODE_BY_EMAIL] ({ commit, rootGetters }, params) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      if (currentAccount && currentAccount.id && currentAccount.authentication_token) {
        const sendTrackingCodeResponses = await Vue.axios
          .serverlessServer.post(`orders/${params.order.orderId}/send-tracking-code/?debug=${params.debug}&locale=${params.locale}`, params.order)
        console.log('__RESULT:', sendTrackingCodeResponses)

        if (sendTrackingCodeResponses.status === 200 && sendTrackingCodeResponses.data.status && sendTrackingCodeResponses.data.data.success) {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Send tracking code successfully.',
            type: 'success'
          })
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: Getting orders list failed',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: unauthorize',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [UPDATE_ORDER] ({ commit, rootGetters }, params) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      if (currentAccount && currentAccount.id && currentAccount.authentication_token) {
        const orderResponses = await Vue.axios
          .serverlessServer.put(`orders/${params.order.orderId}/?debug=${params.debug}&locale=${params.locale}`, params.order)
        console.log('__RESULT:', orderResponses)

        if (orderResponses.status === 200 && orderResponses.data.status && orderResponses.data.data.success) {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Updated Order successfully.',
            type: 'success'
          })

          setTimeout(() => {
            router.push({ name: 'orders-management' })
          }, 3000)
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: Getting orders list failed',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: unauthorize',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [FETCH_ORDERS] ({ commit, getters, rootGetters }, params) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']

      if (currentAccount && currentAccount.id && currentAccount.authentication_token) {
        const lastEvaluatedKey = getters['lastEvaluatedKey']

        if (lastEvaluatedKey !== null) {
          const orderResponses = await Vue.axios
            .serverlessServer.get(`orders/?lastEvaluatedKey=${lastEvaluatedKey}&limit=${params.size}&debug=${params.debug}&locale=${params.locale}`)

          if (orderResponses.status === 200 && orderResponses.data.status && orderResponses.data.data.success) {
            let orders = orderResponses.data.data.data.Items
            orders.sort((firstOrder, secondOrder) => sortOrderCondition(firstOrder, secondOrder))

            commit(SET_ORDERS_LIST, orders)
            commit(SET_PAGINATION_META, null)

            if (orderResponses.data.data.data.LastEvaluatedKey &&
                orderResponses.data.data.data.LastEvaluatedKey.orderId) {
              commit(SET_ORDER_LAST_EVALUATED_KEY, orderResponses.data.data.data.LastEvaluatedKey.orderId)
            } else {
              commit(SET_ORDER_LAST_EVALUATED_KEY, null)
            }
          } else {
            commit(SET_REMOTE_MESSAGE, {
              message: 'Error: Orders not found',
              type: 'danger'
            })
          }
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: Getting orders list failed',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: unauthorize',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  async [FETCH_ORDER] ({ commit, rootGetters }, params) {
    try {
      commit(SET_REMOTE_MESSAGE, null)
      const currentAccount = rootGetters['User/user']
      if (currentAccount && currentAccount.id && currentAccount.authentication_token) {
        const orderResponses = await Vue.axios
          .serverlessServer.get(`orders/${params.orderId}/?debug=${params.debug}&locale=${params.locale}`)

        if (orderResponses.status === 200 && orderResponses.data.status && orderResponses.data.data.success) {
          commit(SET_CURRENT_ORDER, orderResponses.data.data.data)
          commit(SET_PAGINATION_META, null)
        } else {
          commit(SET_REMOTE_MESSAGE, {
            message: 'Error: Getting orders list failed',
            type: 'danger'
          })
        }
      } else {
        commit(SET_REMOTE_MESSAGE, {
          message: 'Error: unauthorize',
          type: 'danger'
        })
      }
    } catch (error) {
      console.error(error)
      commit(SET_REMOTE_MESSAGE, {
        message: 'Error: ' + error.message,
        type: 'danger'
      })
    }
  },
  [CURRENT_ORDER_UPDATE] ({ commit }, order) {
    commit(SET_CURRENT_ORDER, order)
  },
  [ORDER_STATE_UPDATE] ({ commit }, orderState) {
    commit(SET_ORDER_STATE, orderState)
  },
  [ORDER_NUMBER_UPDATE] ({ commit }, orderNumber) {
    commit(SET_ORDER_NUMBER, orderNumber)
  },
  [ORDER_NET_AMOUNT_UPDATE] ({ commit }, netAmount) {
    commit(SET_ORDER_NET_AMOUNT, netAmount)
  },
  [ORDER_SHIPPING_AMOUNT_UPDATE] ({ commit }, shippingAmount) {
    commit(SET_ORDER_SHIPPING_AMOUNT, shippingAmount)
  },
  [ORDER_GROSS_AMOUNT_UPDATE] ({ commit }, grossAmount) {
    commit(SET_ORDER_GROSS_AMOUNT, grossAmount)
  },
  [ORDER_TRANSACTION_FEE_UPDATE] ({ commit }, transactionFee) {
    commit(SET_ORDER_TRANSACTION_FEE, transactionFee)
  },
  [ORDER_CHARGED_AMOUNT_UPDATE] ({ commit }, chargedAmount) {
    commit(SET_ORDER_CHARGED_AMOUNT, chargedAmount)
  },
  [ORDER_CUSTOMER_EMAIL_UPDATE] ({ commit }, email) {
    commit(SET_ORDER_CUSTOMER_EMAIL, email)
  },
  [ORDER_CUSTOMER_NAME_UPDATE] ({ commit }, name) {
    commit(SET_ORDER_CUSTOMER_NAME, name)
  },
  [ORDER_CUSTOMER_PHONE_UPDATE] ({ commit }, phone) {
    commit(SET_ORDER_CUSTOMER_PHONE, phone)
  },
  [ORDER_SHIPPING_CARRIER_UPDATE] ({ commit }, carrier) {
    commit(SET_ORDER_SHIPPING_CARRIER, carrier)
  },
  [ORDER_SHIPPING_TRACKING_CODE_UPDATE] ({ commit }, code) {
    commit(SET_ORDER_SHIPPING_TRACKING_CODE, code)
  },
  [ORDER_SHIPPING_NAME_UPDATE] ({ commit }, shippingName) {
    commit(SET_ORDER_SHIPPING_NAME, shippingName)
  },
  [ORDER_SHIPPING_PHONE_UPDATE] ({ commit }, shippingPhone) {
    commit(SET_ORDER_SHIPPING_PHONE, shippingPhone)
  },
  [ORDER_METADATA_UPDATE] ({ commit }, metadata) {
    commit(SET_ORDER_METADATA, metadata)
  },
  [ORDER_METADATA_ITEM_ADD] ({ commit }, metaItem) {
    commit(ADD_ORDER_METADATA_ITEM, metaItem)
  },
  [ORDER_METADATA_ITEM_REMOVE] ({ commit }, metaItem) {
    commit(REMOVE_ORDER_METADATA_ITEM, metaItem)
  },
  [ORDER_CARTRIDGES_UPDATE] ({ commit }, selectedCartridges) {
    commit(UPDATE_ORDER_CARTRIDGES, selectedCartridges)
  },
  [ORDER_CARTRIDGE_ITEM_ADD] ({ commit }, cartridge) {
    commit(ADD_ORDER_CARTRIDGE, cartridge)
  },
  [ORDER_CARTRIDGE_ITEM_REMOVE] ({ commit }, cartridge) {
    commit(REMOVE_ORDER_CARTRIDGE, cartridge)
  },
  [ORDER_SHIPPING_TRACK_URL_UPDATE] ({ commit }, trackingURL) {
    commit(SET_ORDER_SHIPPING_TRACK_URL, trackingURL)
  }
}

export default ordersManagementActions
