<template>
  <tr>
    <td class="text-center">
      {{ contact.id || '' }}
    </td>
    <td>
      {{ contact.name || '' }}
    </td>
    <td>
      {{ contact.email || '' }}
    </td>
    <td>
      {{ contact.created_at | formatDate }}
    </td>
    <td>
      <d-button-group class="d-flex justify-content-center btn-group-sm">
        <d-button
          class="btn-white"
          @click="handleOperations('view')"
        >
          <i class="material-icons">
            credit_card
          </i>
        </d-button>
        <d-button
          class="btn-white"
          @click="handleOperations('delete')"
        >
          <i class="material-icons">
            delete_sweep
          </i>
        </d-button>
      </d-button-group>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

import {
  CURRENT_CONTACT_UPDATE,
  DELETE_CONTACT
} from '@/states/actionTypes'

export default {
  name: 'ContactItem',
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateCurrentContact: `ContactsManagement/${CURRENT_CONTACT_UPDATE}`,
      deleteContact: `ContactsManagement/${DELETE_CONTACT}`
    }),
    handleOperations (type) {
      /** Set current contact */
      this.updateCurrentContact(this.contact)

      /** Do news record opertions */
      switch (type) {
        case 'view':
          this.$router.push({
            name: 'view-contact',
            params: { id: this.contact.id.toString(), action: 'view' }
          })
          break
        case 'delete':
          let confirmDelete = confirm(`Are you sure to delete contact:\n ${this.contact.email}?`)
          if (confirmDelete) {
            // detelet contact
            this.deleteContact(this.contact.id)
          }
          break
        default:
          break
      }
    }
  }
}
</script>
