import cartridgesManagementStates from './CartridgesManagementStates'
import cartridgesManagementGetters from './CartridgesManagementGetters'
import cartridgesManagementMutations from './CartridgesManagementMutations'
import cartridgesManagementActions from './CartridgesManagementActions'

const cartridgesManagement = {
  namespaced: true,
  state: cartridgesManagementStates,
  getters: cartridgesManagementGetters,
  mutations: cartridgesManagementMutations,
  actions: cartridgesManagementActions
}

export default cartridgesManagement
