/**
 * Careers management states
 **/
const careersManagementStates = {
  careers: [],
  currentCareer: {
    title: '',
    deadline: '',
    slug: '',
    requirements: '',
    description: '',
    benefits: '',
    nice_to_have: '',
    work_location: ''
  },
  paginationMeta: {
    current: 0,
    limit: 10,
    pages: 0,
    next: 0,
    prev: 0
  },
  remoteMessage: null
}

export default careersManagementStates
