/** Subscribers management states */

const subscriberStates = {
  subscribers: [],
  currentSubscriber: null,
  paginationMeta: null,
  remoteMessage: null
}

export default subscriberStates
