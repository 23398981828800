<template>
  <d-container
    fluid
    class="main-content-container px-4 h-100"
  >
    <d-row class="h-100 no-gutters" />
  </d-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SignOut',
  created () {
    this.signout()
  },
  methods: {
    ...mapActions({
      signout: 'User/signOut'
    })
  }
}
</script>
